import React from "react"
import ClassIcon from "@material-ui/icons/Class"
import {Link} from "react-router-dom"
import "./test.css"
import {Home} from "react-feather"
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined"

export default function StudentBottomTabs() {
	return (
		<nav className="nav">
			<Link to="/user-dashboard" className="nav__link">
				<Home className="nav__icon" />
			</Link>
			<Link to="/user-history" className="nav__link">
				<ClassIcon className="nav__icon" />
			</Link>
			<Link to="/transactions" className="nav__link">
				<AccountBalanceWalletOutlinedIcon className="nav__icon" />
			</Link>
		</nav>
	)
}
