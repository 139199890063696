import api from "../../helpers/api"
import moment from "moment"

export const getAttendance = async (id) => {
	try {
		const res = await api.get(`/admin/attendance/${id}`)
		return res
	} catch (err) {
		return err.response
	}
}

export const getStudentList = async (id) => {
	try {
		const res = await api.get(`/schedule/${id}`)
		return res
	} catch (err) {
		return err.response
	}
}

export const getTodaysAttendance = async (id) => {
	try {
		const res = await api.get(`/attendance/${id}?date=${moment(new Date()).format("YYYY-MM-DD")}`)
		return res
	} catch (err) {
		return err.response
	}
}

export const postStudentsAttendance = async (formData) => {
	try {
		const res = await api.post(`/attendance`, formData)
		return res
	} catch (err) {
		return err.response
	}
}
