import React, {useEffect, useState} from "react"
import {AttendanceTable, MenuAppBar, StudentBottomTabs} from "../../components"
import {makeStyles} from "@material-ui/core/styles"
import api from "../../helpers/api"

const AttendancePage = () => {
	const useStyles = makeStyles((theme) => ({
		tableWidth: {
			width: "90%",
			margin: "0 auto",
			marginTop: "90px",
			[theme.breakpoints.up("sm")]: {
				width: "40%",
			},
		},
	}))
	const [customers, setCustomers] = useState([])

	const classes = useStyles()

	useEffect(() => {
		api
			.get(`/customer/email?email=${localStorage.getItem("userId")}&q=className`)
			.then((data) => {
				setCustomers(data.data.result)
			})
			.catch((err) => {})
	}, [])

	return (
		<div>
			<MenuAppBar userName={""} />
			<div className={classes.tableWidth}>
				{customers.map((customer) => (
					<AttendanceTable key={customer._id} id={customer._id} nameOfClass={customer.className} />
				))}
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default AttendancePage
