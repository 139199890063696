import React from "react"
import {withStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import {List, ListItem, ListItemText} from "@material-ui/core"
import moment from "moment"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
})

const DialogTitle = withStyles(styles)((props) => {
	const {children, classes, onClose, ...other} = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions)

export default function LeavesModal({
	open,
	setOpen,
	cancelledDates,
	updateCancelledClass,
	deleteCancelledClass,
}) {
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div>
			<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					Leaves
				</DialogTitle>
				<DialogContent dividers>
					{cancelledDates.length ? (
						""
					) : (
						<h5
							style={{
								textAlign: "center",
								width: 300,
								padding: 30,
							}}
						>
							No Applied Leaves
						</h5>
					)}
					<List dense>
						{cancelledDates.map((date) => (
							<ListItem>
								<ListItemText
									primary={
										"Applied for a Leave on " + moment(date.cancelledDate).format("MMM DD hh:mm A")
									}
									style={{color: "red"}}
								/>
								<IconButton onClick={() => updateCancelledClass(date._id, date.cancelledDate)}>
									<EditIcon color="primary" />
								</IconButton>
								<IconButton onClick={() => deleteCancelledClass(date._id)}>
									<DeleteIcon color="secondary" />
								</IconButton>
							</ListItem>
						))}
					</List>
				</DialogContent>
			</Dialog>
		</div>
	)
}
