import React, {useContext} from "react"
import PaymentSuccessgif from "../../assets/PaymentSuccess.gif"
import {MenuAppBar} from "../../components"
import {makeStyles} from "@material-ui/core/styles"
import {Button} from "@material-ui/core"
import {Link} from "react-router-dom"
import authContext from "../../context/auth/authContext"

const PaymentSuccess = () => {
	const useStyles = makeStyles((theme) => ({
		Successgif: {
			width: "500px",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
	}))

	const classes = useStyles()
	const AuthContext = useContext(authContext)
	const {isAuthenticated} = AuthContext

	return (
		<>
			<MenuAppBar />
			<div
				style={{
					height: "90vh",
					width: "100vw",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-evenly",
					flexDirection: "column",
				}}
			>
				<img className={classes.Successgif} src={PaymentSuccessgif} alt="" />

				<Link style={{textDecoration: "none"}} to="/user-dashboard">
					<Button variant="contained" color="primary">
						{isAuthenticated ? "Go Back Home" : "Login"}
					</Button>
				</Link>
			</div>
		</>
	)
}

export default PaymentSuccess
