import React, {useContext, useEffect} from "react"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import {
	AttendancePage,
	ChangePassword,
	Login,
	ManageMaterials,
	MaterialAccess,
	MaterialPage,
	MaterialUpload,
	Meetings,
	ParticularMaterial,
	PaymentFailed,
	PaymentInfo,
	PaymentSuccess,
	StudentAttendance,
	TeachersPayroll,
	TransactionsPage,
} from "../pages"
import TeacherPassword from "../pages/login/TeacherPassword"
import authContext from "../context/auth/authContext"
import PrivateRoute from "./PrivateRoute"
import AccessMeetings from "../pages/meetings/AccessMeetings"
import CourseCard from "../components/CourseCard"
import UsersHistory from "../pages/StudentPages/UsersHistory"
import StudentHistory from "../pages/StudentPages/StudentHistory"
import PrivacyPolicy from "../pages/login/PrivacyPolicy"
import TOS from "../pages/login/TOS"
import RefundPolicy from "../pages/login/RefundPolicy"
import Aboutus from "../pages/login/Aboutus"
import CookiePolicy from "../pages/login/CookiePolicy"
import BootcampIndex from "../pages/BootCampPage"
import SummerCampClasses from "../pages/BootCampPage"
import TestMeeting from "../pages/TestMeeting"
import Leaves from "../pages/TeacherPages/Leaves/Leaves"
import Profile from "../pages/TeacherPages/Profile"
import {NotificationContextProvider} from "../context/NotificationContext"
import Scheduler from "../pages/TeacherPages/TeacherScheduler/Scheduler"
import {TeacherDashboard} from "../pages/TeacherPages/TeacherDashboard"
import ResetPassword from "../pages/login/ResetPassword"
import Options from "../pages/StudentPages/Options/Options"
import SelectPlans from "../pages/StudentPages/Options/SelectPlan"
import Subscriptionsuccess from "../pages/StudentPages/Options/Subscriptionsuccess"
import Subscriptionfailed from "../pages/StudentPages/Options/Subscriptionfailed"
import Checkout from "../pages/StudentPages/Options/Checkout"
import {CertificatePage, StudentVideos, Videopage} from "../pages/StudentPages/StudentVideos"
import {RewardsInfo, ShowRewards} from "../pages/StudentPages/Rewards"
import {StudentsProfile} from "../pages/StudentPages/Profile"
import Reviews from "../pages/StudentPages/Reviews/Reviews"
import RewardsHistory from "../pages/StudentPages/Rewards/RewardsHistory"
import Test from "../Test"

const Routes = () => {
	const AuthContext = useContext(authContext)
	const {loadUser} = AuthContext

	useEffect(() => {
		loadUser()
	}, [])

	return (
		<NotificationContextProvider>
			<Router>
				<Switch>
					<Route path="/summercamps" exact component={SummerCampClasses} />
					<Route path="/" exact component={Login} />
					<Route path="/reset" exact component={ResetPassword} />
					<Route path="/login" exact component={Login} />
					<Route path="/privacy-policy" exact component={PrivacyPolicy} />
					<Route path="/terms-of-use" exact component={TOS} />
					<Route path="/return-policy" exact component={RefundPolicy} />
					<Route path="/about" exact component={Aboutus} />
					<Route path="/cookie-policy" exact component={CookiePolicy} />
					<Route path="/summercamps" exact component={BootcampIndex} />
					<Route path="/test" exact component={Test} />
					<PrivateRoute path="/user-dashboard" exact component={Meetings} />
					<PrivateRoute path="/teacher-dashboard" exact component={TeacherDashboard} />
					<PrivateRoute path="/timetable" exact component={Scheduler} />
					<PrivateRoute path="/courses" exact component={CourseCard} />
					<PrivateRoute path="/material-upload" exact component={MaterialUpload} />
					<PrivateRoute path="/attendance" exact component={AttendancePage} />
					<PrivateRoute path="/payment-info" exact component={PaymentInfo} />
					<PrivateRoute path="/meetings" exact component={AccessMeetings} />
					<PrivateRoute path="/transactions" exact component={TransactionsPage} />
					<PrivateRoute path="/payroll" exact component={TeachersPayroll} />
					<PrivateRoute path="/user-history" exact component={UsersHistory} />
					<PrivateRoute path="/profile" exact component={Profile} />
					<PrivateRoute path="/student-history" exact component={StudentHistory} />
					<PrivateRoute path="/manage-materials" exact component={ManageMaterials} />
					<Route path="/payment-success" exact component={PaymentSuccess} />
					<PrivateRoute path="/material-access" exact component={MaterialAccess} />
					<PrivateRoute path="/particular-material/:id" exact component={ParticularMaterial} />
					<PrivateRoute path="/student-attendance/:id" exact component={StudentAttendance} />
					<PrivateRoute path="/materials/" exact component={MaterialPage} />
					<Route path="/payment-failed" exact component={PaymentFailed} />
					<PrivateRoute path="/change-password" exact component={ChangePassword} />
					<PrivateRoute path="/change-teacherPassword" exact component={TeacherPassword} />
					<PrivateRoute path="/whereby/meeting" exact component={TestMeeting} />
					<PrivateRoute path="/leaves" exact component={Leaves} />
					<Route path="/plans/:resubscribe/:customerId" exact component={SelectPlans} />
					<Route path="/options/:id" exact component={Options} />
					<Route path="/subscription/success" exact component={Subscriptionsuccess} />
					<Route path="/subscription/failed" exact component={Subscriptionfailed} />
					<Route path="/subscription/:resubscribe/:customerId/:planId" exact component={Checkout} />
					<Route path="/videos" exact component={StudentVideos} />
					<Route path="/videos/:id" exact component={Videopage} />
					<Route path="/certificates" exact component={CertificatePage} />
					<Route path="/rewards" exact component={ShowRewards} />
					<PrivateRoute path="/rewards-info" exact component={RewardsInfo} />
					<PrivateRoute path="/rewards-history" exact component={RewardsHistory} />
					<PrivateRoute path="/student-profile" exact component={StudentsProfile} />
					<PrivateRoute path="/reviews" exact component={Reviews} />
				</Switch>
			</Router>
		</NotificationContextProvider>
	)
}

export default Routes
