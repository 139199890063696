import React, {useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Collapse from "@material-ui/core/Collapse"
import {Button, TextField} from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import CircularProgress from "@material-ui/core/CircularProgress"

import moment from "moment"
import {Autocomplete} from "@material-ui/lab"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DeleteIcon from "@material-ui/icons/Delete"
import PDFLogo from "../../../assets/pdf.png"
import ImageLogo from "../../../assets/image_logo.png"
import musicLogo from "../../../assets/music.png"
import youtubeLogo from "../../../assets/youtube_logo.png"
import api from "../../../helpers/api"
import {SnackbarAlert} from "../../../components"
import ConfirmDialog from "../../../components/ConfirmDialog"

const useStyles = makeStyles((theme) => ({
	root: {
		width: 345,
		marginBottom: 10,
		boxShadow: " 0px 1px 11px 3px rgba(95,95,115,0.75)",
	},
	media: {
		height: 0,
		paddingTop: "56.25%", // 16:9
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	avatar: {
		// backgroundColor: red[500],
		height: 50,
		width: 50,
	},
}))

const MaterialCards = ({data, classNames, onSubmitClick}) => {
	const classes = useStyles()
	const [expanded, setExpanded] = React.useState(false)
	const [classNameIDS, setClassNameIDS] = useState()
	const [alertMsg, setAlertMsg] = useState()
	const [openAlert, setOpenAlert] = useState(false)
	const [alertColor, setAlertColor] = useState("")
	const [loading, setLoading] = useState(false)
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
	const [functionToDelete, setFunctionToDelete] = useState(() => () => console.log("helllo"))
	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	const handleClassNamechange = (event, value) => {
		setClassNameIDS(value)
	}

	const deleteMaterial = async () => {
		try {
			const res = await api.delete(`${process.env.REACT_APP_API_KEY}/uploads/delete/${data._id}`)
			if (res.status === 200) {
				onSubmitClick()

				setOpenAlert(true)
				setAlertMsg(res.data.message)
				setAlertColor("success")
			}
		} catch (error) {
			console.log(error)
		}
	}

	const onDeassignMaterial = async (id) => {
		try {
			const res = await api.delete(
				`${process.env.REACT_APP_API_KEY}/uploads/delete/${data._id}/${id}`
			)
			if (res.status === 200) {
				onSubmitClick()

				setOpenAlert(true)
				setAlertMsg(res.data.message)
				setAlertColor("success")
			}
		} catch (error) {
			console.log(error.response)
		}
	}

	const onAssignMaterials = async () => {
		setLoading(true)
		let formData = {
			materialId: data._id,
			scheduleId: classNameIDS && classNameIDS.ScheduleId,
		}

		try {
			const data = await api.post(`${process.env.REACT_APP_API_KEY}/uploads/assign`, formData)
			if (data.status === 200) {
				onSubmitClick()

				setOpenAlert(true)
				setAlertMsg(data.data.message)
				setAlertColor("success")
			}
		} catch (error) {
			setOpenAlert(true)
			setAlertMsg("Something went wrong!")
			setAlertColor("error")
		}
		setLoading(false)
	}

	return (
		<>
			{alertMsg ? (
				<SnackbarAlert
					open={openAlert}
					setOpen={setOpenAlert}
					message={alertMsg}
					color={alertColor}
				/>
			) : (
				""
			)}
			<ConfirmDialog
				open={deleteDialogOpen}
				setOpen={setDeleteDialogOpen}
				message={"Do you really want to delete this ?"}
				onAgree={() => {
					functionToDelete()
					setDeleteDialogOpen(false)
				}}
			/>

			<Card className={classes.root}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginLeft: 5,
						marginRight: 5,
					}}
				>
					<div style={{display: "flex", flexDirection: "row", flex: 0.7}}>
						<div>
							<a href={data.uploadLink} target="_blank" style={{textDecoration: "none"}}>
								{data.typeOfMaterial === "Youtube" ? (
									<img src={youtubeLogo} alt="" className={classes.avatar} />
								) : data.typeOfMaterial === "application/pdf" ? (
									<img src={PDFLogo} alt="" className={classes.avatar} />
								) : data.typeOfMaterial === "image/png" ? (
									<img src={ImageLogo} alt="" className={classes.avatar} />
								) : data.typeOfMaterial === "audio/mpeg" ? (
									<img src={musicLogo} alt="" className={classes.avatar} />
								) : data.typeOfMaterial === "image/jpeg" ? (
									<img src={ImageLogo} alt="" className={classes.avatar} />
								) : (
									<img
										src="https://www.flaticon.com/svg/vstatic/svg/535/535761.svg?token=exp=1618930536~hmac=f791068eac5964ad00d28b32dedc1497"
										alt=""
										className={classes.avatar}
									/>
								)}
							</a>
						</div>

						<div style={{display: "flex", flexDirection: "column", marginLeft: 10}}>
							<p style={{wordBreak: "break-word", fontSize: 12}}>{data.materialName}</p>
							<p style={{fontSize: 12}}>{moment(data.updatedAt).format("MMMM Do YYYY")}</p>
						</div>
					</div>

					<div style={{flex: "0.3"}}>
						<CardHeader
							avatar={""}
							titleTypographyProps={{fontSize: 12}}
							action={
								<>
									<IconButton
										aria-label="settings"
										onClick={() => {
											setDeleteDialogOpen(true)
											setFunctionToDelete(() => () => deleteMaterial())
										}}
									>
										<DeleteIcon />
									</IconButton>
									<IconButton
										// className={clsx(classes.expand, {
										// 	[classes.expandOpen]: expanded,
										// })}

										style={{
											backgroundColor: "#2ecc71",
											height: 35,
											width: 35,
											borderRadius: "50%",
											padding: 5,
											color: "white",
											fontSize: 12,
										}}
										onClick={handleExpandClick}
										aria-expanded={expanded}
										aria-label="show more"
									>
										{data.classes.length}
									</IconButton>
								</>
							}
						/>
					</div>
				</div>

				{/* <div style={{display: "flex", flexDirection: "row", padding: 15, marginTop: -20}}>
					<Typography
						variant="body2"
						color="textSecondary"
						component="p"
						style={{
							fontSize: 16,
							fontWeight: "bold",
							color: "black",
							marginTop: "10px",
						}}
					>
						Total classes Assigned: {data.classes.length}
					</Typography>
				</div> */}

				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<div style={{marginLeft: "15px"}}>
						{data.classes.map((data) => (
							<div
								style={{
									margin: "10px 0",
								}}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<Typography style={{fontSize: "11px", fontWeight: "bold", width: "85%"}}>
										{data.className}
									</Typography>
									<IconButton
										onClick={() => {
											setFunctionToDelete(() => () => onDeassignMaterial(data._id))
											setDeleteDialogOpen(true)
										}}
									>
										<DeleteIcon />
									</IconButton>
								</div>

								{data.students.map((student) => {
									return (
										<Chip
											style={{
												marginTop: "-8px",
												backgroundColor: "#2ecc71",
												color: "white",
												marginLeft: 3,
											}}
											label={<p style={{fontSize: 11}}>{student.firstName}</p>}
										/>
									)
								})}
							</div>
						))}
					</div>

					<CardContent
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						{classNames && (
							<Autocomplete
								options={classNames}
								getOptionLabel={(classNames) => classNames.ClassName}
								style={{width: 190}}
								onChange={handleClassNamechange}
								renderInput={(params) => (
									<TextField {...params} label="Class Name" variant="outlined" />
								)}
							/>
						)}
						{loading ? (
							<CircularProgress />
						) : (
							<Button variant="contained" style={{height: 45}} onClick={onAssignMaterials}>
								Share
							</Button>
						)}
					</CardContent>
				</Collapse>
			</Card>
		</>
	)
}

export default MaterialCards
