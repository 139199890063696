/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import {getAttendance} from "../context/Attendance/Attendance"
const useStyles = makeStyles({
	table: {
		minWidth: 300,
	},
})

export default function AttendanceTable({id, nameOfClass}) {
	const [studentAttendance, setStudentAttendance] = useState()

	useEffect(() => {
		attendance()
	}, [])

	const attendance = async () => {
		const {data} = await getAttendance(id)
		setStudentAttendance(data.result)
	}

	const classes = useStyles()

	return (
		<TableContainer component={Paper} style={{margin: "40px 0"}}>
			<h3 style={{textAlign: "center"}}> {nameOfClass} </h3>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Date</TableCell>
						<TableCell align="right">Time</TableCell>
						<TableCell align="right">Attedended</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{studentAttendance &&
						studentAttendance.map((student) => (
							<TableRow key={student._id} style={{backgroundColor: "#3F51B5", color: "white"}}>
								<TableCell component="th" style={{color: "white"}} scope="row">
									{student.date}
								</TableCell>
								<TableCell component="th" style={{color: "white"}} scope="row">
									{student.time}
								</TableCell>
								<TableCell align="right" style={{color: "white"}}>
									Yes
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
