import React from "react"
import Vimeo from "@u-wave/react-vimeo"
import {Link} from "react-router-dom"
const Videocard = ({data}) => {
	let trimmedText = ""
	let match = undefined
	if (data.url) {
		var url = data.url
		var regExp = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:(?:[a-z0-9]*\/)*\/?)?([0-9]+)/
		match = url.match(regExp)

		trimmedText =
			data?.description?.slice(0, 40) + (data?.description?.length > 250 ? "......" : "")
	}

	return (
		<>
			{match === undefined || match === null ? null : (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
						margin: 10,
						alignItems: "center",
					}}
				>
					<Vimeo width="130px" height="100px" video={match[1]} />
					<Link
						to={{
							pathname: `/videos/${match[1]}`,
							state: {
								data: data,
							},
						}}
						style={{width: "70%", marginLeft: 10, textDecoration: "none", color: "#2f3640"}}
					>
						<p style={{fontWeight: 600}}>{data.name}</p>
						<p style={{fontWeight: 400}}>{trimmedText}</p>
					</Link>
				</div>
			)}
		</>
	)
}

export default Videocard
