import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import {Link} from "react-router-dom"
import {Home, Book, Calendar, XCircle} from "react-feather"
const useStyles = makeStyles({
	root: {
		width: "100%",
	},
	icons: {
		color: "black",
		marginTop: 10,
	},
	activeIcon: {
		color: "white",
		// marginTop: 15,
	},

	active: {
		backgroundColor: "#3867d6",
		height: 35,
		width: 35,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 35 / 2,
		marginTop: 3,
	},
})

export default function TeacherBottomTabs() {
	const classes = useStyles()

	return (
		<nav className="nav">
			<Link to="/" className="nav__link">
				{window.location.pathname === "/teacher-dashboard" ? (
					<>
						<div className={classes.active}>
							<Home className={classes.activeIcon} />
						</div>
					</>
				) : (
					<Home className={classes.icons} />
				)}

				{window.location.pathname === "/teacher-dashboard" ? (
					<span className="nav__text">Home</span>
				) : (
					<span style={{color: "black"}} className="nav__text">
						Home
					</span>
				)}
			</Link>
			<Link to="/leaves" className="nav__link">
				{window.location.pathname === "/leaves" ? (
					<>
						<div className={classes.active}>
							<XCircle className={classes.activeIcon} />
						</div>
					</>
				) : (
					<XCircle className={classes.icons} />
				)}
				{window.location.pathname === "/leaves" ? (
					<span className="nav__text">Leaves</span>
				) : (
					<span style={{color: "black"}} className="nav__text">
						Leaves
					</span>
				)}
			</Link>
			<Link to="/meetings" className="nav__link">
				{window.location.pathname === "/meetings" ? (
					<>
						<div className={classes.active}>
							<Calendar className={classes.activeIcon} />
						</div>
					</>
				) : (
					<Calendar className={classes.icons} />
				)}
				{window.location.pathname === "/meetings" ? (
					<span className="nav__text">Calendar</span>
				) : (
					<span style={{color: "black"}} className="nav__text">
						Calendar
					</span>
				)}
			</Link>
			<Link to="/manage-materials" className="nav__link">
				{window.location.pathname === "/manage-materials" ? (
					<>
						<div className={classes.active}>
							<Book className={classes.activeIcon} />
						</div>
					</>
				) : (
					<Book className={classes.icons} />
				)}
				{window.location.pathname === "/manage-materials" ? (
					<span className="nav__text">Materials</span>
				) : (
					<span style={{color: "black"}} className="nav__text">
						Materials
					</span>
				)}
				{/* <span className="nav__text">Materials</span> */}
			</Link>
		</nav>
	)
}
