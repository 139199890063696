import {Dialog, DialogContent, DialogTitle, CircularProgress} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {useForm} from "react-hook-form"
import {CountryDropdown, RegionDropdown} from "react-country-region-selector"
import api from "../helpers/api"

const UpdateAddressDialog = ({open, setOpen}) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: {errors},
	} = useForm()
	const [loading, setLoading] = useState(false)
	const [billingDetails, setBillingDetails] = useState({
		name: "",
		line1: "",
		postal_code: "",
		city: "",
		state: "Alabama",
		country: "United States",
	})

	useEffect(() => {
		getStudentAddresses()
	}, [])

	const getStudentAddresses = async () => {
		try {
			if (localStorage.getItem("customerId")) {
				const res = await api.get(`/address/${localStorage.getItem("customerId")}`)
				if (res) {
					setBillingDetails({
						state: res.data.result.state,
						country: res.data.result.country,
					})

					setValue("firstName", res.data.result.name)
					setValue("address", res.data.result.address)
					setValue("zip", res.data.result.zipcode)
					setValue("city", res.data.result.city)
				}
			}
		} catch (error) {
			console.log(error)
		}
	}

	function handleChange(evt) {
		const value = evt.target.value
		setBillingDetails({
			...billingDetails,
			[evt.target.name]: value,
		})
	}

	const onSubmit = async (form) => {
		setLoading(true)
		const formData = {
			address: form.address,
			state: billingDetails.state,
			city: form.city,
			country: billingDetails.country,
			zipcode: form.zip,
			name: form.firstName,
		}
		try {
			const data = await api.post(`/address/${localStorage.getItem("customerId")}`, formData)
			if (data.status === 200) {
				setOpen(false)
			}
		} catch (error) {
			console.log(error)
		}
		setLoading(false)
	}

	return (
		<Dialog open={open} onClose={() => setOpen(!open)}>
			<DialogTitle>{"Update Address"}</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(onSubmit)}>
					<label className="checkout-label">
						<input
							value={billingDetails.name}
							type="text"
							className="StripeElement"
							style={{width: "100%"}}
							placeholder="Full Name"
							name="name"
							onChange={handleChange}
							{...register("firstName", {
								required: true,
								maxLength: 20,
								pattern: /^[A-Za-z]+$/i,
							})}
						/>
						{errors?.firstName?.type === "required" && (
							<p className="error-message">Full Name is required</p>
						)}
						{errors?.firstName?.type === "maxLength" && (
							<p className="error-message">Full Name cannot exceed 20 characters</p>
						)}
						<input
							value={billingDetails.line1}
							type="text"
							className="StripeElement"
							style={{width: "100%"}}
							placeholder="Address"
							name="line1"
							onChange={handleChange}
							{...register("address", {
								required: true,
							})}
						/>
						{errors?.address?.type === "required" && (
							<p className="error-message">Address is required</p>
						)}
						<input
							value={billingDetails.city}
							type="text"
							className="StripeElement"
							name="city"
							onChange={handleChange}
							style={{width: "100%"}}
							placeholder="City"
							{...register("city", {
								required: true,
							})}
						/>
						{errors?.city?.type === "required" && <p className="error-message">City is required</p>}
						<CountryDropdown
							onChange={(e) => {
								setBillingDetails({
									...billingDetails,
									["country"]: e,
								})
								console.log(e)
							}}
							value={billingDetails.country}
							className="StripeElement"
							whitelist={["US", "GB", "IN", "SG", "AU"]}
							style={{width: "100%"}}
						/>
						<RegionDropdown
							className="StripeElement"
							style={{width: "100%"}}
							onChange={(e) =>
								setBillingDetails({
									...billingDetails,
									["state"]: e,
								})
							}
							country={billingDetails.country}
							value={billingDetails.state}
						/>
						<input
							value={billingDetails.postal_code}
							type="text"
							className="StripeElement"
							style={{width: "100%"}}
							placeholder="Zip code"
							name="postal_code"
							onChange={handleChange}
							{...register("zip", {
								required: true,
							})}
						/>
						{errors?.zip?.type === "required" && (
							<p className="error-message">Zip code is required</p>
						)}
					</label>

					<div style={{display: "flex"}}>
						<button className="paynow-btn-address" type="submit">
							{loading ? (
								<CircularProgress style={{height: 20, width: 20, color: "white", marginTop: 10}} />
							) : (
								"Submit"
							)}
						</button>

						<button
							onClick={() => setOpen(false)}
							style={{marginLeft: 5}}
							className="paynow-btn-cancel"
							type="submit"
						>
							Cancel
						</button>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default UpdateAddressDialog
