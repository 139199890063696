import {
	Button,
	Card,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {MenuAppBar} from "../../../components"
import TeacherBottomTabs from "../../../components/BottomTabs/TeacherBottomTabs"
import "./style.css"
import {editLeaveById, getTeacherLeaves} from "../../../Services/services"
import moment from "moment"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import useWindowDimensions from "../../../helpers/useDimensions"
import {useSnackbar} from "notistack"

function Leaves() {
	const [leaves, setLeaves] = useState([])
	const [years, setYears] = useState([])
	const [refresh, setRefresh] = useState(false)
	const {width} = useWindowDimensions()
	const {enqueueSnackbar} = useSnackbar()

	const [leaveData, setLeaveData] = useState({
		scheduleId: "",
		date: new Date(),
		entireDay: false,
		isHidden: true,
		_id: "",
		reason: "",
	})

	const editLeave = () => {
		const {scheduleId, date, entireDay, reason} = leaveData

		editLeaveById(leaveData._id, {
			scheduleId: entireDay ? "" : scheduleId,
			teacherId: localStorage.getItem("teacherId"),
			date,
			entireDay,
			reason,
		})
			.then((data) => {
				enqueueSnackbar(data.data.message, {
					variant: "success",
				})
				setLeaveData({
					scheduleId: "",
					date: new Date(),
					entireDay: false,
					isHidden: true,
					_id: "",
				})
				setRefresh((prev) => !prev)
			})
			.catch((err) => {
				console.log(err.response)
				let message =
					err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Something went wrong!"
				enqueueSnackbar(message, {
					variant: "error",
				})
				setLeaveData({
					scheduleId: "",
					date: new Date(),
					entireDay: false,
					isHidden: true,
					_id: "",
				})
			})
	}

	useEffect(() => {
		getTeacherLeaves(localStorage.getItem("teacherId"))
			.then((data) => {
				setLeaves(data.data.result)
				let tempYears = []
				data.data.result.forEach((leave) => {
					let yr = moment(leave.date).format("YYYY")
					if (!tempYears.includes(yr)) {
						tempYears.push(yr)
					}
				})
				tempYears.reverse()
				setYears(tempYears)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [refresh])

	return (
		<div>
			<MenuAppBar />

			<Dialog
				open={!leaveData.isHidden}
				onClose={() =>
					setLeaveData((prev) => ({
						scheduleId: "",
						teacherId: "",
						date: new Date(),
						entireDay: false,
						isHidden: true,
						_id: "",
					}))
				}
				fullScreen={width < 400}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Edit Leave</DialogTitle>
				<DialogContent>
					<DialogContentText>Select new Date to update</DialogContentText>
					<FormControlLabel
						control={
							<Checkbox
								checked={leaveData.entireDay}
								onChange={() => setLeaveData((prev) => ({...prev, entireDay: !prev.entireDay}))}
								name="checkedA"
							/>
						}
						label="Apply Leave for Entire Day"
					/>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							margin="normal"
							fullWidth
							disablePast
							id="date-picker-dialog"
							label="Select Leave Date"
							inputVariant="outlined"
							variant="static"
							shouldDisableDate={(date) =>
								!(new Date(date).getDay() === new Date(leaveData.date).getDay())
							}
							value={leaveData.date}
							onChange={(date) => {
								setLeaveData((prev) => ({...prev, date: new Date(date)}))
							}}
						/>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button
						variant={"contained"}
						color="secondary"
						onClick={() =>
							setLeaveData((prev) => ({
								scheduleId: "",
								_id: "",
								date: new Date(),
								entireDay: false,
								isHidden: true,
							}))
						}
					>
						cancel
					</Button>
					<Button variant={"contained"} color={"primary"} onClick={() => editLeave()}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
			<div className="leaves-container">
				<p style={{color: "#130f40", fontWeight: "bold", fontSize: 24}}>Applied Leaves</p>
				{years.map((year) =>
					moment
						.months()
						.reverse()
						.map((month) => {
							let monthAndYear = `${month}, ${year}`
							let filteredLeaves = leaves
								.filter((leave) => moment(leave.date).format("MMMM, YYYY") === monthAndYear)
								.sort((x, y) => new Date(y.date).getTime() - new Date(x.date).getTime())
							if (!filteredLeaves.length) {
								return ""
							}

							return (
								<React.Fragment key={month}>
									<Typography style={{opacity: 0.7, marginBottom: 10}}>{monthAndYear}</Typography>
									<Grid container spacing={2}>
										{filteredLeaves.map((leave) => (
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<Card
													className="card"
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
													}}
												>
													<div>
														<div className="date" style={{fontSize: 15, color: "#130f40"}}>
															{moment(leave.date).format("ddd Do, hh:mm A")}
														</div>
														<div className="caption" style={{fontSize: 12}}>
															{leave.reason ||
																leave?.scheduleId?.className ||
																"You applied Leave for entire Day"}
														</div>
													</div>

													<div>
														<div>
															{leave.entireDay ? (
																<Chip
																	style={{
																		marginTop: -20,
																		backgroundColor: "#e74d3c3b",
																		color: "#c0392b",
																		fontWeight: 600,
																	}}
																	label="Entire Day"
																	size="small"
																/>
															) : (
																""
															)}
														</div>
													</div>
												</Card>
											</Grid>
										))}
									</Grid>
									<Divider className="divider-styles" />
								</React.Fragment>
							)
						})
				)}
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
		</div>
	)
}

export default Leaves
