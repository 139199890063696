import React, {useState} from "react"
import {useEffect} from "react"
const Test = () => {
	useEffect(() => {
		// checkSpeedTest()
	}, [])

	const [speedTest, setSpeedTest] = useState("")
	const [loading, setLoading] = useState(false)
	const checkSpeedTest = () => {
		var fileURL = "/test.zip"

		var request = new XMLHttpRequest()
		var avoidCache = "?avoidcache=" + new Date().getTime()
		request.open("GET", fileURL + avoidCache, true)
		request.responseType = "application/zip"
		var startTime = new Date().getTime()
		var endTime = startTime
		request.onreadystatechange = function () {
			if (request.readyState == 2) {
				//ready state 2 is when the request is sent
				startTime = new Date().getTime()
			}
			if (request.readyState == 4) {
				endTime = new Date().getTime()
				var downloadSize = request.responseText.length
				var time = (endTime - startTime) / 1000
				var sizeInBits = downloadSize * 8
				var speed = (sizeInBits / time / (1024 * 1024)).toFixed(2)
				console.log(speed + " Mbps")
			}
		}

		request.send()
	}

	const testing = () => {
		var imageAddr =
			"https://4k-uhd.nl/wp-content/uploads/2018/08/4K-3840x2160-Wallpaper-Uitzicht-5.jpg"
		var downloadSize = 5739426 //bytes

		var startTime, endTime
		var download = new Image()
		download.onload = function () {
			endTime = new Date().getTime()
			showResults()
		}

		startTime = new Date().getTime()
		var cacheBuster = "?nnn=" + startTime
		download.src = imageAddr + cacheBuster

		function showResults() {
			var duration = (endTime - startTime) / 1000
			var bitsLoaded = downloadSize * 8
			var speedBps = (bitsLoaded / duration).toFixed(2)
			var speedKbps = (speedBps / 1024).toFixed(2)
			var speedMbps = (speedKbps / 1024).toFixed(2)
			setSpeedTest(speedMbps + " Mbps")
		}
	}
	return (
		<div>
			<button onClick={testing}>{loading ? "LOADING..." : "SUBMIT"}</button>
			<h1>{speedTest}</h1>
		</div>
	)
}

export default Test
