import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

export default function ConfirmDialog({open, setOpen, message, onAgree}) {
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{message}</DialogTitle>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						No!
					</Button>
					<Button variant="contained" onClick={onAgree} color="secondary">
						Yes!
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
