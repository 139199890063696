import React from "react"
import {Button, Avatar} from "@material-ui/core"
import BookIcon from "@material-ui/icons/Book"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
const SingleCard = () => {
	return (
		<div className="SingleCardCon">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					margin: "20px",
					alignItems: "center",
				}}
			>
				<Avatar>M</Avatar>
				<div>
					<h1 style={{fontSize: "18px", marginBottom: "5px"}}>Classname</h1>
					<p style={{color: "#a5b1c2"}}>Schedule Description</p>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-evenly",
				}}
			>
				<div style={{textAlign: "center", marginTop: "6px"}}>
					<h3>10</h3>
					<p style={{fontSize: "14px"}}>Remanining</p>
				</div>
				<div style={{textAlign: "center"}}>
					<AttachMoneyIcon />
					<p style={{fontSize: "14px"}}>Pay Now</p>
				</div>
				<div style={{textAlign: "center"}}>
					<BookIcon />
					<p style={{fontSize: "14px"}}>Materials</p>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "20px",
				}}
			>
				<Button
					style={{
						backgroundColor: "#3867d6",
						color: "white",
						flex: "0.9",
						height: "40px",
					}}
				>
					Join Class
				</Button>
			</div>
		</div>
	)
}

export default SingleCard
