import {Avatar, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core"
import {CloudDownload} from "@material-ui/icons"
import {File, FilePlus} from "react-feather"
import ReactAudioPlayer from "react-h5-audio-player"
export const SEEN = JSON.parse(sessionStorage.getItem("SEEN")) || []
const urlRegex = /(http(s)?:\/\/)?([\w-]+\.)+[A-Za-z-]+([/:][\w- ;,./?%&=+]*)?/gi

export const TextFormatter = (text) => {
	const match = text.matchAll(urlRegex)
	if (match) {
		const groups = Array.from(match)[0]
		const httpsPresent = groups && !!groups[1]
		return text.replace(urlRegex, function (url) {
			return `<a target='_blank' href= ${(!httpsPresent ? "https://" : "") + url} > ${url}</a>`
		})
	}
	return text
}

export const urlExtractor = (text) => (text ? text.match(urlRegex) : null)

export const toHex = (str) => {
	let hash = 0
	if (str.length === 0) return hash
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
		hash = hash & hash
	}
	let color = "#"
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 255
		color += ("00" + value.toString(16)).substr(-2)
	}
	return color
}

export const CustomizeMessage = (messageType, fileURL) => {
	if (messageType === "IMAGE") {
		return <img src={fileURL} alt="alt" />
	} else if (messageType === "AUDIO") {
		return (
			<audio controls>
				<source
					src="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_1MG.mp3"
					type="audio/mpeg"
				/>
				Your browser does not support the audio element.
			</audio>
		)
	} else if (messageType === "VIDEO") {
		return (
			<>
				<video width="100%" controls>
					<source
						src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_480_1_5MG.mp4"
						type="video/mp4"
					/>
					<source src="mov_bbb.ogg" type="video/ogg" />
					Your browser does not support HTML video.
				</video>
			</>
		)
	} else if (messageType === "DOCUMENT") {
		return (
			<a href={fileURL} download id="download" target="_blank" rel="noreferrer">
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Avatar>
						<CloudDownload />
					</Avatar>
					<Typography variant="subtitle2" style={{marginLeft: 10}}>
						Click To Download Document
					</Typography>
				</div>
			</a>
		)
	}
}
