import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	makeStyles,
	Button,
	Card,
	CircularProgress,
} from "@material-ui/core"
import {Edit, Trash2} from "react-feather"
import {Rating} from "@material-ui/lab"

import React, {useState, useEffect} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import api from "../../../helpers/api"
import {useSnackbar} from "notistack"

const useStyles = makeStyles(() => ({
	root: {
		width: "90%",
		marginTop: "100px",
		margin: "0 auto",
		marginBottom: 80,
	},

	formControl: {
		width: "100%",
		marginBottom: "20px",
	},
}))

const Reviews = () => {
	const classes = useStyles()
	const [meetings, setMeetings] = useState()
	const [selectedClass, setSelectedClass] = useState()
	const [refresh, setRefresh] = useState(true)
	const [selectedTeacher, setSelectedTeacher] = useState()
	const [updateReview, setupdateReview] = useState(false)
	useEffect(() => {
		api
			.post(`/customer/schedules`, {email: localStorage.getItem("userId")})
			.then((data) => {
				setMeetings(
					data.data.result
						.map((schedule) => {
							if (schedule && schedule.meetingLink) {
								return schedule
							}
							return null
						})
						.filter((schedule) => !!schedule)
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const {enqueueSnackbar} = useSnackbar()

	const [ratingValue, setRatingValue] = useState(0)
	const [ratingDesc, setRatingDesc] = useState("")
	const [pageLoading, setPageLoading] = useState(false)
	const [reviewData, setReviewData] = useState(null)

	useEffect(() => {
		if (selectedTeacher) {
			getReviews()
		}
	}, [refresh])

	const getReviews = async () => {
		setPageLoading(true)
		try {
			const data = await api.get(`/reviews/${selectedTeacher?.customerId}/${selectedTeacher?._id}`)
			setReviewData(data?.data?.result)
		} catch (error) {}
		setPageLoading(false)
	}
	const submitReview = async () => {
		const formData = {
			ratingValue,
			ratingDesc,
			teacherId: selectedTeacher?.teacher,
			className: selectedTeacher?.className,
			studentName: selectedTeacher?.customerName,
			customerId: selectedTeacher?.customerId,
			scheduleId: selectedTeacher?._id,
		}

		try {
			const data = await api.post("/reviews", formData)
			if (data.status === 201) {
				enqueueSnackbar(`Revied submitted Successfully`, {variant: "success"})
				getReviews()
				setRatingDesc("")
				setRatingValue("")
			}
		} catch (error) {}
	}

	const updateReviewById = async (_id) => {
		const formData = {
			ratingValue,
			ratingDesc,
			teacherId: selectedTeacher?.teacher,
			className: selectedTeacher?.className,
			studentName: selectedTeacher?.customerName,
			customerId: selectedTeacher?.customerId,
			scheduleId: selectedTeacher?._id,
		}

		try {
			const data = await api.patch(`/reviews/${_id}`, formData)
			if (data.status === 200) {
				enqueueSnackbar(`Updated Successfully`, {variant: "success"})
				getReviews()
				setRatingDesc("")
				setRatingValue("")
				setupdateReview(false)
			}
		} catch (error) {}
	}

	const deleteReview = async () => {
		setupdateReview(false)
		try {
			const data = await api.delete(`/reviews/${reviewData._id}`)

			if (data.status === 200) {
				getReviews()
			}
		} catch (error) {}
	}

	console.log(reviewData)

	const handleChange = (event) => {
		setRefresh(!refresh)
		setSelectedClass(event.target.value)
		meetings &&
			meetings.map((item) => {
				if (item._id === event.target.value) {
					setSelectedTeacher(item)
				}
			})
	}

	const editRatingEnable = () => {
		setupdateReview(!updateReview)
		setRatingValue(parseInt(reviewData.ratingValue))
		setRatingDesc(reviewData.ratingDesc)
	}
	return (
		<div>
			<MenuAppBar />

			<div className={classes.root}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel>Select Class</InputLabel>
					<Select value={selectedClass} onChange={handleChange} label="Select Class">
						{meetings &&
							meetings.map((item) => (
								<MenuItem value={item._id}>{item?.teacherData?.TeacherName}</MenuItem>
							))}
					</Select>
				</FormControl>

				{pageLoading ? (
					<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
						<CircularProgress />
					</div>
				) : (
					<>
						{selectedTeacher && (
							<>
								{updateReview ? (
									<>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
												marginTop: 20,
											}}
										>
											<p style={{textAlign: "center", fontWeight: 520, marginBottom: 10}}>
												{selectedTeacher?.teacherData?.TeacherName}
											</p>
											<Rating
												value={parseInt(ratingValue)}
												onChange={(e) => setRatingValue(e.target.value)}
												defaultValue={0}
												size="large"
											/>
											<textarea
												style={{
													height: 100,
													width: "90%",
													marginTop: 20,
													outline: "none",
													border: "2px solid #dcdde1",
													borderRadius: 10,
													padding: 10,
												}}
												onChange={(e) => setRatingDesc(e.target.value)}
												value={ratingDesc}
											></textarea>

											<div style={{display: "flex"}}>
												<Button
													onClick={() => setupdateReview(false)}
													variant="contained"
													style={{
														width: 150,
														marginTop: 10,
														backgroundColor: "#ecf0f1",
														color: "black",
														marginRight: 20,
													}}
												>
													Cancel
												</Button>

												<Button
													onClick={() => updateReviewById(reviewData._id)}
													variant="contained"
													style={{
														width: 150,
														marginTop: 10,
														backgroundColor: "#3867d6",
														color: "white",
													}}
												>
													Update Review
												</Button>
											</div>
										</div>
									</>
								) : (
									<>
										{reviewData === null ? (
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													flexDirection: "column",
												}}
											>
												<p style={{textAlign: "center", fontWeight: 520, marginBottom: 10}}>
													{selectedTeacher?.teacherData?.TeacherName}
												</p>
												<Rating
													value={parseInt(ratingValue)}
													onChange={(e) => setRatingValue(e.target.value)}
													defaultValue={0}
													size="large"
												/>
												<textarea
													style={{
														height: 100,
														width: "90%",
														marginTop: 20,
														outline: "none",
														border: "2px solid #dcdde1",
														borderRadius: 10,
														padding: 10,
													}}
													onChange={(e) => setRatingDesc(e.target.value)}
													value={ratingDesc}
												></textarea>
												<Button
													onClick={submitReview}
													variant="contained"
													style={{
														width: "90%",
														marginTop: 10,
														backgroundColor: "#3867d6",
														color: "white",
													}}
												>
													Submit
												</Button>
											</div>
										) : (
											<Card
												style={{
													height: "auto",
													width: "100%",
													padding: 10,
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
												}}
											>
												<div>
													<Rating
														value={parseInt(reviewData?.ratingValue)}
														readOnly
														size="medium"
													/>
													<p>{reviewData.ratingDesc}</p>
												</div>
												<div style={{display: "flex", flexDirection: "column"}}>
													<Edit
														onClick={editRatingEnable}
														style={{marginBottom: 10, cursor: "pointer"}}
													/>

													<Trash2 onClick={deleteReview} style={{cursor: "pointer"}} />
												</div>
											</Card>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default Reviews
