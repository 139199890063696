import React, {useState} from "react"
import clsx from "clsx"

import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"

import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Chip from "@material-ui/core/Chip"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
}))

const TeachersAccordianDate = ({
	presentName,
	absentName,
	requestedPaidStudents,
	date,
	expandeded,
}) => {
	const classes = useStyles()

	const [expanded, setExpanded] = useState(false)

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	return (
		<>
			<Collapse in={expandeded} timeout="auto" unmountOnExit style={{marginLeft: 20}}>
				<CardActions disableSpacing>
					<Typography style={{fontSize: 16, fontWeight: "bold", marginLeft: 5}}>{date}</Typography>

					<IconButton
						className={clsx(classes.expand, {
							[classes.expandOpen]: expanded,
						})}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</IconButton>
				</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent>
						<Typography paragraph>Attedended Students:</Typography>
						{presentName.map((name) => (
							<Chip
								label={name}
								style={{
									marginRight: 5,
									marginBottom: 5,
									backgroundColor: "#2ecc71",
									color: "white",
									fontSize: 16,
								}}
							/>
						))}

						<Typography paragraph>Requested Paid Students:</Typography>
						{requestedPaidStudents.map((name) => (
							<Chip
								label={name}
								style={{
									marginRight: 5,
									marginBottom: 5,
									backgroundColor: "#F4BE2C",
									color: "white",
									fontSize: 16,
								}}
							/>
						))}

						<Typography paragraph>Absent Students:</Typography>
						{absentName.map((name) => (
							<Chip
								label={name}
								style={{
									marginRight: 5,
									marginBottom: 5,
									backgroundColor: "#e74c3c",
									color: "white",
									fontSize: 16,
								}}
							/>
						))}
					</CardContent>
				</Collapse>
			</Collapse>
		</>
	)
}

export default TeachersAccordianDate
