import {Card, makeStyles} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import api from "../../../helpers/api"
import RewardsCard from "./RewardsCard"
const useStyles = makeStyles(() => ({
	container: {
		height: "100vh",
		width: "100%",
		backgroundColor: "#f1f2f6",
	},
}))
const RewardsHistory = () => {
	const classes = useStyles()

	const [rewardsHistoryData, setRewardsHistoryData] = useState()
	useEffect(() => {
		getUserHistory()
	}, [])
	const getUserHistory = async () => {
		try {
			const data = await api.get(`/rewards/user/${localStorage.getItem("userId")}?redeems=1`)
			console.log(data)
			setRewardsHistoryData(data?.data?.result?.redeems)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div>
			<MenuAppBar />

			<div className={classes.container}>
				<div style={{paddingTop: 80}}>
					{rewardsHistoryData &&
						rewardsHistoryData.map((item) => (
							<RewardsCard rewardsHistoryData={item} type={"credit"} classes={13} />
						))}
					{/* 					
					<RewardsCard type={"credit"} classes={15} />
					<RewardsCard type={"debit"} classes={12} />
					<RewardsCard type={"debit"} classes={10} /> */}
				</div>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default RewardsHistory
