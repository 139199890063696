import React, {useEffect, useState, useContext} from "react"
import {MenuAppBar, Meeetingcard, MeetingcardSubscription} from "../../components"
import Button from "@material-ui/core/Button"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import api from "../../helpers/api"
import StudentBottomTabs from "../../components/BottomTabs/StudentBottomTabs"
import useWindowDimensions from "./../../components/useWindowDimensions"
import SnackbarAlert from "./../../components/SnackbarAlert"
// import tawkTo from "./tawkTo"
import "./meetingsBG.css"
import {Grid} from "@material-ui/core"
import moment from "moment"
import SingleNotification from "./SingleNotification"
import {NotificationContext} from "../../context/NotificationContext"
import ChatHome from "../../ChatBot/ChatHome"
import DemoMeetingCard from "../../components/ClassCards/DemoMeetingCard"
import {isFuture} from "../../Services"

const Meetings = () => {
	const {setRefresh: globalNotificationsRefresh} = useContext(NotificationContext)

	useEffect(() => {
		globalNotificationsRefresh((prev) => !prev)
	}, [globalNotificationsRefresh])

	const [customerId] = useState(localStorage.getItem("customerId"))
	const {width} = useWindowDimensions()
	const [meetings, setMeetings] = useState([])
	const [loading, setLoading] = useState(false)
	const [zone] = useState("")
	const [dialogOpen, setDialogOpen] = useState(false)
	const [selectedSchedule, setSelectedSchedule] = useState({})
	const [selectedDateToCancel, setSelectedDateToCancel] = useState(new Date())
	const [open, setOpen] = useState(false)
	const [color, setColor] = useState("")
	const [message, setMessage] = useState("")
	const [refresh, setRefresh] = useState(false)
	const [allowedDays, setAllowedDays] = useState(["Sunday"])
	const [editingId, setEditingId] = useState("")
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
	const NotificationContextData = useContext(NotificationContext)
	const {unread} = NotificationContextData
	const [leavesLimitExceededDialogOpen, setLeavesLimitExceededDialogOpen] = useState(false)

	useEffect(() => {
		api
			.post(`/customer/schedules`, {email: localStorage.getItem("userId")})
			.then((data) => {
				setMeetings(
					data.data.result
						.map((schedule) => {
							if (schedule) {
								return schedule
							}
							return null
						})
						.filter((schedule) => !!schedule)
				)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [customerId, refresh])

	const submitLeave = () => {
		setSubmitButtonDisabled(true)
		if (!editingId) {
			let daySelected = moment(selectedDateToCancel).format("dddd")
			let hoursAndMins = allowedDays.find((day) => day[0] === daySelected)
			let hours = hoursAndMins[1]
			let mins = hoursAndMins[2]
			api
				.post(`/cancelclass`, {
					studentId: localStorage.getItem("userId"),
					scheduleId: selectedSchedule._id,
					cancelledDate: moment(selectedDateToCancel)
						.set("hour", hours)
						.set("minute", mins)
						.format(),
				})
				.then((data) => {
					if (data.data.message) {
						setColor("success")
						setMessage(data.data.message)
						setOpen(true)
					}
					setDialogOpen(false)
					setSelectedDateToCancel(new Date())
					setSelectedSchedule({})
					setRefresh((prev) => !prev)
					setSubmitButtonDisabled(false)
				})
				.catch((err) => {
					setDialogOpen(false)
					setSelectedDateToCancel(new Date())
					setSelectedSchedule({})
					if (err.response) {
						if (err.response.data.error === "LEAVE LIMIT EXCEEDED") {
							setLeavesLimitExceededDialogOpen(true)
							return
						}
						setColor("error")
						setMessage((err.response.data && err.response.data.error) || "Unable to Apply Leave")
						setOpen(true)
						setSubmitButtonDisabled(false)
					}
				})
		} else {
			api
				.put("/cancelclass", {
					cancelledDate: selectedDateToCancel,
					_id: editingId,
				})
				.then((data) => {
					if (data.data.message) {
						setColor("success")
						setMessage(data.data.message)
						setOpen(true)
					}
					setDialogOpen(false)
					setSelectedDateToCancel(new Date())
					setSelectedSchedule({})
					setRefresh((prev) => !prev)
					setSubmitButtonDisabled(false)
				})
				.catch((err) => {
					setDialogOpen(false)
					setSelectedDateToCancel(new Date())
					setSelectedSchedule({})
					if (err.response) {
						setColor("error")
						setMessage((err.response.data && err.response.data.error) || "Unable to Apply Leave")
						setOpen(true)
						setSubmitButtonDisabled(false)
					}
				})
		}
	}

	const getUsersInfo = async () => {
		try {
			if (localStorage.getItem("Name") === null || localStorage.getItem("LName") === null) {
				const data = await api.get(
					`${process.env.REACT_APP_API_KEY}/user/info/${localStorage.getItem("customerId")}`
				)
				if (data) {
					localStorage.setItem("Name", data && data.data.result.firstName)
					localStorage.setItem("LName", data && data.data.result.lastName)
					localStorage.setItem("timezone", data && data.data.result.timeZone)
					localStorage.setItem("whatsapp", data && data.data.result.whatsAppnumber)
				}
			}
		} catch (error) {}
	}

	useEffect(() => {
		getUsersInfo()
	}, [])

	const handleLeavesLimitDialogClose = () => {
		setLeavesLimitExceededDialogOpen(false)
	}

	return (
		<div className={"meetingsBg"}>
			<MenuAppBar />
			<SnackbarAlert setOpen={setOpen} open={open} message={message} color={color} />
			<Dialog
				open={leavesLimitExceededDialogOpen}
				onClose={handleLeavesLimitDialogClose}
				aria-labelledby="Leaves-limit-exceeded"
				aria-describedby="Leaves-limit-exceeded"
			>
				<DialogContent>
					<DialogContentText id="Leaves-limit-exceeded">
						<b>Namaskaram,</b>
						<br />
						<br />
						<div>
							To maintain regularity in learning, we have restricted the number of leaves to only
							one in a given payment cycle. You have already applied for one leave in your current
							payment cycle. To apply for more, so that the class may not be counted, kindly contact
							Live Sloka Admin.
						</div>
						<br />
						<div style={{fontWeight: "bold"}}>Ram +91 83093 41208</div>
						<div style={{fontWeight: "bold"}}>Lahari +91 83285 30889</div>
						<div style={{fontWeight: "bold"}}>Bhargav +91 8074474524</div>
						<br />
						Regards, Live Sloka Team.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleLeavesLimitDialogClose} variant="contained" color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false)
					setSelectedDateToCancel(new Date())
					setSelectedSchedule({})
				}}
				fullScreen={width < 400}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{!editingId ? "Apply Leave" : "Edit Leave"}{" "}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{editingId ? (
							`Select new Date to update`
						) : (
							<>
								<div>Select a Date to Apply Leave for</div>
								<div>{selectedSchedule.subject} class</div>
							</>
						)}
					</DialogContentText>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							margin="normal"
							fullWidth
							disablePast
							id="date-picker-dialog"
							label="Select Leave Date"
							inputVariant="outlined"
							variant="static"
							shouldDisableDate={(date) => {
								let arr = [
									"Sunday",
									"Monday",
									"Tuesday",
									"Wednesday",
									"Thursday",
									"Friday",
									"Saturday",
								]
								return !allowedDays.map((day) => day[0]).includes(arr[new Date(date).getDay()])
							}}
							value={selectedDateToCancel}
							onChange={(date, value) => {
								setSelectedDateToCancel(new Date(date))
							}}
						/>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button variant={"contained"} color="secondary" onClick={() => setDialogOpen(false)}>
						cancel
					</Button>
					<Button
						variant={"contained"}
						color={submitButtonDisabled ? "default" : "primary"}
						disabled={submitButtonDisabled}
						onClick={() => submitLeave()}
					>
						{submitButtonDisabled ? "Loading" : "Submit"}
					</Button>
				</DialogActions>
			</Dialog>

			<div style={{padding: "70px 0 10px"}}>
				<div>
					{unread && (
						<SingleNotification
							icon={unread[0]?.icon}
							color={unread[0]?.background}
							title={unread[0]?.title}
							text={unread[0]?.message}
							id={unread[0]?._id}
							time={unread[0]?.teacherLeaveDate}
							isImage={unread[0]?.isImage}
							image={unread[0]?.image}
						/>
					)}
				</div>
			</div>

			<div
				style={{
					margin: "0 auto",
					maxWidth: "350px",
				}}
			></div>
			<div
				style={{
					paddingBottom: "60px",
				}}
			>
				<Grid container direction="row" justify="center" alignItems="center">
					{meetings.map((meeting, i) => {
						return (
							<Grid
								key={meeting._id}
								item
								xs={12}
								sm={6}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{meeting.paidTill ? (
									<MeetingcardSubscription
										className={meeting.className}
										customerId={meeting.customerId}
										key={i}
										isDemo={meeting.demo}
										link={meeting?.teacherData?.joinLink}
										loading={loading}
										setLoading={setLoading}
										isJoinButtonDisabled={meeting.isJoinButtonDisabled}
										paidTill={meeting.paidTill}
										numberOfClassesBought={meeting.numberOfClassesBought}
										cardId={meeting.className}
										scheduleId={meeting._id}
										isClassTemperarilyCancelled={
											meeting.cancelledTill && isFuture(meeting.cancelledTill)
										}
										message={meeting.message}
										subject={meeting.subject.subjectName}
										slots={meeting.slots}
										zone={zone}
										desc={meeting.scheduleDescription}
										desc2={meeting.scheduleDescription2}
										setDialogOpen={setDialogOpen}
										setSelectedSchedule={setSelectedSchedule}
										isSubscribed={meeting.isSubscribed}
										_id={meeting._id}
										scheduleDays={meeting.scheduleDays}
										cancelledDates={meeting.cancelledClasses}
										setRefresh={setRefresh}
										setColor={setColor}
										setMessage={setMessage}
										setOpen={setOpen}
										setAllowedDays={setAllowedDays}
										setEditingId={setEditingId}
										setSelectedDateToCancel={setSelectedDateToCancel}
										isWhereby={!!meeting.wherebyMeetingId}
										customerName={meeting.customerName}
										isSummerCampClass={meeting.isSummerCampClass}
										isTeacherOnLeave={meeting.isTeacherOnLeave}
										demo={meeting.demo}
										allData={meeting}
									/>
								) : meeting.demo ? (
									<DemoMeetingCard
										className={meeting.className}
										customerId={meeting.customerId}
										key={i}
										link={meeting?.teacherData?.joinLink}
										isDemo={meeting.demo}
										loading={loading}
										setLoading={setLoading}
										isJoinButtonDisabled={meeting.isJoinButtonDisabled}
										paidTill={meeting.paidTill}
										numberOfClassesBought={meeting.numberOfClassesBought}
										cardId={meeting.className}
										scheduleId={meeting._id}
										isClassTemperarilyCancelled={
											meeting.cancelledTill && isFuture(meeting.cancelledTill)
										}
										message={meeting.message}
										subject={meeting.subject.subjectName}
										slots={meeting.slots}
										zone={zone}
										desc={meeting.scheduleDescription}
										desc2={meeting.scheduleDescription2}
										setDialogOpen={setDialogOpen}
										setSelectedSchedule={setSelectedSchedule}
										isSubscribed={meeting.isSubscribed}
										_id={meeting._id}
										scheduleDays={meeting.scheduleDays}
										cancelledDates={meeting.cancelledClasses}
										setRefresh={setRefresh}
										setColor={setColor}
										setMessage={setMessage}
										setOpen={setOpen}
										setAllowedDays={setAllowedDays}
										setEditingId={setEditingId}
										setSelectedDateToCancel={setSelectedDateToCancel}
										isWhereby={!!meeting.wherebyMeetingId}
										customerName={meeting.customerName}
										isSummerCampClass={meeting.isSummerCampClass}
										isTeacherOnLeave={meeting.isTeacherOnLeave}
										demo={meeting.demo}
									/>
								) : (
									<Meeetingcard
										className={meeting.className}
										customerId={meeting.customerId}
										key={i}
										isDemo={meeting.demo}
										link={meeting?.teacherData?.joinLink}
										loading={loading}
										setLoading={setLoading}
										isJoinButtonDisabled={meeting.isJoinButtonDisabled}
										paidTill={meeting.paidTill}
										numberOfClassesBought={meeting.numberOfClassesBought}
										cardId={meeting.className}
										scheduleId={meeting._id}
										isClassTemperarilyCancelled={
											meeting.cancelledTill && isFuture(meeting.cancelledTill)
										}
										message={meeting.message}
										subject={meeting.subject.subjectName}
										slots={meeting.slots}
										zone={zone}
										desc={meeting.scheduleDescription}
										desc2={meeting.scheduleDescription2}
										setDialogOpen={setDialogOpen}
										setSelectedSchedule={setSelectedSchedule}
										isSubscribed={meeting.isSubscribed}
										_id={meeting._id}
										scheduleDays={meeting.scheduleDays}
										cancelledDates={meeting.cancelledClasses}
										setRefresh={setRefresh}
										setColor={setColor}
										setMessage={setMessage}
										setOpen={setOpen}
										setAllowedDays={setAllowedDays}
										setEditingId={setEditingId}
										setSelectedDateToCancel={setSelectedDateToCancel}
										isWhereby={!!meeting.wherebyMeetingId}
										customerName={meeting.customerName}
										isSummerCampClass={meeting.isSummerCampClass}
										isTeacherOnLeave={meeting.isTeacherOnLeave}
										demo={meeting.demo}
										allData={meeting}
									/>
								)}
							</Grid>
						)
					})}
				</Grid>
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>

			<ChatHome />
		</div>
	)
}

export default Meetings
