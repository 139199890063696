import React, {useState, useEffect, useCallback, useMemo} from "react"
import {MenuAppBar} from "../../components"
import moment from "moment"
import {Button, Typography} from "@material-ui/core"
import {
	getStudentList,
	getTodaysAttendance,
	postStudentsAttendance,
} from "../../context/Attendance/Attendance"
import {Redirect, useLocation, useParams} from "react-router-dom"
import {getAllCancelledStudents} from "../../Services/services"
import {Fab} from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import "./attendance.css"

function useQuery() {
	return new URLSearchParams(useLocation().search)
}

const isNotPaidStudent = (student) => {
	return (
		(student.numberOfClassesBought && student.numberOfClassesBought < 0) ||
		(student.paidTill && moment(student.paidTill).unix() < moment().unix())
	)
}

const StudentAttendance = () => {
	let query = useQuery()
	const location = useLocation()
	const [studentNameLists, setStudentNameLists] = useState([])
	const [studentAttendance, setStudentAttendance] = useState([])
	const [requestedStudents, setRequestedStudents] = useState([])
	const [absentees, setAbsentees] = useState([])
	const [redirect, setRedirect] = useState(false)
	const [disable, setDisable] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const params = useParams()

	const scheduleId = useMemo(() => params.id, [params])
	const memoizedQuery = useMemo(() => query, [query])

	const studentListNAttendance = useCallback(async () => {
		const {data} = await getStudentList(scheduleId)
		console.log(data.result.students)
		setStudentNameLists(data.result.students)
		const res = await getTodaysAttendance(scheduleId)
		setStudentAttendance(res.data?.result?.customers || [])
		setAbsentees(res.data?.result?.absentees || [])
		setIsLoading(false)
		if (res.data.result && res.data.result.requestedStudents) {
			setRequestedStudents(res.data?.result?.requestedStudents || [])
		} else {
			getCancelledStudentsAndSetRequestedStudents(scheduleId, memoizedQuery.get("time"))
		}
	}, [scheduleId, memoizedQuery])

	const getCancelledStudentsAndSetRequestedStudents = async (_id, time) => {
		let date = moment(
			`${moment(new Date()).format("YYYY-MM-DD")} ${time}`,
			"YYYY-MM-DD hh:mm A"
		).format()
		getAllCancelledStudents(_id, date)
			.then((data) => {
				setRequestedStudents(data.data.result.map((date) => date.studentId))
				setIsLoading(false)
			})
			.catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		studentListNAttendance()
	}, [])

	const postAttendance = async () => {
		try {
			setDisable(true)
			let tempAbsentees = []
			studentNameLists.forEach((student) => {
				if (
					!studentAttendance.includes(student._id) &&
					!requestedStudents.includes(student._id) &&
					!absentees.includes(student._id)
				) {
					tempAbsentees.push(student._id)
				}
			})
			const formData = {
				date: moment(new Date()).format("YYYY-MM-DD"),
				time: new Date().toLocaleTimeString().split(" ")[0],
				scheduleId: params.id,
				customers: studentAttendance,
				requestedStudents: [...requestedStudents, ...tempAbsentees],
				absentees: [...absentees],
				teacherId: localStorage.getItem("teacherId"),
			}
			const data = await postStudentsAttendance(formData)
			if (data) {
				setRedirect(true)
			}
		} catch (error) {}
	}

	const removeId = (prev, id) => {
		console.log("yes")
		let prevValues = [...prev]
		let index = prevValues.indexOf(id)
		if (index !== -1) {
			prevValues.splice(index, 1)
		}
		return prevValues
	}

	const set = (name, id) => {
		if (name === "present") {
			if (!studentAttendance.includes(id)) {
				setStudentAttendance((prev) => [...prev, id])
			}
			if (absentees.includes(id)) {
				setAbsentees((prev) => removeId(prev, id))
			}
		} else if (name === "absent") {
			if (!absentees.includes(id)) {
				setAbsentees((prev) => [...prev, id])
			}
			if (studentAttendance.includes(id)) {
				setStudentAttendance((prev) => removeId(prev, id))
			}
		}
	}

	if (redirect) {
		if (location?.state?.type === "new") {
			return <Redirect to="/teacher-dashboard" />
		} else {
			return <Redirect to="/meetings" />
		}
	}

	return (
		<div className="teacher-attendance">
			<MenuAppBar />
			<div>
				<h2 className="title">Attendance </h2>
				{isLoading ? (
					<div
						style={{display: "flex", align: "center", justifyContent: "center", margin: "20px 0"}}
					>
						<CircularProgress />
					</div>
				) : (
					<>
						{studentNameLists.map((student) => (
							<div className="attendance-card">
								<div className="student-name">
									{student.firstName}{" "}
									{isNotPaidStudent(student) && (
										<Typography color="secondary" style={{ fontSize:12 }} >
											Student not Paid, Please contact Lahari Mam{" "}
										</Typography>
									)}
								</div>
								{requestedStudents.includes(student._id) ? (
									<Fab size="small" style={{backgroundColor: "#d35400", color: "#fff"}}>
										L
									</Fab>
								) : (
									<>
										<Fab
											size="small"
											style={{
												marginRight: 10,
												backgroundColor: studentAttendance.includes(student._id) && "#2ecc71",
												color: studentAttendance.includes(student._id) && "#fff",
											}}
											onClick={() => set("present", student._id)}
											disabled={isNotPaidStudent(student)}
										>
											P
										</Fab>
										<Fab
											size="small"
											style={
												absentees.includes(student._id)
													? {backgroundColor: "#d63031", color: "#fff"}
													: {}
											}
											onClick={() => set("absent", student._id)}
											disabled={isNotPaidStudent(student)}
										>
											A
										</Fab>
									</>
								)}
							</div>
						))}
						<div style={{textAlign: "center"}}>
							<Button
								style={{width: "140px"}}
								variant="contained"
								disabled={disable}
								onClick={postAttendance}
								color="primary"
							>
								Submit
							</Button>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default StudentAttendance
