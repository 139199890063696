import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import {makeStyles} from "@material-ui/core/styles"

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
}))

function SnackbarAlert({open, setOpen, message, color}) {
	const classes = useStyles()

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}

		setOpen(false)
	}

	return (
		<div className={classes.root}>
			<Snackbar
				anchorOrigin={{vertical: "top", horizontal: "center"}}
				open={open}
				autoHideDuration={6000}
			>
				<Alert onClose={handleClose} severity={color}>
					{message}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default SnackbarAlert
