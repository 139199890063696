import React, {useState} from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import {CircularProgress} from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle"
import {storage} from "../Firebase"
import api from "../helpers/api"
import {IconButton} from "@material-ui/core"
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined"
import SnackbarAlert from "./SnackbarAlert"
export default function ProfilePicupdate({open, setOpen, data, getBackData}) {
	const [profilePicture, setProfilePicture] = useState()
	const [loading, setLoading] = useState(true)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const [modalAlert, setModalAlert] = useState(false)

	const profilePictureUpdate = async () => {
		if (profilePicture !== undefined) {
			let unix = new Date().getTime()
			const uploadTask = storage
				.ref(`students/${unix}-${profilePicture[0].name}`)
				.put(profilePicture[0])
			var desertRef = storage.ref("students").child(data.profileLocation)
			desertRef
				.delete()
				.then(function () {
					console.log("yes deleted old")
				})
				.catch(function (error) {
					console.log(error)
				})
			uploadTask.on(
				"state_changed",
				(snapshot) => {},
				(err) => {
					console.log(err)
				},
				() => {
					storage
						.ref("students")
						.child(`${unix}-${profilePicture[0].name}`)
						.getDownloadURL()
						.then(async (URL) => {
							try {
								const data = await api.post("/update/customerPic/", {
									email: localStorage.getItem("userId"),
									profilePic: URL,
									location: `${unix}-${profilePicture[0].name}`,
								})
								getBackData(200)
								setProfilePicture()
								handleClose()
								setModalAlert(true)
							} catch (error) {}
						})
				}
			)
		}
	}

	return (
		<div>
			{/* {result && result.status === 400 ? (
        <SnackbarAlert
          setOpen={setOpen}
          open={open}
          message={message}
          color={color}
        />
      ) : (
        ""
      )} */}

			<SnackbarAlert
				setOpen={setModalAlert}
				open={modalAlert}
				message={"Profile Picture updated"}
				color={"success"}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Update Profile Picture</DialogTitle>
				<DialogContent style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
					<IconButton
						variant="contained"
						component="label"
						style={{
							marginTop: -20,
						}}
					>
						{profilePicture ? (
							<img
								src={URL.createObjectURL(profilePicture[0])}
								style={{height: 100, width: 100, objectFit: "cover", borderRadius: 10}}
								alt=""
							/>
						) : (
							<div
								style={{
									height: 100,
									width: 100,
									border: "1px dotted #7f8c8d",
									borderRadius: 10,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<AddCircleOutlineOutlinedIcon />
							</div>
						)}

						<input
							multiple
							accept="image/*"
							onChange={(e) => {
								setProfilePicture(e.target.files)
							}}
							type="file"
							hidden
						/>
					</IconButton>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					{!loading ? (
						<CircularProgress style={{height: 30, width: 30, marginRight: 15}} />
					) : (
						<Button onClick={profilePictureUpdate} color="primary" autoFocus>
							Upload
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	)
}
