import React, {useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import {postforpayment} from "../context/Payment/PaymentService"
import {CircularProgress} from "@material-ui/core"

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
		margin: "20px 0",
	},
	media: {
		width: 100,
	},
})
const PaymentCard = ({desc, amount, cls, id, disabled}) => {
	const classes = useStyles()

	const [loading, setLoading] = useState(false)

	const onPayNowClick = async () => {
		setLoading(true)
		const formData = {
			id,
		}

		const data = await postforpayment(formData)

		if (data.status === 200) {
			window.location.assign(data.data.link)
		}
		setLoading(false)
	}

	return (
		<Card className={classes.root}>
			<CardActionArea>
				<CardContent>
					<Typography style={{fontSize: "18px"}} variant="h5" align={"center"} component="h3">
						{cls}
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						align={"center"}
						component="p"
						style={{fontSize: "18px", marginTop: "10px"}}
					>
						{desc}
					</Typography>

					<Typography
						variant="body2"
						color="textSecondary"
						align={"center"}
						component="p"
						style={{fontSize: "18px", marginTop: "10px", fontWeight: "bold"}}
					>
						${amount}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<div style={{margin: "0 auto"}}>
					{loading ? (
						<CircularProgress />
					) : (
						<Button variant="contained" color="primary" onClick={onPayNowClick} disabled={disabled}>
							Pay now
						</Button>
					)}
				</div>
			</CardActions>
		</Card>
	)
}

export default PaymentCard
