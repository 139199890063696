import {Grid} from "@material-ui/core"
import React, {useEffect} from "react"
import AOS from "aos"
import "aos/dist/aos.css"

function OurAchievements() {
	useEffect(() => {
		AOS.init({
			duration: 1000,
		})
	}, [])

	return (
		<div className="auto-container">
			<h3 className="heading text-align-center">Our Achievements</h3>
			<p className="head-desc text-align-center margin-auto">
				Here are some of our Achievements till now
			</p>
			<Grid container>
				<Grid item xs={12} sm={6} md={4}>
					<div className="oa-card-wrapper">
						<div data-aos="fade-up-right" className="oa-card">
							<div className="icon-wrapper red">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="50"
									height="50"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									style={{color: "black"}}
									className="feather feather-book-open"
								>
									<path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
									<path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
								</svg>
							</div>
							<div className="oa-text">Total Subjects</div>
							<div className="oa-number">10+</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<div className="oa-card-wrapper">
						<div data-aos="fade-up" className="oa-card">
							<div className="icon-wrapper">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="50"
									height="50"
									viewBox="0 0 24 24"
									style={{color: "black"}}
								>
									<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.057v-3.057h2.994c-.059 1.143-.212 2.24-.456 3.279-.823-.12-1.674-.188-2.538-.222zm1.957 2.162c-.499 1.33-1.159 2.497-1.957 3.456v-3.62c.666.028 1.319.081 1.957.164zm-1.957-7.219v-3.015c.868-.034 1.721-.103 2.548-.224.238 1.027.389 2.111.446 3.239h-2.994zm0-5.014v-3.661c.806.969 1.471 2.15 1.971 3.496-.642.084-1.3.137-1.971.165zm2.703-3.267c1.237.496 2.354 1.228 3.29 2.146-.642.234-1.311.442-2.019.607-.344-.992-.775-1.91-1.271-2.753zm-7.241 13.56c-.244-1.039-.398-2.136-.456-3.279h2.994v3.057c-.865.034-1.714.102-2.538.222zm2.538 1.776v3.62c-.798-.959-1.458-2.126-1.957-3.456.638-.083 1.291-.136 1.957-.164zm-2.994-7.055c.057-1.128.207-2.212.446-3.239.827.121 1.68.19 2.548.224v3.015h-2.994zm1.024-5.179c.5-1.346 1.165-2.527 1.97-3.496v3.661c-.671-.028-1.329-.081-1.97-.165zm-2.005-.35c-.708-.165-1.377-.373-2.018-.607.937-.918 2.053-1.65 3.29-2.146-.496.844-.927 1.762-1.272 2.753zm-.549 1.918c-.264 1.151-.434 2.36-.492 3.611h-3.933c.165-1.658.739-3.197 1.617-4.518.88.361 1.816.67 2.808.907zm.009 9.262c-.988.236-1.92.542-2.797.9-.89-1.328-1.471-2.879-1.637-4.551h3.934c.058 1.265.231 2.488.5 3.651zm.553 1.917c.342.976.768 1.881 1.257 2.712-1.223-.49-2.326-1.211-3.256-2.115.636-.229 1.299-.435 1.999-.597zm9.924 0c.7.163 1.362.367 1.999.597-.931.903-2.034 1.625-3.257 2.116.489-.832.915-1.737 1.258-2.713zm.553-1.917c.27-1.163.442-2.386.501-3.651h3.934c-.167 1.672-.748 3.223-1.638 4.551-.877-.358-1.81-.664-2.797-.9zm.501-5.651c-.058-1.251-.229-2.46-.492-3.611.992-.237 1.929-.546 2.809-.907.877 1.321 1.451 2.86 1.616 4.518h-3.933z" />
								</svg>
							</div>
							<div className="oa-text">Global Positions</div>
							<div className="oa-number">7+</div>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<div className="oa-card-wrapper">
						<div data-aos="fade-up-left" className="oa-card">
							<div className="icon-wrapper sand">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="50"
									height="50"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									className="feather feather-users"
									style={{color: "black"}}
								>
									<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
									<circle cx="9" cy="7" r="4"></circle>
									<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
									<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
								</svg>
							</div>
							<div className="oa-text">Total Classes</div>
							<div className="oa-number">30,000+</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default OurAchievements
