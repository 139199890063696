import React, {useMemo, useState, useEffect} from "react"
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from "@stripe/react-stripe-js"
import useResponsiveFontSize from "../Plans/useResponsiveFontSize"
import api from "../../../helpers/api"
import Subscriptionsuccess from "./Subscriptionsuccess"
import Subscriptionfailed from "./Subscriptionfailed"
import {CircularProgress} from "@material-ui/core"
import {ReactComponent as Amex} from "../../../assets/Amex.svg"
import {ReactComponent as Mastercard} from "../../../assets/mastercard.svg"
import {ReactComponent as Visa} from "../../../assets/visa.svg"
import {ReactComponent as Discovery} from "../../../assets/discovery.svg"
import {CountryDropdown, RegionDropdown} from "react-country-region-selector"
import {useForm} from "react-hook-form"
import "./cardform.css"

const useOptions = () => {
	const fontSize = useResponsiveFontSize()
	const options = useMemo(
		() => ({
			showIcon: true,
			style: {
				base: {
					fontSize,
					color: "#424770",
					letterSpacing: "0.025em",
					fontFamily: "Source Code Pro, monospace",
					"::placeholder": {
						color: "#aab7c4",
					},
				},
				invalid: {
					color: "#9e2146",
				},
			},
		}),
		[fontSize]
	)

	return options
}

const countries = {
	India: "IN",
	"United States": "US",
	"United Kingdom": "GB",
	Australia: "AU",
	Singapore: "SG",
}

const StripeCardElement = ({plan, customerId, resubscribe}) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: {errors},
	} = useForm()

	const stripe = useStripe()
	const elements = useElements()
	const options = useOptions()
	const [successOpen, setSuccessOpen] = useState(false)
	const [failedOpen, setFailedOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [invalidCardNumber, setInvalidCardNumber] = useState()
	const [invalidDate, setInvalidDate] = useState()
	const [billingDetails, setBillingDetails] = useState({
		name: "",
		line1: "",
		postal_code: "",
		city: "",
		state: "Alabama",
		country: "United States",
		email: "",
	})

	function handleChange(evt) {
		const value = evt.target.value
		setBillingDetails({
			...billingDetails,
			[evt.target.name]: value,
		})
	}

	useEffect(() => {
		api
			.get(`/address/${customerId}`)
			.then((res) => {
				if (res.data.result) {
					const {state, country, name, address, zipcode, city, email} = res.data.result
					setBillingDetails({
						state,
						country,
					})
					setValue("firstName", name)
					setValue("address", address)
					setValue("zip", zipcode)
					setValue("city", city)
					setValue("email", email)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [customerId, setValue])

	const postStudentAddress = async (form) => {
		const formData = {
			address: form.address,
			state: billingDetails.state,
			city: form.city,
			country: billingDetails.country,
			zipcode: form.zip,
			name: form.firstName,
			email: form.email,
		}
		try {
			await api.post(`/address/${customerId}`, formData)
		} catch (error) {
			console.log(error)
		}
	}

	const increaseClass = async (id, subscriptionId) => {
		try {
			console.log(subscriptionId)
			const res = await api.get(
				`subscriptions/subscription/stripe/success/${id}?sub_id=${subscriptionId}${
					resubscribe === "resubscribe" ? "&resubscribe=1" : ""
				}`
			)
			console.log(res)
		} catch (error) {
			console.log(error)
		}
	}

	const onSubmit = async (formData) => {
		setLoading(true)

		if (!stripe || !elements) {
			return
		}
		let address = {
			line1: formData.address,
			postal_code: formData.zip,
			city: formData.city,
			state: billingDetails.state,
			country: countries[billingDetails.country],
			email: billingDetails.email,
		}
		try {
			let paymentMethod = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardNumberElement),
				billing_details: {
					name: formData.firstName,
					address,
				},
			})

			const response = await api.post(`/subscriptions/subscribe/stripe/${customerId}/${plan._id}`, {
				address,
				paymentMethod: paymentMethod.paymentMethod.id,
			})

			let res = await stripe.confirmCardPayment(response?.data?.clientSecret, {
				payment_method: paymentMethod.paymentMethod.id,
			})

			if (res?.paymentIntent?.status === "succeeded") {
				postStudentAddress(formData)
				increaseClass(customerId, response.data.subscriptionId)
				setSuccessOpen(true)
			}
			if (res?.error?.payment_intent?.amount === plan.amount) {
				setErrorMessage(res?.error?.message)
				setFailedOpen(true)
			}
		} catch (error) {
			console.log(error)
		}

		setLoading(false)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Subscriptionsuccess successOpen={successOpen} setsuccessOpen={setSuccessOpen} />
			<Subscriptionfailed open={failedOpen} setOpen={setFailedOpen} errorMessage={errorMessage} />

			<label className="checkout-label">
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<p style={{fontWeight: "bold"}}>Card Details</p>
					<div>
						<Amex className="card-imgs" />
						<Mastercard className="card-imgs" />
						<Visa className="card-imgs" />
						<Discovery className="card-imgs" />
					</div>
				</div>
				<CardNumberElement onChange={(e) => setInvalidCardNumber(e)} options={options} />

				<p className="error-message">
					{invalidCardNumber && invalidCardNumber.error ? invalidCardNumber.error.message : null}
				</p>
				<CardExpiryElement onChange={(e) => setInvalidDate(e)} options={options} />
				<p className="error-message">
					{invalidDate && invalidDate.error ? invalidDate.error.message : null}
				</p>
				<CardCvcElement onChange={(e) => console.log(e)} options={options} />
			</label>

			<label className="checkout-label">
				Billing Details
				<input
					value={billingDetails.email}
					type="email"
					required
					className="StripeElement"
					style={{width: "100%"}}
					placeholder="Email"
					name="email"
					onChange={handleChange}
					{...register("email", {
						required: true,
						pattern:
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
					})}
				/>
				{errors?.firstName?.type === "required" && (
					<p className="error-message">Email is Required</p>
				)}
				<input
					value={billingDetails.name}
					type="text"
					className="StripeElement"
					style={{width: "100%"}}
					placeholder="Full Name"
					name="name"
					onChange={handleChange}
					{...register("firstName", {
						required: true,
						maxLength: 20,
					})}
				/>
				{errors?.firstName?.type === "required" && (
					<p className="error-message">Full Name is required</p>
				)}
				{errors?.firstName?.type === "maxLength" && (
					<p className="error-message">Full Name cannot exceed 20 characters</p>
				)}
				<input
					value={billingDetails.line1}
					type="text"
					className="StripeElement"
					style={{width: "100%"}}
					placeholder="Address"
					name="line1"
					onChange={handleChange}
					{...register("address", {
						required: true,
					})}
				/>
				{errors?.address?.type === "required" && (
					<p className="error-message">Address is required</p>
				)}
				<input
					value={billingDetails.city}
					type="text"
					className="StripeElement"
					name="city"
					onChange={handleChange}
					style={{width: "100%"}}
					placeholder="City"
					{...register("city", {
						required: true,
					})}
				/>
				{errors?.city?.type === "required" && <p className="error-message">City is required</p>}
				<CountryDropdown
					onChange={(e) => {
						setBillingDetails({
							...billingDetails,
							country: e,
						})
						console.log(e)
					}}
					value={billingDetails.country}
					className="StripeElement"
					whitelist={["US", "GB", "IN", "SG", "AU"]}
					style={{width: "343px"}}
				/>
				<RegionDropdown
					className="StripeElement"
					style={{width: "343px"}}
					onChange={(e) =>
						setBillingDetails({
							...billingDetails,
							state: e,
						})
					}
					country={billingDetails.country}
					value={billingDetails.state}
				/>
				<input
					value={billingDetails.postal_code}
					type="text"
					className="StripeElement"
					style={{width: "100%"}}
					placeholder="Zip code"
					name="postal_code"
					onChange={handleChange}
					{...register("zip", {
						required: true,
					})}
				/>
				{errors?.zip?.type === "required" && <p className="error-message">Zip code is required</p>}
			</label>

			<button className="paynow-btn" type="submit" disabled={!stripe}>
				{loading ? (
					<CircularProgress style={{height: 20, width: 20, color: "white", marginTop: 10}} />
				) : (
					"Pay now"
				)}
			</button>
		</form>
	)
}

export default StripeCardElement
