import {Card} from "@material-ui/core"
import React from "react"
import {DollarSign, PlusCircle, MinusCircle} from "react-feather"
const RewardsCard = ({rewardsHistoryData}) => {
	return (
		<div style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>
			<Card
				style={{
					width: "100%",
					height: 70,
					marginBottom: 15,
					background: "rgba( 255, 255, 255, 0.45 )",
					boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
					backdropFilter: "blur( 8.5px )",
					WebkitBackdropFilter: "blur( 8.5px )",
					borderRadius: "10px",
					border: "1px solid rgba( 255, 255, 255, 0.18 )",
				}}
			>
				<div
					style={{
						margin: 15,
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
						<div
							style={{
								height: 40,
								width: 40,
								backgroundColor: "#ecf0f1",
								borderRadius: 12,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{rewardsHistoryData.present - rewardsHistoryData.prev > 0 ? (
								<PlusCircle style={{color: "#2980b9"}} />
							) : (
								<MinusCircle style={{color: "#e74c3c"}} />
							)}
						</div>
					</div>

					<div
						style={{
							marginLeft: 10,
							display: "flex",
							alignItems: "center",
						}}
					>
						<p
							style={{
								fontWeight: "bold",
								fontSize: 14,
								color:
									rewardsHistoryData.present - rewardsHistoryData.prev > 0 ? "#2980b9" : "#e74c3c",
								textAlign: "center",
							}}
						>
							{rewardsHistoryData.message}
						</p>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<p
							style={{
								fontSize: 16,
								textAlign: "right",
								fontWeight: "bold",
							}}
						>
							<p>{rewardsHistoryData.present - rewardsHistoryData.prev}</p>
						</p>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default RewardsCard
