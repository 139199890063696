import React, {useEffect, useState} from "react"
import Footer from "./Footer"
import Logo from "./logo512.png"
import {Link} from "react-router-dom"
import getWindowDimensions from "../../helpers/useDimensions"
const ReturnPolicy = () => {
	const {width} = getWindowDimensions()

	const [mobile, setMobile] = useState(false)

	useEffect(() => {
		mobileResposive()
	}, [width])
	function mobileResposive() {
		if (width < 700) {
			setMobile(true)
		} else {
			setMobile(false)
		}
	}

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<Link to="/">
					<img src={Logo} style={{height: "150px", width: "150px "}} alt="" />
				</Link>
				<strong>
					<span style={{fontSize: "26px"}}>
						<span data-custom-class="title">LiveSloka</span>
					</span>
				</strong>
			</div>
			<div
				style={{
					width: mobile ? "80%" : "50%",
					margin: "0 auto",
				}}
			>
				<div>
					<div>
						<div
							align="center"
							className="MsoNormal"
							style={{textAlign: "center", lineHeight: "115%", marginTop: 50}}
						>
							<div
								align="center"
								className="MsoNormal"
								style={{textAlign: "left", lineHeight: "150%"}}
							>
								<strong>
									<span style={{fontSize: "26px"}}>
										<span data-custom-class="title">RETURN POLICY</span>
									</span>
								</strong>
							</div>
							<div
								align="center"
								className="MsoNormal"
								style={{textAlign: "left", lineHeight: "150%"}}
							>
								<br />
							</div>
							<div
								align="center"
								className="MsoNormal"
								style={{textAlign: "left", lineHeight: "150%"}}
							>
								<span style={{fontSize: "15px"}}>
									<span style={{color: "rgb(89, 89, 89)"}}>
										<strong>
											<span data-custom-class="subtitle">
												Last updated <bdt className="question">June 01, 2020</bdt>
											</span>
										</strong>
									</span>
								</span>
							</div>
							<div
								align="center"
								className="MsoNormal"
								style={{textAlign: "left", lineHeight: "150%"}}
							>
								<br />
							</div>
							<div
								align="center"
								className="MsoNormal"
								style={{textAlign: "left", lineHeight: "150%"}}
							>
								<span style={{fontSize: "15px"}}>
									<br />
								</span>
							</div>
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span
								style={{
									fontSize: "15px",
									lineHeight: "115%",
									fontFamily: "Arial",
									color: "rgb(89, 89, 89)",
								}}
							>
								Thank you for your purchase. We hope you are happy with your purchase. However, if
								you are not completely satisfied with your purchase for any reason, you may return
								it to us for{" "}
								<bdt
									className="block-container if"
									data-type="if"
									id="03b751bb-5eee-5230-df87-d0707fec3124"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="policy_type"
											data-type="statement"
										/>
										<bdt data-type="body">a full refund only</bdt>
									</bdt>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="policy_type"
											data-type="statement"
										/>
									</bdt>
									. Please see below for more information on our return policy.
								</bdt>
							</span>
						</div>
					</div>
					<div style={{lineHeight: "1.1"}}>
						<br />
					</div>
					<div>
						<div className="MsoNormal" data-custom-class="heading_1" style={{lineHeight: "115%"}}>
							<strong>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										fontSize: "19px",
									}}
								>
									RETURNS
								</span>
							</strong>
						</div>
					</div>
					<div style={{lineHeight: "1.1"}}>
						<br />
					</div>
					<div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span
								style={{
									fontSize: "15px",
									lineHeight: "115%",
									fontFamily: "Arial",
									color: "rgb(89, 89, 89)",
								}}
							>
								All returns must be postmarked within{" "}
								<bdt
									className="block-container question question-in-editor"
									data-id="10b33c27-be1f-aeda-7ea3-7c1f52ee6130"
									data-type="question"
								>
									seven (7)
								</bdt>{" "}
								days of the purchase date. All returned items must be in new and unused condition,
								with all original tags and labels attached.
							</span>
						</div>
					</div>
					<div style={{lineHeight: "1.1"}}>
						<br />
					</div>
					<div>
						<div className="MsoNormal" data-custom-class="heading_1" style={{lineHeight: "115%"}}>
							<strong>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										fontSize: "19px",
									}}
								>
									RETURN PROCESS
								</span>
							</strong>
						</div>
					</div>
					<div style={{lineHeight: "1.1"}}>
						<br />
					</div>
					<div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span
								style={{
									fontSize: "15px",
									lineHeight: "115%",
									fontFamily: "Arial",
									color: "rgb(89, 89, 89)",
								}}
							>
								To return an item,{" "}
								<bdt
									className="block-container if"
									data-type="if"
									id="51c4b423-789c-79e6-4161-743acb653a2d"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="authorization_option"
											data-type="statement"
										/>
										<bdt data-type="body">
											please email customer service at{" "}
											<bdt
												className="block-container question question-in-editor"
												data-id="0b78538e-7550-6c62-669a-d2eb2ed0eafb"
												data-type="question"
											>
												info@livesloka.com
											</bdt>{" "}
											to obtain a Return Merchandise Authorization (RMA) number. After receiving a
											RMA number,&nbsp;
										</bdt>
									</bdt>
									<bdt className="statement-end-if-in-editor" data-type="close" />
								</bdt>
								place the item securely in its original packaging
								<bdt
									className="block-container if"
									data-type="if"
									id="903ce2af-7990-07ea-2615-36e36315d483"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="return_inlcude_option"
											data-type="statement"
										/>
										<bdt data-type="body">&nbsp;and include your proof of purchase,&nbsp;</bdt>
									</bdt>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="return_inlcude_option"
											data-type="statement"
										/>
									</bdt>
									and mail your return to the following address:
								</bdt>
							</span>
						</div>
						<div className="MsoNormal" style={{lineHeight: "1.1"}}>
							<span style={{fontSize: "15px"}}>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										color: "rgb(89, 89, 89)",
									}}
								>
									<br />
								</span>
							</span>
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "115%"}}>
							<span style={{fontSize: "15px"}}>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										color: "rgb(89, 89, 89)",
									}}
								>
									<bdt
										className="block-container question question-in-editor"
										data-id="49265208-ebd3-4d63-5ecb-9fcda064a7d5"
										data-type="question"
									>
										info@livesloka.com
									</bdt>
									<br />
								</span>
							</span>
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px"}}>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										color: "rgb(89, 89, 89)",
									}}
								>
									Attn: Returns
								</span>
							</span>
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px"}}>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										color: "rgb(89, 89, 89)",
									}}
								>
									<bdt
										className="block-container if"
										data-type="if"
										id="2e485380-f516-a019-540b-f82bd718b0df"
									>
										<bdt data-type="conditional-block">
											<bdt
												className="block-component"
												data-record-question-key="authorization_option"
												data-type="statement"
											/>
											<bdt data-type="body">RMA #</bdt>
										</bdt>
										<bdt className="statement-end-if-in-editor" data-type="close" />
									</bdt>
								</span>
							</span>
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px"}}>
								<span style={{color: "rgb(89, 89, 89)"}}>
									<bdt
										className="block-container question question-in-editor"
										data-id="22673e85-8c82-1948-b0e9-b16dd7c6f007"
										data-type="question"
									>
										2-164 Kesava Reddy Nagar Colony{" "}
									</bdt>
								</span>
							</span>{" "}
							<bdt className="block-component" />
							&nbsp;
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<bdt className="question">meerpet</bdt> <bdt className="statement-end-if-in-editor" />
							&nbsp;
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px"}}>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										color: "rgb(89, 89, 89)",
									}}
								>
									<bdt
										className="block-container question question-in-editor"
										data-id="765d45c0-0386-b367-b58a-832b154c7ee8"
										data-type="question"
									>
										hyderabad
									</bdt>
									, <bdt className="block-component" />
									<bdt className="block-component" />
									<bdt className="question">Telangana</bdt>
									<bdt className="statement-end-if-in-editor" />
									<bdt className="statement-end-if-in-editor" />{" "}
									<bdt
										className="block-container question question-in-editor"
										data-id="85b0476b-4b2d-4b3d-060f-fc67c287cbe7"
										data-type="question"
									>
										500097
									</bdt>{" "}
									<bdt className="block-component" />
									<bdt className="block-component" />
									&nbsp;
								</span>
							</span>
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px"}}>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										color: "rgb(89, 89, 89)",
									}}
								>
									<bdt className="question">India</bdt>{" "}
									<bdt className="statement-end-if-in-editor" />{" "}
									<bdt className="statement-end-if-in-editor" /> &nbsp;
								</span>
							</span>
						</div>
					</div>
					<div style={{lineHeight: "1.1"}}>
						<br />
					</div>
					<div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span
								style={{
									fontSize: "15px",
									lineHeight: "115%",
									fontFamily: "Arial",
									color: "rgb(89, 89, 89)",
								}}
							>
								<bdt
									className="block-container if"
									data-type="if"
									id="b7518b5d-84c0-c06e-ad97-c7421eb67b0b"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="shipping_fee_option"
											data-type="statement"
										/>
									</bdt>
									<bdt
										className="block-container if"
										data-type="if"
										id="d3f0beb2-1468-a072-da09-6936c6e877e2"
									>
										<bdt data-type="conditional-block">
											<bdt
												className="block-component"
												data-record-question-key="shipping_fee_option"
												data-type="statement"
											/>
										</bdt>
										<bdt
											className="block-container if"
											data-type="if"
											id="48a0f62a-d42f-f443-061d-cbbf93b49154"
										>
											<bdt data-type="conditional-block">
												<bdt
													className="block-component"
													data-record-question-key="shipping_fee_option"
													data-type="statement"
												/>
												<bdt data-type="body">
													Return shipping charges will be paid or reimbursed by us.&nbsp;
												</bdt>
											</bdt>
											<bdt className="statement-end-if-in-editor" data-type="close" />
										</bdt>
										<bdt
											className="block-container if"
											data-type="if"
											id="ac6ab4e0-da32-a003-8324-04d8b43cb50c"
										>
											<bdt data-type="conditional-block">
												<bdt
													className="block-component"
													data-record-question-key="shipping_fee_option"
													data-type="statement"
												/>
											</bdt>
										</bdt>
									</bdt>
								</bdt>
							</span>
						</div>
						<div className="MsoNormal" style={{lineHeight: "1.1"}}>
							<br />
						</div>
						<div className="MsoNormal" data-custom-class="heading_1" style={{lineHeight: "115%"}}>
							<strong>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										fontSize: "19px",
									}}
								>
									REFUNDS
								</span>
							</strong>
						</div>
						<div className="MsoNormal" style={{lineHeight: "115%"}}></div>
						<div className="MsoNormal" style={{lineHeight: "1.1"}}>
							<br />
						</div>
						<div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span
								style={{
									fontSize: "15px",
									lineHeight: "115%",
									fontFamily: "Arial",
									color: "rgb(89, 89, 89)",
								}}
							>
								After receiving your return and inspecting the condition of your item, we will
								process your{" "}
								<bdt
									className="block-container if"
									data-type="if"
									id="4c11860e-4346-687b-5cb3-3727f319e194"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="policy_type"
											data-type="statement"
										/>
										<bdt data-type="body">return</bdt>
									</bdt>
									<bdt className="statement-end-if-in-editor" data-type="close" />
								</bdt>
								. Please allow at least{" "}
								<bdt
									className="block-container question question-in-editor"
									data-id="ab10b1ab-f4a1-256f-29ae-65257d891371"
									data-type="question"
								>
									seven (7)
								</bdt>{" "}
								days from the receipt of your item to process your{" "}
								<bdt
									className="block-container if"
									data-type="if"
									id="4c11860e-4346-687b-5cb3-3727f319e194"
									style={{fontSize: "14.6667px"}}
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="policy_type"
											data-type="statement"
										/>
										<bdt data-type="body">return</bdt>
									</bdt>
									<bdt className="statement-end-if-in-editor" data-type="close" />
								</bdt>
								.
								<bdt
									className="block-container if"
									data-type="if"
									id="16f989a0-873e-9d7c-70f2-1c4b9cc7ecc4"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="policy_type"
											data-type="statement"
										/>
										<bdt data-type="body">
											&nbsp;Refunds may take 1-2 billing cycles to appear on your credit card
											statement, depending on your credit card company.
										</bdt>
									</bdt>
									<bdt className="statement-end-if-in-editor" data-type="close" />
								</bdt>
								<bdt
									className="block-container if"
									data-type="if"
									id="b49c01dc-6b19-275b-5996-06e6aeaaf917"
								>
									<bdt data-type="conditional-block">
										<bdt
											className="block-component"
											data-record-question-key="customer_notification_option"
											data-type="statement"
										/>
										<bdt data-type="body">
											&nbsp;We will notify you by email when your return has been processed.
										</bdt>
									</bdt>
								</bdt>
								<bdt
									className="block-container if"
									data-type="if"
									id="b49c01dc-6b19-275b-5996-06e6aeaaf917"
								>
									<bdt className="statement-end-if-in-editor" data-type="close" />
								</bdt>
							</span>
						</div>
						<div className="MsoNormal" style={{lineHeight: "115%"}}></div>
						<div className="MsoNormal" style={{lineHeight: "1.1"}}>
							<br />
						</div>
						<div className="MsoNormal" data-custom-class="heading_1" style={{lineHeight: "115%"}}>
							<strong>
								<span
									style={{
										lineHeight: "115%",
										fontFamily: "Arial",
										fontSize: "19px",
									}}
								>
									EXCEPTIONS &nbsp;
								</span>
							</strong>
							<span style={{fontSize: "15px"}}>
								<bdt className="block-component" /> <bdt className="block-component" /> &nbsp;
							</span>
						</div>
						<div data-custom-class="body_text" data-empty="true" style={{lineHeight: "1.1"}}>
							<span style={{color: "rgb(89, 89, 89)"}}>
								<span style={{fontSize: "15px"}}>
									<br />
								</span>
							</span>
						</div>
						<div data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{color: "rgb(89, 89, 89)"}}>
								<span style={{fontSize: "15px"}}>
									For defective or damaged products, please contact us at the contact details below
									to arrange a refund or exchange.&nbsp;
								</span>
							</span>
						</div>
						<div data-custom-class="body_text" data-empty="true" style={{lineHeight: "1.5"}}>
							<span style={{color: "rgb(89, 89, 89)"}}>
								<span style={{fontSize: "15px"}}>
									<bdt className="block-component" />
									&nbsp;
								</span>
							</span>
						</div>
						<div data-custom-class="body_text" data-empty="true" style={{lineHeight: "1.5"}}>
							<span style={{color: "rgb(89, 89, 89)"}}>
								<span style={{fontSize: "15px"}}>
									<strong>Please Note</strong>
								</span>
							</span>
						</div>
						<div data-custom-class="body_text" data-empty="true" style={{lineHeight: "1.5"}}>
							<span style={{color: "rgb(89, 89, 89)"}}>
								<span style={{fontSize: "15px"}}>
									<bdt className="statement-end-if-in-editor" /> &nbsp;
								</span>
							</span>
							<span style={{fontSize: "15px"}}>
								<bdt className="block-component">
									<span style={{color: "rgb(89, 89, 89)"}} />
								</bdt>{" "}
								&nbsp;
							</span>
							<span style={{color: "rgb(89, 89, 89)"}}>
								<span style={{fontSize: "15px"}}>
									&nbsp; <bdt className="block-component" />
									&nbsp; <bdt className="block-component" />
									&nbsp; &nbsp;
									<bdt className="forloop-component" />
									&nbsp;
								</span>
							</span>
						</div>
						<div data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
								<strong
									style={{
										fontWeight: 700,
										color: "rgb(0, 0, 0)",
										fontFamily: "sans-serif",
										fontSize: "medium",
										fontStyle: "normal",
										fontVariantLigatures: "normal",
										fontVariantCaps: "normal",
										letterSpacing: "normal",
										orphans: 2,
										textAlign: "start",
										textIndent: "0px",
										textTransform: "none",
										whiteSpace: "normal",
										widows: 2,
										wordSpacing: "0px",
										WebkitTextStrokeWidth: "0px",
										backgroundColor: "rgb(255, 255, 255)",
										textDecorationStyle: "initial",
										textDecorationColor: "initial",
									}}
								>
									<span style={{fontSize: "13px", color: "rgb(89, 89, 89)"}}>
										&nbsp; &nbsp; &nbsp;
										<strong
											style={{
												fontWeight: 700,
												color: "rgb(0, 0, 0)",
												fontFamily: "sans-serif",
												fontSize: "medium",
												fontStyle: "normal",
												fontVariantLigatures: "normal",
												fontVariantCaps: "normal",
												letterSpacing: "normal",
												orphans: 2,
												textAlign: "start",
												textIndent: "0px",
												textTransform: "none",
												whiteSpace: "normal",
												widows: 2,
												wordSpacing: "0px",
												WebkitTextStrokeWidth: "0px",
												textDecorationStyle: "initial",
												textDecorationColor: "initial",
												backgroundColor: "rgb(255, 255, 255)",
											}}
										>
											<span
												style={{
													color: "rgb(89, 89, 89)",
													fontFamily: "sans-serif",
													fontSize: "15px",
													fontStyle: "normal",
													fontVariantLigatures: "normal",
													fontVariantCaps: "normal",
													fontWeight: 400,
													letterSpacing: "normal",
													orphans: 2,
													textAlign: "start",
													textIndent: "0px",
													textTransform: "none",
													whiteSpace: "normal",
													widows: 2,
													wordSpacing: "0px",
													WebkitTextStrokeWidth: "0px",
													backgroundColor: "rgb(255, 255, 255)",
													textDecorationStyle: "initial",
													textDecorationColor: "initial",
													display: "inline !important",
												}}
											>
												●&nbsp;
											</span>
										</strong>
										&nbsp; &nbsp;&nbsp;
									</span>
								</strong>
								<bdt className="question">
									Parents must let Live Sloka know through email that they didnot like the class and
									would like return of their amount through email sent to info@livesloka.com. There
									will not be any refund of money after attending more than 2 classes.
								</bdt>
								&nbsp; <bdt className="forloop-component" />{" "}
								<bdt className="statement-end-if-in-editor" />
								&nbsp;
							</span>
						</div>
						<div style={{lineHeight: "1.1"}}>
							<br />
						</div>
						<div data-custom-class="heading_1">
							<span style={{fontSize: "19px", color: "rgb(0, 0, 0)"}}>
								<strong>QUESTIONS</strong>
							</span>
						</div>
						<div style={{lineHeight: "1.1"}}>
							<br />
						</div>
						<div data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
								If you have any questions concerning our return policy, please contact us at:
							</span>
							<span style={{fontSize: "15px"}}>
								<bdt className="block-component" />
							</span>
						</div>
						<div data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px"}}>
								<bdt className="question">
									<span style={{color: "rgb(89, 89, 89)"}}>+19542571859</span>
								</bdt>
								<span style={{color: "rgb(89, 89, 89)"}}>
									<bdt className="statement-end-if-in-editor" />
									&nbsp;
								</span>
							</span>
						</div>
						<div data-custom-class="body_text" style={{lineHeight: "1.5"}}>
							<span style={{fontSize: "15px", color: "rgb(89, 89, 89)"}}>
								<bdt className="question">info@livesloka.com</bdt>&nbsp;
							</span>
						</div>
						<style
							dangerouslySetInnerHTML={{
								__html:
									"\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
							}}
						/>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default ReturnPolicy
