import React from "react"
import {StudentBottomTabs} from ".."
import "./Card.css"
import SingleCard from "./SingleCard"
import SearchIcon from "@material-ui/icons/Search"

const CourseCard = () => {
	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<SearchIcon className="inputfieldsearch" />
				<input className="searchInput" type="text" />
			</div>
			<div className="cardContariner">
				<SingleCard />
				<SingleCard />
				<SingleCard />
				<SingleCard />
				<SingleCard />
				<SingleCard />
			</div>

			<div style={{marginBottom: "80px"}}>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default CourseCard
