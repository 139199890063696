/* eslint-disable no-unused-vars */
import React, {useState, useContext} from "react"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import {makeStyles} from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import authContext from "../../context/auth/authContext"
import SnackbarAlert from "../../components/SnackbarAlert"
import {Redirect} from "react-router-dom"
import {MenuAppBar} from "../../components"
import {CircularProgress} from "@material-ui/core"
import Logo from "./logo512.png"

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(15),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

export default function ChangePassword() {
	const classes = useStyles()

	const AuthContext = useContext(authContext)
	const {changePassword, loadUser, isAuthenticated} = AuthContext

	const [password, setPassword] = useState("")
	const [confirmpassword, setConfirmPassword] = useState("")
	const [result, setResult] = useState()

	const [message, setMessage] = useState()

	const [open, setOpen] = useState(false)
	const [color, setColor] = useState("")

	const [loading, setLoading] = useState(false)

	const onSubmit = async (e) => {
		e.preventDefault()

		if (password === confirmpassword) {
			const data = await changePassword(password, confirmpassword)
			setLoading(true)
			setResult(data)

			if (data.status === 400) {
				setMessage(data.data.message)
			}
			setOpen(true)
			setColor("error")
		} else {
			setOpen(true)
			setColor("error")
			setMessage("Password and confirm password didn't match")
		}
		setLoading(false)
	}

	if (result && result.status === 200) {
		localStorage.setItem("firstTimeLogin", "N")
		return <Redirect to="/user-dashboard" />
	}
	return (
		<>
			{result && result.status === 400 ? (
				<SnackbarAlert setOpen={setOpen} open={open} message={message} color={color} />
			) : (
				""
			)}
			<MenuAppBar />
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img src={Logo} style={{height: "150px", width: "150px "}} alt="" />

					<Typography component="h1" variant="h5">
						Change Password
					</Typography>
					<form className={classes.form} onSubmit={onSubmit}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoFocus
							onChange={(e) => setPassword(e.target.value)}
							value={password}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Confirm Password"
							type="password"
							id="password"
							onChange={(e) => setConfirmPassword(e.target.value)}
							value={confirmpassword}
						/>

						{loading ? (
							<div style={{textAlign: "center", marginTop: "20px"}}>
								<CircularProgress />
							</div>
						) : (
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Change Password
							</Button>
						)}
					</form>
				</div>
			</Container>
		</>
	)
}
