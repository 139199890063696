import React from "react"
import {MenuAppBar, StudentBottomTabs} from "../../components"
const PaymentInfo = () => {
	return (
		<div>
			<MenuAppBar userName={""} />
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",
					minHeight: "77vh",
				}}
			>
				<h3> Coming Soon.. </h3>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default PaymentInfo
