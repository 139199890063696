/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import "react-h5-audio-player/lib/styles.css"
import {MenuAppBar} from "../../components"
import "./Video.css"
const MaterialPage = () => {
	const urlParams = new URLSearchParams(window.location.search)
	const VideoType = urlParams.get("type")
	const link = urlParams.get("link")
	let youtubeUrl

	function getId(url) {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
		const match = url.match(regExp)

		return match && match[2].length === 11 ? match[2] : null
	}

	if (VideoType === "Youtube") {
		youtubeUrl = getId(link)
	}
	return (
		<div>
			<MenuAppBar />

			{VideoType === "Youtube" ? (
				<div
					className="video"
					style={{
						position: "relative",
						paddingBottom: "56.25%" /* 16:9 */,
						paddingTop: 25,
						height: "80%",
						width: "90%",
						margin: "0 auto",
						marginTop: 100,
					}}
				>
					<iframe
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
						}}
						src={`https://www.youtube.com/embed/${youtubeUrl && youtubeUrl}`}
						frameBorder="0"
					/>
				</div>
			) : (
				// <div style={{ margin: "0 auto", width: "80%", marginTop: "50px" }}>
				//   <VideoPlayer
				//     src={firebaseVideo}
				//     width={
				//       window.innerWidth < 415
				//         ? window.innerWidth - 80
				//         : window.innerWidth - 300
				//     }
				//     height={
				//       window.innerHeight < 680
				//         ? window.innerHeight - 400
				//         : window.innerHeight - 200
				//     }
				//   />
				// </div>
				""
			)}

			{/* <div style={{ margin: "0 auto", width: "80%", marginTop: "50px" }}>
        <AudioPlayer
          autoPlay
          src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
          onPlay={(e) => console.log("onPlay")}
          // other props here
        />
      </div> */}
		</div>
	)
}

export default MaterialPage
