import React, {createContext, useEffect, useState} from "react"
import api from "../helpers/api"

export const NotificationContext = createContext([])
export const NotificationContextProvider = ({children}) => {
	const [allMessages, setAllMessages] = useState([])
	const [unread, setUnread] = useState([])
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {
		if (localStorage.getItem("teacherId")) {
			let teacherId = localStorage.getItem("teacherId") !== "undefined"
			let query = teacherId
				? localStorage.getItem("teacherId") + `?isTeacher=1`
				: localStorage.getItem("userId")
			api
				.get("/messages/" + query)
				.then((data) => {
					setAllMessages(data?.data?.result?.allMessages)
					setUnread(data?.data?.result?.unSeenMessages)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [refresh])

	const updateUnRead = (id) => {
		setUnread((prev) => {
			let prevMessages = [...prev].filter((message) => !(message._id === id))
			return prevMessages
		})
	}

	const markTeacherAllAsRead = (email) => {
		api
			.post("/messages/teacher/mark/read", {email})
			.then((response) => {
				console.log(response)
				setRefresh((prev) => !prev)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	return (
		<NotificationContext.Provider
			value={{allMessages, unread, updateUnRead, setRefresh, markTeacherAllAsRead}}
		>
			{children}
		</NotificationContext.Provider>
	)
}
