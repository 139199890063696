import React, {useEffect, useState} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import Videocard from "./Videocard"
import api from "../../../helpers/api"
const StudentVideos = () => {
	const [videoData, setVideoData] = useState()
	useEffect(() => {
		getVideos()
	}, [])
	const getVideos = async () => {
		const data = await api.get(`/videos/customer/${localStorage.getItem("userId")}`)
		setVideoData(data?.data?.result)
	}
	return (
		<div>
			<MenuAppBar />

			<div
				style={{
					marginTop: 70,
					marginBottom: 80,
				}}
			>
				<p style={{fontSize: 24, marginLeft: 15}}>
					Videos |{" "}
					<a
						style={{textDecoration: "none"}}
						target="_blank"
						href="https://vimeo.com/channels/1734639"
					>
						Live Sloka Channel
					</a>
				</p>

				{videoData &&
					videoData.map((item, index) => (
						<>
							<Videocard data={item} />
							{index === videoData.length - 1 ? null : (
								<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
									<div style={{height: 1, backgroundColor: "#636e72", width: "95%"}}></div>
								</div>
							)}
						</>
					))}
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default StudentVideos
