import React, {useCallback, useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import {postforpayment} from "../context/Payment/PaymentService"
import {Link, Redirect} from "react-router-dom"
import {addAttendanceAfterPayment, updateLastTimeJoined} from "../Services/services"
import useWindowDimensions from "./useWindowDimensions"
import {Avatar} from "@material-ui/core"
import api from "./../helpers/api"
import BookIcon from "@material-ui/icons/Book"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import io from "socket.io-client"
import {Clock, XCircle} from "react-feather"
import LeavesModal from "./LeavesModal"
import {Alert} from "@material-ui/lab"
import ReviewsModal from "./ReviewsModal"

const socket = io(process.env.REACT_APP_API_KEY)
const Meetingcard = ({
	link,
	loading,
	setLoading,
	customerId,
	isJoinButtonDisabled,
	numberOfClassesBought,
	scheduleId,
	subject,
	desc2,
	setDialogOpen,
	setSelectedSchedule,
	_id,
	cancelledDates,
	scheduleDays,
	setOpen,
	setMessage,
	setColor,
	setRefresh,
	setAllowedDays,
	setEditingId,
	setSelectedDateToCancel,
	isWhereby,
	customerName,
	isSummerCampClass,
	isTeacherOnLeave,
	demo,
	allData,
	isClassTemperarilyCancelled,
	message,
}) => {
	const {width} = useWindowDimensions()
	const [leavesModal, setleavesModal] = useState(false)
	const [desktopView, setDesktopView] = useState(false)
	const [reviewsModal, setReviewsModal] = useState(false)
	const deskview = useCallback(() => {
		if (width > 800) {
			setDesktopView(true)
		} else {
			setDesktopView(false)
		}
	}, [width])

	useEffect(() => {
		deskview()
	}, [width, deskview])

	const useStyles = makeStyles({
		root: {
			margin: "10px",
			padding: "10px",
			background: demo ? "#ecf0f1" : "rgba( 255, 255, 255, 0.45 )",
			boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
			backdropFilter: "blur( 8.5px )",
			WebkitBackdropFilter: "blur( 8.5px )",
			borderRadius: "10px",
			border: "1px solid rgba( 255, 255, 255, 0.18 )",
			width: desktopView ? 400 : "100%",
		},
	})

	const classes = useStyles()
	const [id, setId] = useState("")
	const [redirect, setRedirect] = useState("")
	// const confirm = useConfirm()

	useEffect(() => {
		setId(customerId)
	}, [customerId])

	const onPayNowClick = async () => {
		setLoading(true)
		const formData = {
			id,
		}

		const data = await postforpayment(formData)

		if (data.status === 200) {
			if (data.data.type === "PAYPAL" && data.data.link) {
				window.location.assign(data.data.link)
			} else if (data.data.type === "RAZORPAY") {
				const options = {
					key: process.env.REACT_APP_RAZORPAY_KEY_ID,
					currency: data.data.currency,
					amount: data.data.amount.toString(),
					order_id: data.data.id,
					name: "Payment for Livesloka Class",
					notes: [id],
					handler: (response) => {
						if (response.razorpay_order_id) {
							addAttendanceAfterPayment(customerId)
								.then((data) => {
									setRedirect("SUCCESS")
								})
								.catch((err) => {
									setRedirect("FAILED")
								})
						} else {
							setRedirect("FAILED")
						}
					},
					prefill: {
						email: localStorage.getItem("userId"),
					},
				}
				const paymentObject = new window.Razorpay(options)
				paymentObject.open()
			}
		}
	}

	if (redirect) {
		return <Redirect to={redirect === "SUCCESS" ? "/payment-success" : "/payment-failed"} />
	}

	const deleteCancelledClass = (id) => {
		if (window.confirm("Cancel Leave ?")) {
			api
				.delete(`/cancelclass/${id}`)
				.then((data) => {
					setColor("success")
					setMessage(data.data.message)
					setOpen(true)
					setRefresh((prev) => !prev)
				})
				.catch((err) => {
					console.log(err)
					setColor("error")
					setMessage(
						err.response && err.response.data && err.response.data.error
							? err.response.data.error
							: "Something went wrong"
					)
					setOpen(true)
				})
		}
	}

	const updateCancelledClass = (id, cancelledDate) => {
		setEditingId(id)
		setDialogOpen(true)
		setSelectedSchedule({
			subject,
			_id,
		})
		setAllowedDays(scheduleDays)
		setSelectedDateToCancel(new Date(cancelledDate))
	}

	const joinClass = (link) => {
		updateLastTimeJoined(localStorage.getItem("userId"), _id)
			.then((data) => {
				socket.emit("student-joined-class", {
					scheduleId,
					email: localStorage.getItem("userId"),
				})
				window.location = link
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<>
			<Card
				style={{
					backgroundColor: isTeacherOnLeave ? "rgba(235, 123, 123, 0.1)" : undefined,
				}}
				className={classes.root}
			>
				{isClassTemperarilyCancelled && (
					<Alert variant="filled" severity="warning">
						{message}
					</Alert>
				)}
				{isTeacherOnLeave && (
					<Alert variant="filled" severity="warning">
						{isTeacherOnLeave}
					</Alert>
				)}

				<div
					style={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
				>
					<div>
						{demo ? (
							<div
								style={{
									height: "60px",
									width: "60px",
									marginLeft: "10px",
									backgroundColor: "#ff4757",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									color: "white",
								}}
							>
								<Clock />
								<p style={{fontSize: 10}}>Demo</p>
							</div>
						) : (
							<Avatar
								style={{
									height: "50px",
									width: "50px",
									marginLeft: "10px",
									backgroundColor: "#27ae60",
								}}
							>
								{customerName[0]}
							</Avatar>
						)}
					</div>
					<div>
						<Typography
							style={{
								marginTop: "10px",
								fontWeight: "bold",
								fontSize: "18px",
								marginLeft: 16,
							}}
						>
							{customerName}
						</Typography>
						<div>
							<CardContent>
								<Typography
									variant="body2"
									color="textSecondary"
									component="p"
									style={{fontWeight: 600}}
								>
									{desc2}
								</Typography>
							</CardContent>
						</div>
					</div>
				</div>
				{!isSummerCampClass ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-evenly",
						}}
					>
						<>
							<div style={{textAlign: "center"}}>
								<h3 style={{color: "#3867d6", height: 30}}>{numberOfClassesBought || 0}</h3>
								<p style={{fontSize: "14px", fontWeight: "bold"}}>Classes Left</p>
							</div>

							<div onClick={onPayNowClick} style={{textAlign: "center", cursor: "pointer"}}>
								<AttachMoneyIcon style={{color: "#27ae60"}} />
								<p style={{fontSize: "14px", fontWeight: "bold"}}>
									{loading ? "Loading" : "Pay Now"}
								</p>
							</div>
						</>
						<div style={{textAlign: "center"}}>
							<Link
								to={`/particular-material/${scheduleId}`}
								style={{
									textDecoration: "none",
								}}
							>
								<BookIcon style={{color: "#27ae60"}} />
								<p
									style={{
										fontSize: "14px",
										fontWeight: "bold",
										color: "black",
									}}
								>
									Materials
								</p>
							</Link>
						</div>
					</div>
				) : (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<div style={{textAlign: "center", marginTop: "-15px"}}>
							<p style={{fontSize: "14px", fontWeight: "bold"}}>
								<span style={{color: "#3867d6", fontSize: 16}}>{numberOfClassesBought}</span>{" "}
								Classes Left
							</p>
						</div>
					</div>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						marginTop: "20px",
						alignItems: "center",
					}}
				>
					<Button
						onClick={() => joinClass(link)}
						disabled={
							(!isSummerCampClass && isJoinButtonDisabled) ||
							isTeacherOnLeave ||
							isClassTemperarilyCancelled
						}
						style={{
							backgroundColor:
								(!isSummerCampClass && isJoinButtonDisabled) || isTeacherOnLeave
									? "#aaa"
									: "#27ae60",
							color: "#fff",
							height: "40px",
							width: 150,
						}}
					>
						Join Class
					</Button>
					{!isSummerCampClass ? (
						""
					) : (
						<Link
							to={`/particular-material/${scheduleId}`}
							style={{
								textDecoration: "none",
							}}
						>
							<Button
								style={{
									backgroundColor: "#f39c12",
									color: "white",
									height: "40px",
									width: 140,
									marginLeft: 10,
								}}
							>
								View Materials
							</Button>
						</Link>
					)}
				</div>
			</Card>

			<LeavesModal
				open={leavesModal}
				setOpen={setleavesModal}
				cancelledDates={cancelledDates}
				updateCancelledClass={updateCancelledClass}
				deleteCancelledClass={deleteCancelledClass}
			/>
			<ReviewsModal open={reviewsModal} setOpen={setReviewsModal} allData={allData} />
		</>
	)
}

export default Meetingcard
