import api from "../../helpers/api"

export const getPayeeDetails = async (email) => {
	try {
		const res = await api.get(
			`/customer/email?email=${email}&q=firstName,lastName,proposedAmount,className,isPaymentDone`
		)
		return res
	} catch (err) {
		return err.response
	}
}

export const postforpayment = async (formData) => {
	try {
		const res = await api.post(`payment/pay`, formData)
		return res
	} catch (err) {
		return err.response
	}
}
