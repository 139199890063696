import React, {useState, useEffect} from "react"
import {
	TextField,
	Button,
	CircularProgress,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	Typography,
} from "@material-ui/core"
import {firebase} from "../../../Firebase"
import "./Inputfield.css"
import {MenuAppBar, SnackbarAlert} from "../../../components"
import api from "../../../helpers/api"
import {Redirect} from "react-router-dom"
import "sweetalert2/src/sweetalert2.scss"

const MaterialUpload = () => {
	const [onVideo, setOnVideo] = useState()
	const [fileType, setFileType] = useState()
	const [loading, setLoading] = useState(false)
	const [classNames, setClassNames] = useState()
	const [youtubeURL, setYoutubeURL] = useState("")
	const [value, setValue] = useState("youtube")
	const [classNameIDS, setClassNameIDS] = useState()
	const [uploadAlert, setUploadAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState("")
	const [alertColor, setAlertColor] = useState("")
	const [subLoading, setSubLoading] = useState(false)
	const [materialName, setMaterialName] = useState("")
	const [redirect, setRedirect] = useState(false)
	useEffect(() => {
		getClassDetails()
	}, [])

	const getClassDetails = async () => {
		try {
			const data = await api.get(
				`${process.env.REACT_APP_API_KEY}/uploads/getTeacherSchds/${localStorage.getItem(
					"teacherId"
				)}`
			)
			setClassNames(data && data.data.obj)
		} catch (error) {
			console.log(error.response)
		}
	}

	const onSubmitMaterial = async () => {
		setSubLoading(true)

		if (value === "own") {
			if (onVideo) {
				setLoading(true)
				setFileType(onVideo[0].type)
				let storageRef = firebase.storage().ref(`${onVideo[0].type}/${onVideo[0].name}`)

				const data = await storageRef.put(onVideo[0])

				storageRef.getDownloadURL().then(async (url) => {
					if (url) {
						const formData = {
							uploadLink: url,
							teacherId: localStorage.getItem("teacherId"),
							typeOfMaterial: onVideo[0].type,
							materialName,
						}

						try {
							const data = await api.post(
								`${process.env.REACT_APP_API_KEY}/uploads/material`,
								formData
							)
							if (data.status === 200) {
								setUploadAlert(true)
								setAlertColor("success")
								setAlertMessage("Material submitted successfully !")
								setYoutubeURL("")
								setClassNameIDS()
								setTimeout(() => {
									setRedirect(true)
								}, 2000)
							}
						} catch (error) {
							console.log(error.response)
							if (error.response.status === 500) {
								setUploadAlert(true)
								setAlertColor("error")
								setAlertMessage("Something went wrong! Reload Page")
							}
						}
					} else {
						setUploadAlert(true)
						setAlertColor("error")
						setAlertMessage("Please input Youtube video url or Upload your file")
					}
				})
			}
		} else if (value === "youtube") {
			if (youtubeURL) {
				const formData = {
					uploadLink: youtubeURL,
					teacherId: localStorage.getItem("teacherId"),
					typeOfMaterial: "Youtube",
					materialName,
				}

				try {
					const data = await api.post(`${process.env.REACT_APP_API_KEY}/uploads/material`, formData)
					if (data.status === 200) {
						setUploadAlert(true)
						setAlertColor("success")
						setAlertMessage("Youtube Link submitted successfully !")
						setTimeout(() => {
							setRedirect(true)
						}, 2000)
						setYoutubeURL("")
					}
				} catch (error) {
					console.log(error.response)
					if (error.response.status === 500) {
						setUploadAlert(true)
						setAlertColor("error")
						setAlertMessage("Something went wrong! Reload Page")
					}
				}
			} else {
				setUploadAlert(true)
				setAlertColor("error")
				setAlertMessage("Please input Youtube video url or Upload your file")
			}
		}

		setSubLoading(false)
	}

	const handleChange = (event) => {
		setValue(event.target.value)
	}

	if (redirect) {
		return <Redirect to="/manage-materials" />
	}

	const handleVideoChange = (e) => {
		// if (
		//   e.target.files[0].type === "video/mp4" ||
		//   e.target.files[0].type === "audio/mp4" ||
		//   e.target.files[0].type === "video/mpeg" ||
		//   e.target.files[0].type === "application/pdf" ||
		//   e.target.files[0].type === "audio/mpeg" ||
		//   e.target.files[0].type === "image/png" ||
		//   e.target.files[0].type === "image/jpeg"
		// ) {
		setOnVideo(e.target.files)
		// } else {
		//   Swal.fire({
		//     title: "File format not supported",
		//     text: "MP3, MP4, JPG, PNG, PDF files only supported",
		//   });
		// }
	}

	return (
		<div>
			{uploadAlert ? (
				<SnackbarAlert
					open={uploadAlert}
					setOpen={setUploadAlert}
					message={alertMessage}
					color={alertColor}
				/>
			) : (
				""
			)}

			<MenuAppBar userName={""} />
			<div style={{marginTop: 100}}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<TextField
						style={{width: 300, marginBottom: 20}}
						label="Material Name"
						variant="outlined"
						onChange={(e) => setMaterialName(e.target.value)}
						value={materialName}
					/>

					<FormControl component="fieldset" style={{marginBottom: 20}}>
						<FormLabel component="legend" style={{marginBottom: 10}}>
							Select Upload
						</FormLabel>
						<RadioGroup
							value={value}
							onChange={handleChange}
							style={{display: "flex", flexDirection: "row", width: "300px"}}
						>
							<FormControlLabel
								value="youtube"
								control={<Radio color="primary" />}
								label="Youtube Link"
								style={{marginRight: "50px"}}
							/>
							<FormControlLabel value="own" control={<Radio color="primary" />} label="Own File" />
						</RadioGroup>
					</FormControl>

					{value === "youtube" ? (
						<TextField
							style={{width: 300, marginBottom: 20}}
							label="Youtube URL"
							variant="outlined"
							onChange={(e) => setYoutubeURL(e.target.value)}
							value={youtubeURL}
						/>
					) : (
						<>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									marginBottom: 20,
								}}
							>
								<input
									// onChange={(e) => setOnVideo(e.target.files)}
									onChange={handleVideoChange}
									className="uploadBtn"
									type="file"
									// accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.zip,.rar,.mp3,.mp4,.mpeg"
								/>
								<Typography
									style={{
										fontSize: "18px",
										fontWeight: "bold",
										marginTop: "5px",
									}}
								>
									{onVideo && onVideo[0].name}
								</Typography>
							</div>
						</>
					)}
					{subLoading ? (
						<CircularProgress />
					) : (
						<Button variant="contained" onClick={onSubmitMaterial}>
							Submit
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

export default MaterialUpload
