import React, {useContext} from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import SingleNotification from "../pages/meetings/SingleNotification"
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive"
import {NotificationContext} from "../context/NotificationContext"
import {Backdrop, Badge, makeStyles, Button} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	markAllAsReadContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
	},
}))

export default function Notifications() {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const NotificationContextData = useContext(NotificationContext)
	const {unread, allMessages, markTeacherAllAsRead} = NotificationContextData

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleMarkReadClick = (e) => {
		e.stopPropagation()
		console.log("yes working")
		markTeacherAllAsRead(localStorage.getItem("userId"))
	}

	const classes = useStyles()

	return (
		<div>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<Badge badgeContent={unread && unread.length} color="primary">
					<NotificationsActiveIcon />
				</Badge>
			</IconButton>

			<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							maxHeight: "500px",
							width: "350px",
							padding: 20,
						},
					}}
				>
					<div className={classes.markAllAsReadContainer}>
						<Button onClick={handleMarkReadClick}>Mark all as read</Button>
					</div>
					<h3 style={{textAlign: "center", marginBottom: 10}}>Notifications</h3>
					{allMessages &&
						allMessages.map((message) => (
							<SingleNotification
								isImage={message.isImage}
								image={message.image}
								hideClose={true}
								icon={message.icon}
								color={message.background}
								title={message.title}
								text={message.message}
								time={message.teacherLeaveDate}
							/>
						))}
				</Menu>
			</Backdrop>
		</div>
	)
}
