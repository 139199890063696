import React, {useEffect, useRef, useState} from "react"
import {io} from "socket.io-client"
import {v4 as uuidv4} from "uuid"
import axios from "axios"

let socket
const ChatBot = () => {
	const lastElement = useRef(null)

	const [timeout, setSTimeout] = useState(undefined)

	const [roomID, setroomID] = useState(localStorage.getItem("roomID"))
	const userID = localStorage.getItem("userId")

	const [message, setmessage] = useState("")
	const [messages, setmessages] = useState([])

	const [isTyping, setIsTyping] = useState(false)

	const [canEdit, setCanEdit] = useState(false)

	const createRoom = (questionType) => {
		console.log("in create room")
		if (!roomID) {
			console.log("in create room inside")

			const id = uuidv4()
			socket.emit(
				"create-room",
				{
					roomID: id,
					userID,
				},
				(error) => {
					if (error) {
						alert(JSON.stringify(error))
					} else {
						setroomID(id)
						localStorage.setItem("roomID", id)
						notifyRoom(id, questionType, [])
					}
				}
			)

			// setmessages([]);
		}
	}

	const notifyRoom = (roomID, type, msgs) => {
		const typeMessage = userTypeMessage(type)
		console.log(typeMessage)
		socket.emit("notifyToRoomFromUser", {roomID, userID, typeMessage}, (error) => {
			if (error) {
				alert(JSON.stringify(error))
			} else {
				const questionMessages = [
					...msgs,
					{
						role: type === 5 ? 0 : 1,
						message: typeMessage,
						name: "Live Sloka",
					},
				]
				setmessages(questionMessages)
				setCanEdit(true)
			}
		})
	}

	const userTypeMessage = (type) => {
		switch (type) {
			case 1:
				return "Teacher did not join the class yet"

			case 2:
				return "I did not get material"

			case 3:
				return "I would like to take leave"

			case 4:
				return "I have a payment issue"

			case 5:
				return "Please write us your query"
			default:
				return "No Type"
		}
	}

	const scrollToBottom = () => {
		lastElement.current?.scrollIntoView({behavior: "smooth"})
	}
	useEffect(() => {
		scrollToBottom()
	}, [messages, isTyping])

	useEffect(() => {
		socket = io.connect(`${process.env.REACT_APP_API_KEY}/`)

		axios.get(`${process.env.REACT_APP_API_KEY}/user/${userID}`).then((data) => {
			const id = data?.data?.roomID

			if (roomID !== id && id) {
				setroomID(id)
				localStorage.setItem("roomID", id)
			} else {
				localStorage.removeItem("roomID")
			}
			if (!id) {
				setmessages([
					{
						role: 0,
						message: "Hi there 🖐. I'm here to help with your general enquiries.",
						name: "Live Sloka",
					},
					{
						role: 0,
						message: (
							<p
								style={{
									marginTop: 20,
								}}
							>
								<button className="button" onClick={() => createRoom(1)}>
									Teacher didn't Join
								</button>
								<button className="button" onClick={() => createRoom(2)}>
									I didn't get material
								</button>
								<button className="button" onClick={() => createRoom(3)}>
									Leave Request
								</button>
								<button className="button" onClick={() => createRoom(4)}>
									Payment Issue
								</button>
								<button className="button" onClick={() => createRoom(5)}>
									Others
								</button>
							</p>
						),
						name: "Live Sloka",
					},
				])
			} else {
				console.log(id, "from chat bot")
				if (!localStorage.getItem("roomID")) {
					localStorage.setItem("roomID", id)
				}
				socket.emit("JOIN_ROOM", {roomID: id, isAdmin: false}, (error) => {
					if (error) alert(error)
				})
				axios.get(`${process.env.REACT_APP_API_KEY}/rooms/${id}`).then(({data}) => {
					const allMsgs = data.messages

					let filterdMsgs = [
						...allMsgs,
						{
							role: 0,
							message: "Hi there 🖐. I'm here to help with your general enquiries.",
							name: "Live Sloka",
							createdAt: new Date().toISOString(),
						},
						{
							role: 0,
							message: (
								<p
									style={{
										marginTop: 20,
									}}
								>
									<button className="button" onClick={() => notifyRoom(id, 1, allMsgs)}>
										Teacher didn't Join
									</button>
									<button className="button" onClick={() => notifyRoom(id, 2, allMsgs)}>
										I didn't get material
									</button>
									<button className="button" onClick={() => notifyRoom(id, 3, allMsgs)}>
										Leave Request
									</button>
									<button className="button" onClick={() => notifyRoom(id, 4, allMsgs)}>
										Payment Issue
									</button>
									<button className="button" onClick={() => notifyRoom(id, 5, allMsgs)}>
										Others
									</button>
								</p>
							),
							name: "Live Sloka",
							createdAt: new Date().toISOString(),
						},
					]

					if (filterdMsgs) {
						setmessages(filterdMsgs)
					}
				})
			}
		})
	}, [])

	useEffect(() => {
		socket.on("messageToRoomFromAdmin", ({role, message, name}) => {
			console.log(message)

			setIsTyping(false)
			setmessages((prevState) => {
				const copyState = [...prevState]
				copyState.push({role, message, name})
				return copyState
			})
		})
		socket.on("agent-typing", ({name, typing}) => {
			setIsTyping(typing)
		})

		// socket.on("roomCreated", () => {
		// 	setmessages([
		// 		{
		// 			role: 0,
		// 			message: "Please Wait we are connecting to a agent.",
		// 		},
		// 	])
		// })
		return removeListners
	}, [])

	const removeListners = () => {
		console.log("removing")
		socket.removeAllListeners()
	}

	const typingTimeout = (msg) => {
		console.log("stoped Typing")
		socket.emit("user-typing", {roomID, name: userID, typing: false, message: msg}, (error) => {
			if (error) {
				alert(JSON.stringify(error))
			}
		})
	}

	const handelSubmit = (e) => {
		e.preventDefault()
		if (message.trim() === "") {
			return
		}

		socket.emit("messageFromBot", {roomID, message, name: userID}, (error) => {
			if (error) {
				alert(JSON.stringify(error))
			}
		})
		setmessages((prevState) => {
			const copyState = [...prevState]
			copyState.push({role: 1, message})
			return copyState
		})
		setmessage("")
	}

	const handelChange = (e) => {
		let ftime = undefined
		socket.emit(
			"user-typing",
			{roomID, name: userID, typing: true, message: e.target.value},
			(error) => {
				if (error) {
					alert(JSON.stringify(error))
				}
			}
		)
		clearTimeout(timeout)
		ftime = setTimeout(() => typingTimeout(e.target.value), 2000)
		setSTimeout(ftime)

		setmessage(e.target.value)
	}

	return (
		<>
			{/* <div className="chatbot" id="chatbot"> */}
			{/* <div
					className="chatbot__header"
					onClick={(e) => {
						e.stopPropagation()
						showBot(false)
					}}
				>
					<p>
						<strong>Live Sloka</strong>
						<span
							className="u-text-highlight"
							style={{
								marginLeft: "10px",
							}}
						>
							Ask Query's{" "}
						</span>
					</p>
					<svg className="chatbot__close-button icon-speech" viewBox="0 0 32 32">
						<use xlinkHref="#icon-speech" />
					</svg>
					<svg className="chatbot__close-button icon-close" viewBox="0 0 32 32">
						<use xlinkHref="#icon-close" />
					</svg>
				</div> */}
			<div className="chatbot__message-window">
				{/* <img src={logo} alt="" className="chatbot_logo" /> */}

				<ul className="chatbot__messages" id="chatbot__messages">
					{messages.map(({role, message, name}, idx) => {
						if (role !== 1 && role !== 2) {
							return (
								<li className="is-ai animation" key={idx}>
									<div className="is-ai__profile-picture" title={name}>
										<svg className="icon-avatar" viewBox="0 0 32 32">
											<use xlinkHref="#avatar" />
										</svg>
									</div>
									<span className="chatbot__arrow chatbot__arrow--left"></span>
									<p className="chatbot__message" style={{paddingTop: 0}}>
										{/* <p className="chatbot__chatname">{name}</p> */}
										<span className="chatbot__chatname">{name}</span>
										<p>{message}</p>
									</p>
								</li>
							)
						} else {
							return (
								<li className="is-user animation" key={idx}>
									<p className="chatbot__message">{message}</p>
									{/* <span className="chatbot__arrow chatbot__arrow--right"></span> */}
								</li>
							)
						}
					})}
					{isTyping && (
						<li className="is-ai animation">
							<div className="is-ai__profile-picture">
								<svg className="icon-avatar" viewBox="0 0 32 32">
									<use xlinkHref="#avatar" />
								</svg>
							</div>
							<span className="chatbot__arrow chatbot__arrow--left"></span>
							<p className="chatbot__message">
								<div className="typing">
									<div className="dot"></div>
									<div className="dot"></div>
									<div className="dot"></div>
								</div>
							</p>
						</li>
					)}
				</ul>
				<div ref={lastElement}></div>
			</div>

			<form className="chatbot__entry chatbot--closed" onSubmit={handelSubmit}>
				<input
					type="text"
					className="chatbot__input"
					placeholder="Write a message..."
					value={message}
					disabled={!canEdit}
					onChange={handelChange}
					style={{
						cursor: canEdit ? "text" : "not-allowed",
					}}
				/>
				<button
					style={{
						outline: "none",
						border: "none",
						background: "transparent",
					}}
					type="submit"
				>
					<svg className="chatbot__submit" viewBox="0 0 32 32">
						<use xlinkHref="#icon-send" />
					</svg>
				</button>
			</form>
			{/* </div> */}

			{/* <!-- Symbols --> */}
			<svg
				style={{
					display: "none",
				}}
			>
				{/* <!-- Send icon --> */}
				<symbol id="icon-send" viewBox="0 0 23.97 21.9">
					<title>Send</title>
					<path d="M0.31,9.43a0.5,0.5,0,0,0,0,.93l8.3,3.23L23.15,0Z" />
					<path d="M9,14.6H9V21.4a0.5,0.5,0,0,0,.93.25L13.22,16l6,3.32A0.5,0.5,0,0,0,20,19l4-18.4Z" />
				</symbol>
				<symbol id="avatar">
					<path
						style={{fill: "#f39c12", fillRule: "evenodd"}}
						d="M18.766 12.25c2.203 0.641 5.234 2.812 5.234 9.922 0 3.219-2.391 5.828-5.328 5.828h-13.344c-2.938 0-5.328-2.609-5.328-5.828 0-7.109 3.031-9.281 5.234-9.922-0.781-1.234-1.234-2.688-1.234-4.25 0-4.406 3.594-8 8-8s8 3.594 8 8c0 1.563-0.453 3.016-1.234 4.25zM12 2c-3.313 0-6 2.688-6 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6zM18.672 26c1.828 0 3.328-1.703 3.328-3.828 0-4.922-1.656-8-4.75-8.156-1.406 1.234-3.234 1.984-5.25 1.984s-3.844-0.75-5.25-1.984c-3.094 0.156-4.75 3.234-4.75 8.156 0 2.125 1.5 3.828 3.328 3.828h13.344z"
					></path>
				</symbol>
			</svg>
		</>
	)
}

export default ChatBot
