import React, {useEffect, useRef, useState} from "react"
import {io} from "socket.io-client"
import axios from "axios"
import Chip from "@material-ui/core/Chip"
import moment from "moment"
import {Button, CircularProgress, IconButton} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import ReplyIcon from "@material-ui/icons/Reply"
import AttachmentIcon from "@material-ui/icons/Attachment"
import SendIcon from "@material-ui/icons/Send"
import {firebase} from "../Firebase/index"

import {CustomizeMessage, getRandomColor, hashCode, intToRGB, TextFormatter, toHex} from "./helpers"
import api from "../helpers/api"
import {SnackbarAlert} from "../components"
import AttachFile from "./AttachFile"
let socket
const Group = ({group}) => {
	const {groupID, isClosed} = group
	const lastElement = useRef(null)
	// const [timeout, setSTimeout] = useState(undefined)

	const userId = localStorage.getItem("userId")
	const username = localStorage.getItem("username")
	const roleID = +localStorage.getItem("role")
	const [message, setmessage] = useState("")
	const [messages, setmessages] = useState([])
	const inputRef = useRef()
	const [reply, setReplay] = useState(null)
	const [file, setFile] = useState()
	const [uploadLoading, setUploadLoading] = useState(false)
	// const [isTyping, setIsTyping] = useState(false)

	// const [isClosed, setIsClosed] = useState(isClosed)
	const [uploadAlert, setUploadAlert] = useState(false)
	const scrollToBottom = () => {
		lastElement.current?.scrollIntoView({behavior: "smooth"})
	}
	useEffect(() => {
		scrollToBottom()
	}, [messages])

	const areOnSameDay = (first, second) =>
		first.getFullYear() === second.getFullYear() &&
		first.getMonth() === second.getMonth() &&
		first.getDate() === second.getDate()
	useEffect(() => {
		socket = io.connect(`${process.env.REACT_APP_API_KEY}/`)

		if (groupID) {
			socket.emit("JOIN_GROUP", {groupID, isAgent: false}, (error) => {
				if (error) alert(error)
			})
		}

		axios.get(`${process.env.REACT_APP_API_KEY}/groups/${groupID}`).then(({data}) => {
			console.log(data)
			const {messages} = data
			// setmessages(messages)

			console.log(messages)
			if (messages.length <= 0) return

			const dates = [{...messages[0], start: true}]

			for (let i = 1; i < messages.length; i++) {
				if (!areOnSameDay(new Date(messages[i - 1].createdAt), new Date(messages[i].createdAt))) {
					dates.push({...messages[i], start: true})
				} else {
					dates.push({...messages[i], start: false})
				}
			}
			setmessages(dates)
		})
		inputRef.current.focus()
	}, [])

	useEffect(() => {
		// 	socket.on("messageToRoomFromAdmin", ({role, message, name}) => {
		// 		console.log(message)

		// 		setIsTyping(false)
		// 		setmessages((prevState) => {
		// 			const copyState = [...prevState]
		// 			copyState.push({role, message, name})
		// 			return copyState
		// 		})
		// 	})
		// 	socket.on("agent-typing", ({name, typing}) => {
		// 		setIsTyping(typing)
		// 	})

		socket.on(
			"messageToGroupFromBot",
			({role, message, userID, username, reply, messageType, fileURL}) => {
				// setIsTyping({
				// 	typing: false,
				// 	message: "",
				// })
				console.log(message)

				setmessages((prevState) => {
					const oldState = [...prevState]
					oldState.push({
						message,
						role,
						username,
						userID,
						createdAt: new Date(),
						reply,
						messageType,
						fileURL,
					})
					return oldState
				})
			}
		)

		socket.on("adminmessageToGroup", ({role, message, userID, username}) => {
			console.log(message)

			setmessages((prevState) => {
				const oldState = [...prevState]
				oldState.push({
					message,
					username,
					role,
					userID,
				})
				return oldState
			})
		})
		if (inputRef.current) inputRef.current.focus()

		return removeListners
	}, [])

	const removeListners = () => {
		console.log("removing")
		socket.removeAllListeners()
	}

	// const typingTimeout = (msg) => {
	// 	console.log("stoped Typing")
	// 	socket.emit("user-typing", {roomID, name: userID, typing: false, message: msg}, (error) => {
	// 		if (error) {
	// 			alert(JSON.stringify(error))
	// 		}
	// 	})
	// }

	const handelSubmit = (e) => {
		console.log("clicked")
		e.preventDefault()

		if (message.trim() === "") {
			return
		}

		/**
		 * use this for live chat notifications start
		 */
		socket.emit(
			"messageFromBotToGroup",
			{
				groupID: group.groupID,
				message,
				userID: userId,
				username,
				role: roleID,
				reply,
				messageType: "TEXT",
				fileURL: "",
			},
			(error) => {
				if (error) {
					alert(JSON.stringify(error))
				}
			}
		)
		/**
		 * use this for live chat notifications end
		 */
		setmessages((prevState) => {
			const copyState = [...prevState]
			copyState.push({
				role: roleID,
				message,
				userID: userId,
				username,
				reply,
				messageType: "TEXT",
			})
			return copyState
		})
		setmessage("")
		setReplay(null)
		if (inputRef.current) inputRef.current.focus()
	}

	const handelChange = (e) => {
		setmessage(e.target.value)
	}

	const renderReply = (
		{username, message, userID},
		main,
		name,
		start,
		isRight,
		mainID,
		createdAt
	) =>
		isRight ? (
			<div className="chat__replys" style={{marginTop: start ? "40px" : 0}}>
				<p className="chat__replys-message">
					<span
						className="chat__replys-name"
						style={{
							color: `${toHex(userId === userID ? "You" : username)}`,
						}}
					>
						{userId === userID ? "You" : username}
					</span>
					<span dangerouslySetInnerHTML={{__html: TextFormatter(message)}}></span>
					{/* <span className="chat--time">{moment(createdAt).format("LT")}</span> */}
				</p>
				<p className="chat__replys-main">
					<span dangerouslySetInnerHTML={{__html: TextFormatter(main)}}></span>
					<span className="chat--time">{moment(createdAt).format("LT")}</span>
					{renderReplyIcon(main, name, mainID)}
				</p>
			</div>
		) : (
			<div
				className="chat__replys"
				style={{marginTop: start ? "40px" : 0, backgroundColor: "#fff"}}
			>
				<p className="chat__replys-message">
					<span
						className="chat__replys-name"
						style={{
							color: `${toHex(userId === userID ? "You" : username)}`,
						}}
					>
						{userId === userID ? "You" : username}
					</span>
					<span dangerouslySetInnerHTML={{__html: TextFormatter(message)}}></span>
					{/* <span className="chat--time">{moment(createdAt).format("LT")}</span> */}
				</p>
				<p className="chat__replys-main">
					<span
						className="chat__replys-name"
						style={{
							color: `${toHex(name)}`,
						}}
					>
						{name}
					</span>
					<span dangerouslySetInnerHTML={{__html: TextFormatter(main)}}></span>
					<span className="chat--time">{moment(createdAt).format("LT")}</span>
					{renderReplyIcon(main, name, mainID)}
				</p>
			</div>
		)

	const renderReplyIcon = (message, username, userID) => (
		<IconButton
			aria-label="delete"
			style={{
				position: "absolute",
				right: "2%",
				bottom: "2%",
				display: "none",
			}}
			size="small"
			onClick={() => {
				setReplay({
					message,
					username: username || userID?.split("@")[0],
					userID,
				})
				inputRef.current.focus()
			}}
		>
			<ReplyIcon fontSize="small" />
		</IconButton>
	)

	const imageChange = (e, fileType) => {
		console.log("file changed")
		if (e.target.files && e.target.files.length > 0) {
			console.log(
				"This file size is: " + Number(e.target.files[0].size / 1024 / 1024).toFixed(2) + "MiB"
			)

			if (e.target.files[0].size / 1024 / 1024 < 20) {
				// setFile(e.target.files[0])
				// setSelectedImage(e.target.files[0])
				onSubmitFile(e.target.files[0], fileType)
				console.log(fileType)
			} else {
				setUploadAlert(true)
			}
		}
	}
	const onSubmitFile = async (file, fileType) => {
		setUploadLoading(true)
		if (file) {
			let storageRef = firebase.storage().ref(`${file.type}/${file.name}`)

			const data = await storageRef.put(file)

			storageRef.getDownloadURL().then(async (url) => {
				if (url) {
					console.log(url)
					socket.emit(
						"messageFromBotToGroup",
						{
							groupID: group.groupID,
							message,
							userID: userId,
							username,
							role: roleID,
							reply,
							messageType: fileType,
							fileURL: url,
						},
						(error) => {
							if (error) {
								alert(JSON.stringify(error))
							}
						}
					)
					setUploadLoading(false)

					setmessages((prevState) => {
						const copyState = [...prevState]
						copyState.push({
							role: roleID,
							message,
							userID: userId,
							username,
							reply,
							messageType: fileType,
							fileURL: url,
						})
						return copyState
					})
				} else {
					setUploadLoading(false)
					console.log("Image Failed To Upload")
				}
			})
		}
	}
	const handelClick = (id) => {
		document.getElementById(id).click()
	}

	return (
		<>
			<div
				className="chatbot__message-window"
				style={{
					height: isClosed ? "calc(100% - 70px)" : "calc(100% - 50px)",
				}}
			>
				{uploadAlert && (
					<SnackbarAlert
						open={uploadAlert}
						setOpen={setUploadAlert}
						message={"File Is larger than 20 MiB"}
						color={"error"}
					/>
				)}
				<ul className="chatbot__messages" id="chatbot__messages">
					{messages.map(
						(
							{role, message, username, userID, start, createdAt, reply, messageType, fileURL},
							idx
						) => {
							if (roleID === 1) {
								//main role customer
								if (role === 1 && userId === userID) {
									return (
										<li
											className="is-user animation"
											key={idx}
											onDoubleClick={() => {
												if (messageType === "TEXT") {
													setReplay({message, username: username || userID?.split("@")[0], userID})
													inputRef.current.focus()
												}
											}}
										>
											{!!reply ? (
												renderReply(
													reply,
													message,
													username || userID?.split("@")[0],
													start,
													true,
													userID,
													createdAt
												)
											) : (
												<p
													className="chatbot__message"
													style={{
														marginTop: start ? "40px" : 0,
													}}
												>
													{messageType === "TEXT" ? (
														<>
															<span
																dangerouslySetInnerHTML={{__html: TextFormatter(message)}}
															></span>
														</>
													) : (
														CustomizeMessage(messageType, fileURL)
													)}
													<span className="chat--time">{moment(createdAt).format("LT")}</span>
													{renderReplyIcon(message, username, userID)}
												</p>
											)}
											{start && (
												<Chip
													className="chatTime"
													label={moment(createdAt).calendar({
														sameDay: "[Today]",
														nextDay: "[Tomorrow]",
														nextWeek: "dddd",
														lastDay: "[Yesterday]",
														lastWeek: "[Last] dddd",
														sameElse: "MMMM Do, YYYY",
													})}
												/>
											)}
										</li>
									)
								} else {
									return (
										<li
											className="is-ai animation"
											key={idx}
											onDoubleClick={() => {
												if (messageType === "TEXT") {
													setReplay({message, username: username || userID?.split("@")[0], userID})
													inputRef.current.focus()
												}
											}}
										>
											<div
												className="is-ai__profile-picture"
												title={username || "name"}
												style={{marginTop: start ? "40px" : 0}}
											>
												<svg className="icon-avatar" viewBox="0 0 32 32">
													<use xlinkHref="#avatar" />
												</svg>
											</div>

											{!!reply ? (
												renderReply(
													reply,
													message,
													username || userID?.split("@")[0],
													start,
													false,
													userID,
													createdAt
												)
											) : (
												<>
													<span
														className="chatbot__arrow chatbot__arrow--left"
														style={{marginTop: start ? "58px" : "18px"}}
													></span>
													<p
														className="chatbot__message"
														style={{paddingTop: 0, marginTop: start ? "40px" : 0}}
													>
														<span
															className="chatbot__chatname"
															style={{
																color: `${toHex(username || userID?.split("@")[0])}`,
															}}
														>
															{username || userID?.split("@")[0]}
														</span>
														<p>
															{messageType === "TEXT" ? (
																<>
																	<span
																		dangerouslySetInnerHTML={{__html: TextFormatter(message)}}
																	></span>
																</>
															) : (
																CustomizeMessage(messageType, fileURL)
															)}
														</p>
														<span className="chat--time">{moment(createdAt).format("LT")}</span>
														{renderReplyIcon(message, username, userID)}

														{/*the issue of desendent error child*/}
													</p>
												</>
											)}
											{start && (
												<Chip
													className="chatTime"
													label={moment(createdAt).calendar({
														sameDay: "[Today]",
														nextDay: "[Tomorrow]",
														nextWeek: "dddd",
														lastDay: "[Yesterday]",
														lastWeek: "[Last] dddd",
														sameElse: "MMMM Do, YYYY",
													})}
												/>
											)}
										</li>
									)
								}
							} else if (roleID === 2) {
								if (role === 2 && userId === userID) {
									return (
										<li
											className="is-user animation"
											key={idx}
											onDoubleClick={() => {
												if (messageType === "TEXT") {
													setReplay({message, username: username || userID?.split("@")[0], userID})
													inputRef.current.focus()
												}
											}}
										>
											{!!reply ? (
												renderReply(
													reply,
													message,
													username || userID?.split("@")[0],
													start,
													true,
													userID,
													createdAt
												)
											) : (
												<p className="chatbot__message" style={{marginTop: start ? "40px" : 0}}>
													{messageType === "TEXT" ? (
														<>
															<span
																dangerouslySetInnerHTML={{__html: TextFormatter(message)}}
															></span>
														</>
													) : (
														CustomizeMessage(messageType, fileURL)
													)}
													<span className="chat--time">{moment(createdAt).format("LT")}</span>
													{renderReplyIcon(message, username, userID)}
												</p>
											)}

											{start && (
												<Chip
													className="chatTime"
													label={moment(createdAt).calendar({
														sameDay: "[Today]",
														nextDay: "[Tomorrow]",
														nextWeek: "dddd",
														lastDay: "[Yesterday]",
														lastWeek: "[Last] dddd",
														sameElse: "MMMM Do, YYYY",
													})}
												/>
											)}
										</li>
									)
								} else {
									return (
										<li
											className="is-ai animation"
											key={idx}
											onDoubleClick={() => {
												if (messageType === "TEXT") {
													setReplay({message, username: username || userID?.split("@")[0], userID})
													inputRef.current.focus()
												}
											}}
										>
											<div
												className="is-ai__profile-picture"
												title={username || "name"}
												style={{marginTop: start ? "40px" : 0}}
											>
												<svg className="icon-avatar" viewBox="0 0 32 32">
													<use xlinkHref="#avatar" />
												</svg>
											</div>

											{!!reply ? (
												renderReply(
													reply,
													message,
													username || userID?.split("@")[0],
													start,
													false,
													userID,
													createdAt
												)
											) : (
												<>
													<span
														className="chatbot__arrow chatbot__arrow--left"
														style={{marginTop: start ? "58px" : "18px"}}
													></span>
													<p
														className="chatbot__message"
														style={{paddingTop: 0, marginTop: start ? "40px" : 0}}
													>
														<span
															className="chatbot__chatname"
															style={{
																color: `${toHex(username || userID?.split("@")[0])}`,
															}}
														>
															{username || userID?.split("@")[0]}
														</span>
														<p>
															{messageType === "TEXT" ? (
																<>
																	<span
																		dangerouslySetInnerHTML={{__html: TextFormatter(message)}}
																	></span>
																</>
															) : (
																CustomizeMessage(messageType, fileURL)
															)}
														</p>
														<span className="chat--time">{moment(createdAt).format("LT")}</span>
														{renderReplyIcon(message, username, userID)}

														{/*the issue of desendent error child*/}
													</p>
												</>
											)}

											{start && (
												<Chip
													className="chatTime"
													label={moment(createdAt).calendar({
														sameDay: "[Today]",
														nextDay: "[Tomorrow]",
														nextWeek: "dddd",
														lastDay: "[Yesterday]",
														lastWeek: "[Last] dddd",
														sameElse: "MMMM Do, YYYY",
													})}
												/>
											)}
										</li>
									)
								}
							}
						}
					)}
				</ul>
				{uploadLoading && (
					<CircularProgress
						style={{
							marginLeft: "50%",
						}}
					/>
				)}
				<div ref={lastElement} style={{marginTop: 10}}></div>
			</div>
			{reply && (
				<div className="chat__reply">
					<p className="chat__reply-message">
						<span
							className="chat__reply-name"
							style={{
								color: `${toHex(userId === reply.userID ? "You" : reply?.username)}`,
							}}
						>
							{userId === reply.userID ? "You" : reply?.username}
						</span>
						{reply?.message}
					</p>
					<IconButton
						className="chat__reply-icon"
						onClick={() => {
							setReplay(null)
						}}
					>
						<CloseIcon />
					</IconButton>
				</div>
			)}
			{/* {selectedImage && (
				<div className="chat__file">
					<img src={URL.createObjectURL(selectedImage)} alt="Thumb" width="100%" />
				</div>
			)} */}

			{!isClosed && (
				<form className="chatbot__entry chatbot--closed" onSubmit={handelSubmit}>
					<input
						type="text"
						className="chatbot__input"
						placeholder="Write a message..."
						value={message}
						onChange={handelChange}
						ref={inputRef}
					/>
					<div style={{position: "relative"}} className="file__attach">
						{/* <Button
							component="label"
							style={{
								minWidth: 0,
							}}
						>
							<AttachmentIcon />
							<input
								type="file"
								accept="image/png, image/jpeg,video/mp4,video/x-m4v,video/*"
								hidden
								onChange={imageChange}
							/>
						</Button> */}
						<AttachFile onHandelClick={handelClick} />
						<input
							type="file"
							id="image"
							accept="image/png, image/jpeg"
							hidden
							onChange={(e) => imageChange(e, "IMAGE")}
						/>
						<input
							type="file"
							id="video"
							accept="video/mp4,video/x-m4v,video/*"
							hidden
							onChange={(e) => imageChange(e, "VIDEO")}
						/>
						<input
							type="file"
							id="audio"
							hidden
							accept="audio/mp3,audio/*;capture=microphone"
							onChange={(e) => imageChange(e, "AUDIO")}
						/>
						<input
							type="file"
							id="document"
							hidden
							accept=".pdf,.doc"
							onChange={(e) => imageChange(e, "DOCUMENT")}
						/>
						<IconButton
							color="secondary"
							type="submit"
							component="button"
							style={{
								color: "#f39c12",
							}}
						>
							<SendIcon />
						</IconButton>
						{/* <button
							style={{
								outline: "none",
								border: "none",
								background: "transparent",
							}}
							type="submit"
						>
							<svg className="chatbot__submit" viewBox="0 0 32 32">
								<use xlinkHref="#icon-send" />
							</svg>
						</button> */}
					</div>
				</form>
			)}

			<svg
				style={{
					display: "none",
				}}
			>
				<symbol id="icon-send" viewBox="0 0 23.97 21.9">
					<title>Send</title>
					<path d="M0.31,9.43a0.5,0.5,0,0,0,0,.93l8.3,3.23L23.15,0Z" />
					<path d="M9,14.6H9V21.4a0.5,0.5,0,0,0,.93.25L13.22,16l6,3.32A0.5,0.5,0,0,0,20,19l4-18.4Z" />
				</symbol>
				<symbol id="avatar">
					<path
						style={{fill: "#f39c12", fillRule: "evenodd"}}
						d="M18.766 12.25c2.203 0.641 5.234 2.812 5.234 9.922 0 3.219-2.391 5.828-5.328 5.828h-13.344c-2.938 0-5.328-2.609-5.328-5.828 0-7.109 3.031-9.281 5.234-9.922-0.781-1.234-1.234-2.688-1.234-4.25 0-4.406 3.594-8 8-8s8 3.594 8 8c0 1.563-0.453 3.016-1.234 4.25zM12 2c-3.313 0-6 2.688-6 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6zM18.672 26c1.828 0 3.328-1.703 3.328-3.828 0-4.922-1.656-8-4.75-8.156-1.406 1.234-3.234 1.984-5.25 1.984s-3.844-0.75-5.25-1.984c-3.094 0.156-4.75 3.234-4.75 8.156 0 2.125 1.5 3.828 3.328 3.828h13.344z"
					></path>
				</symbol>
			</svg>
		</>
	)
}

export default React.memo(Group)
