import React, {useState, useEffect} from "react"
import {MenuAppBar} from "../../../components"
import TeacherBottomTabs from "../../../components/BottomTabs/TeacherBottomTabs"
import MaterialCards from "./MaterialCards"
import {IconButton, Grid, Card, TextField} from "@material-ui/core"
import {Link} from "react-router-dom"
import api from "../../../helpers/api"
import moment from "moment"
import BackupIcon from "@material-ui/icons/Backup"
const ManageMaterials = () => {
	const [classNames, setClassNames] = useState()

	const [materialData, setMaterialData] = useState()

	const [searchField, setSearchField] = useState("")

	const [filteredData, setFilteredData] = useState()

	useEffect(() => {
		getClassDetails()
		getSchedules()
	}, [])
	const getClassDetails = async () => {
		try {
			const data = await api.get(
				`${process.env.REACT_APP_API_KEY}/uploads/teacher/${localStorage.getItem("teacherId")}`
			)

			let sortedDates =
				data &&
				data.data.result.sort(function (a, b) {
					return moment(b.createdAt).format("X") - moment(a.createdAt).format("X")
				})

			setMaterialData(data && sortedDates)
		} catch (error) {
			console.log(error.response)
		}
	}

	const getSchedules = async () => {
		try {
			const data = await api.get(
				`${process.env.REACT_APP_API_KEY}/uploads/schedules/${localStorage.getItem("teacherId")}`
			)
			setClassNames(data && data.data.obj)
		} catch (error) {
			console.log(error.response)
		}
	}

	useEffect(() => {
		filterData()
	}, [searchField])

	const filterData = () => {
		let value = searchField

		let regex = new RegExp(`^${value}`, `i`)
		const sortedArr =
			materialData &&
			materialData.filter((x) => {
				return regex.test(x.materialName)
			})

		console.log(sortedArr)
		setFilteredData(sortedArr)
	}

	return (
		<div>
			<MenuAppBar />

			<div
				style={{
					marginTop: 80,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					marginBottom: 50,
				}}
			>
				<TextField
					onChange={(e) => setSearchField(e.target.value)}
					variant="outlined"
					label="Search"
					style={{marginBottom: 20}}
				/>

				<Link to="/material-upload" style={{textDecoration: "none"}}>
					<Card
						style={{
							width: 200,
							height: 50,
							marginBottom: 20,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							cursor: "pointer",
							boxShadow:
								"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
							borderRadius: 15,
						}}
					>
						<IconButton>
							<BackupIcon style={{color: "#2980b9", height: 35, width: 35}} />
						</IconButton>{" "}
						<p style={{fontSize: 12, fontWeight: "bold"}}>Upload new file</p>
					</Card>
				</Link>

				<Grid container>
					{searchField ? (
						<>
							{filteredData &&
								filteredData.map((data) => (
									<Grid xs={12} lg={3} md={4}>
										<div style={{display: "flex", justifyContent: "center"}}>
											<MaterialCards
												key={data._id}
												data={data}
												classNames={classNames}
												onSubmitClick={getClassDetails}
											/>
										</div>
									</Grid>
								))}
						</>
					) : (
						<>
							{materialData &&
								materialData.map((data) => (
									<Grid xs={12} lg={3} md={4}>
										<div style={{display: "flex", justifyContent: "center"}}>
											<MaterialCards
												key={data._id}
												data={data}
												classNames={classNames}
												onSubmitClick={getClassDetails}
											/>
										</div>
									</Grid>
								))}
						</>
					)}
				</Grid>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
		</div>
	)
}

export default ManageMaterials
