import {Grid} from "@material-ui/core"
import React, {useContext, useEffect} from "react"
import {getJoinLink} from "../../Services/services"
import moment from "moment"
import io from "socket.io-client"
import "./style.css"
import SingleTeacherCard from "./SingleTeacherCard"
import {NotificationContext} from "../../context/NotificationContext"

const socket = io(process.env.REACT_APP_API_KEY)

function MeetingTable({meetings, day, value, setLeaveData}) {
	const {setRefresh} = useContext(NotificationContext)

	useEffect(() => {
		setRefresh((prev) => !prev)
	}, [setRefresh])

	function arrayMin(arr) {
		var len = arr.length,
			min = Infinity
		while (len--) {
			if (arr[len] < min) {
				min = arr[len]
			}
		}
		return min
	}

	const minFinder = (data) => {
		let times = []
		if (data.length > 0) {
			data.forEach((el) => {
				times.push(el.split("-")[1].substring(1, 5).replace(":", "."))
			})
		}
		return arrayMin(times)
	}

	const meetinStartTimeGenerator = (data) => {
		let wtg = ""
		let mintime = minFinder(data[day])
		mintime = mintime.toString().replace(".", ":")
		data[day].forEach((el) => {
			if (el.split("-")[1].includes(mintime)) {
				const strtime = el.split("-")
				wtg = wtg + strtime[1]
			}
		})
		return wtg
	}

	const joinClass = (scheduleId) => {
		let date = moment().format()
		getJoinLink(scheduleId, localStorage.getItem("teacherId"), date)
			.then((data) => {
				socket.emit("teacher-joined-class", {scheduleId})
				window.location.href = data.data.link
			})
			.catch((error) => {
				console.log(error)
			})
	}

	return (
		<>
			<Grid container spacing={3}>
				{meetings
					.map((meeting) => ({
						startTime: meetinStartTimeGenerator(meeting.slots),
						...meeting,
					}))
					.sort(
						(a, b) =>
							Date.parse("1970/01/01 " + a.startTime.slice(0, -2) + " " + a.startTime.slice(-2)) -
							Date.parse("1970/01/01 " + b.startTime.slice(0, -2) + " " + b.startTime.slice(-2))
					)
					.map((meeting, i) => (
						<Grid item xs={12} key={i} sm={6} md={4} lg={3}>
							<SingleTeacherCard
								meeting={meeting}
								value={value}
								joinClass={joinClass}
								day={day}
								setLeaveData={setLeaveData}
								key={meeting._id}
							/>
						</Grid>
					))}
			</Grid>
		</>
	)
}

export default MeetingTable
