import React from "react"
import animationData from "../../../assets/failed.json"
import Lottie from "react-lottie"
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core"

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
}

const Subscriptionfailed = ({open, setOpen, errorMessage}) => {
	const handleClose = () => {
		setOpen(false)
	}
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogContent>
				<Lottie options={defaultOptions} height={300} width={300} />
				<p style={{fontSize: 24, textAlign: "center"}}>Payment Failed</p>
				<p style={{fontSize: 14, textAlign: "center"}}>{errorMessage}</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" autoFocus>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default Subscriptionfailed
