import {Card, makeStyles} from "@material-ui/core"
import React from "react"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import Badge1 from "../../../assets/badge.png"
import Badge2 from "../../../assets/badge2.png"
import Badge3 from "../../../assets/badge3.png"
import Badge4 from "../../../assets/badge4.png"
import {ChevronRight} from "react-feather"
const useStyles = makeStyles(() => ({
	container: {
		height: "100vh",
		width: "100%",
		backgroundColor: "#f1f2f6",
	},
	badges: {
		paddingTop: 80,
		marginLeft: 20,
		display: "flex",
		alignItems: "center",
	},
	badgeContainer: {
		height: 100,
		width: 100,
		backgroundColor: "white",
		clipPath: "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
	},
	badgeBg: {
		backgroundColor: "#eb3b5a",
		height: 80,
		width: 80,
		clipPath: "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	badgeImg: {
		height: 50,
		width: 50,
	},
	badgeTitle: {
		fontsize: 18,
		fontWeight: "bold",
		textTransform: "uppercase",
	},
	history: {
		color: "#eb3b5a",
		fontWeight: "bold",
		fontSize: 16,
	},
	allBadges: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 30,
	},
	card: {
		height: 65,
		width: 65,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	badgeContainerSmall: {
		height: 50,
		width: 50,
		backgroundColor: "white",
		clipPath: "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	badgeBgSmall: {
		backgroundColor: "#eb3b5a",
		height: 35,
		width: 35,
		clipPath: "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	badgeImgSmall: {
		height: 25,
		width: 25,
	},
	cardContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	badgeSubTitle: {
		fontWeight: 400,
		marginTop: 10,
	},
	badgeSub: {
		fontSize: 14,
		color: "#a5b1c2",
	},
}))
const RewardsInfo = () => {
	const classes = useStyles()
	return (
		<div>
			<MenuAppBar />

			<div className={classes.container}>
				<div className={classes.badges}>
					<div className={classes.badgeInfo}>
						<div className={classes.badgeContainer}>
							<div className={classes.badgeBg}>
								<img src={Badge1} alt="" className={classes.badgeImg} />
							</div>
						</div>
					</div>
					<div style={{marginLeft: 20}}>
						<p className={classes.badgeTitle}>GOLD User</p>
						<div style={{display: "flex"}}>
							<p className={classes.history}>POINT HISTORY</p>
							<ChevronRight style={{height: 23, width: 23, color: "#eb3b5a"}} />
						</div>
					</div>
				</div>
				<div className={classes.allBadges}>
					<div className={classes.cardContainer}>
						<Card className={classes.card}>
							<div className={classes.badgeInfoSmall}>
								<div className={classes.badgeContainerSmall}>
									<div className={classes.badgeBgSmall}>
										<img src={Badge1} alt="" className={classes.badgeImgSmall} />
									</div>
								</div>
							</div>
						</Card>
						<p className={classes.badgeSubTitle}>Broze</p>
						<p className={classes.badgeSub}>0 PTS</p>
					</div>
					<div className={classes.cardContainer}>
						<Card className={classes.card}>
							<div className={classes.badgeInfoSmall}>
								<div className={classes.badgeContainerSmall}>
									<div className={classes.badgeBgSmall} style={{backgroundColor: "#4b7bec"}}>
										<img src={Badge2} alt="" className={classes.badgeImgSmall} />
									</div>
								</div>
							</div>
						</Card>
						<p className={classes.badgeSubTitle}>Silver </p>
						<p className={classes.badgeSub}>0 PTS</p>
					</div>
					<div className={classes.cardContainer}>
						<Card className={classes.card}>
							<div className={classes.badgeInfoSmall}>
								<div className={classes.badgeContainerSmall}>
									<div className={classes.badgeBgSmall} style={{backgroundColor: "#20bf6b"}}>
										<img src={Badge3} alt="" className={classes.badgeImgSmall} />
									</div>
								</div>
							</div>
						</Card>
						<p className={classes.badgeSubTitle}>Gold</p>
						<p className={classes.badgeSub}>0 PTS</p>
					</div>
					<div className={classes.cardContainer}>
						<Card className={classes.card}>
							<div className={classes.badgeInfoSmall}>
								<div className={classes.badgeContainerSmall}>
									<div className={classes.badgeBgSmall} style={{backgroundColor: "#a5b1c2"}}>
										<img src={Badge4} alt="" className={classes.badgeImgSmall} />
									</div>
								</div>
							</div>
						</Card>
						<p className={classes.badgeSubTitle}>Platinum</p>
						<p className={classes.badgeSub}>0 PTS</p>
					</div>
				</div>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default RewardsInfo
