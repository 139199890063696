import api from "../helpers/api"
export const GetSingleTeacher = (id) => {
	return api.get(`/admin/getSingleTeacher/${id}`)
}
export const GetTeacherMeetings = (id) => {
	return api.get(`/teacher/getTeacherMeetings/${id}`)
}
export const addAttendanceAfterPayment = (id) => {
	return api.get(`/payment/razorpay/success/${id}`)
}
export const getJoinLink = (scheduleId, teacherId, date) => {
	return api.get(`/teacher/join/${scheduleId}/${teacherId}?date=${date}`)
}
export const getAllCancelledStudents = (_id, date) => api.get(`/cancelclass/${_id}`)
export const updateLastTimeJoined = (email, scheduleId) =>
	api.post(`/customer/join/${scheduleId}/${email}`)
export const applyTeacherLeave = (data) => api.post("/teacher-leaves", data)
export const getTeacherLeaves = (id) => api.get(`/teacher-leaves/${id}`)
export const deleteATeacherLeave = (id) => api.delete(`/teacher-leaves/${id}`)
export const editLeaveById = (id, data) => api.put(`/teacher-leaves/${id}`, data)
export const closeNotification = (data) => api.post("/messages/acknowledge", data)
export const addAvailableTimeSlot = (id, slot) => api.post(`/teacher/add/available/${id}`, {slot})
export const deleteAvailableTimeSlot = (id, slot) => api.post(`/teacher/delete/slot/${id}`, {slot})
export const sendOtpToPhone = (userId) => api.post(`/forgot-password`, {userId})
export const validateEnteredOtpAndResetPassword = (userId, otp) =>
	api.post(`/user/password-reset`, {userId, otp})

export const getPlansByCustomerId = (id) => api.get(`/plans?customerId=${id}`)
export const getPlanDetails = (id) => api.get(`/plans/${id}`)
export const getCustomerOption = (customerId) => api.get(`/options/customer/${customerId}`)
export const getPaymentLink = (plan, customer) => api.post(`/payments/${plan}/${customer}`)
export const updatePaidTill = (plan, customer) =>
	api.get(`/payments/razorpay/success/${plan}/${customer}`)
export const getLookupData = (name) => api.get(`/admin/get/${name}`)
export const getRewardsOfUser = (user) => api.get(`/rewards/user/${user}`)
export const redeemASubject = (data) => api.post(`/rewards`, data)
