import React, {useState} from "react"
import {
	Button,
	CircularProgress,
	Container,
	CssBaseline,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core"
import Logo from "./logo512.png"
import Footer from "./Footer"
import {Link} from "react-router-dom"
import OtpInput from "react-otp-input"
import SnackbarAlert from "../../components/SnackbarAlert"
import {sendOtpToPhone, validateEnteredOtpAndResetPassword} from "../../Services/services"

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

function ResetPassword() {
	const classes = useStyles()
	const [userId, setUserId] = useState("")
	const [loading] = useState(false)
	const [resetPasswordState, setResetPasswordState] = useState(false)
	const [otp, setOtp] = useState("")
	const [open, setOpen] = useState(false)
	const [alert, setAlert] = useState({
		message: "",
		color: "error",
	})

	const sendOtp = () => {
		sendOtpToPhone(userId)
			.then((data) => {
				if (data.headers["content-type"] === "text/html; charset=UTF-8") {
					setOpen(true)
					setAlert({
						message: "Looks like you are offline",
						color: "error",
					})
				} else {
					setResetPasswordState(true)
					setOpen(true)
					setAlert({
						message: data?.data?.message || "OTP Sent successfully!",
						color: "success",
					})
				}
			})
			.catch((err) => {
				console.log(err)
				setOpen(true)
				setAlert({
					message: err.response.data.error || "Can't send OTP",
					color: "error",
				})
			})
	}

	const onSubmit = (e) => {
		e.preventDefault()
		if (resetPasswordState) {
			validateEnteredOtpAndResetPassword(userId, otp)
				.then((data) => {
					setOpen(true)
					setAlert({
						message: "SUCCESS",
						color: "success",
					})
				})
				.catch((err) => {
					console.log(err)
					setOpen(true)
					setAlert({
						message: err.response.data.error || "Can't Validate OTP and Reset the Password",
						color: "error",
					})
				})
		} else {
			sendOtp()
		}
	}

	return (
		<div>
			<SnackbarAlert
				setOpen={setOpen}
				open={open}
				message={
					alert.message === "SUCCESS" ? (
						<div>
							Password reset successful, Use <b>livesloka</b> as first time login password
						</div>
					) : (
						alert.message
					)
				}
				color={alert.color}
			/>
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img src={Logo} style={{height: "150px", width: "150px "}} alt="" />
					<Typography component="h1" variant="h5">
						LiveSloka Password Reset
					</Typography>
					<Typography component="p" variant="caption">
						{resetPasswordState
							? "Enter your OTP Below"
							: "OTP will be sent to your Registered Phone Number"}
					</Typography>
					<form className={classes.form} onSubmit={onSubmit}>
						{resetPasswordState ? (
							<>
								<OtpInput
									value={otp}
									onChange={(otp) => setOtp(otp)}
									isInputNum
									containerStyle={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-evenly",
									}}
									inputStyle={{width: "40px", height: "40px", fontSize: "2rem"}}
								/>
							</>
						) : (
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								onChange={(e) => setUserId(e.target.value)}
								value={userId}
							/>
						)}

						{loading ? (
							<div style={{textAlign: "center", marginTop: "20px"}}>
								<CircularProgress />
							</div>
						) : (
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								{resetPasswordState ? "Reset Password" : "Send OTP"}
							</Button>
						)}
						<Link className="password-reset-link" to="/">
							Login to your Account?
						</Link>
					</form>
				</div>{" "}
			</Container>

			<div
				style={{
					marginTop: 200,
				}}
			>
				<Footer />
			</div>
		</div>
	)
}

export default ResetPassword
