import React, {useState} from "react"
import {Button, Card, Fab} from "@material-ui/core"
import {Minus, Plus} from "react-feather"
import {useEffect} from "react"
import {redeemASubject} from "../../../Services/services"
import {useCallback} from "react"
import {useSnackbar} from "notistack"

const Redeemcard = ({
	_id,
	title,
	description,
	rewards,
	selectedSubject,
	setSelectedSubject,
	availableRewards,
	setRewards,
	_setSelectedSubject,
}) => {
	const [classes, setClasses] = useState(0)
	const snackbar = useSnackbar()

	useEffect(() => {
		setClasses(0)
	}, [selectedSubject])

	const redeemPoints = useCallback(() => {
		if (!classes) {
			snackbar.enqueueSnackbar("Minimum 1 class is required")
			return
		}
		redeemASubject({
			userId: localStorage.getItem("userId"),
			subjectId: selectedSubject.id,
			count: classes,
		})
			.then((data) => {
				_setSelectedSubject(null)
				snackbar.enqueueSnackbar("Redeemed successfully!", {variant: "success"})
				setRewards((prev) => prev - classes * rewards)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [selectedSubject?.id, _setSelectedSubject, classes, setRewards, snackbar, rewards])

	return (
		<Card className="redeem-card">
			<div style={{display: "flex", alignItems: "center"}}>
				<div className="redeem-icon-container">
					<img
						src={`https://livesloka.com/assets/${title}.jpeg`}
						className="redeem-icon-container-image"
						alt=""
					/>
					<div>
						<p className="reward-card-text">{title} Class</p>
						<p style={{fontSize: 12, marginLeft: 10}}>{description}</p>
						<div>
							{selectedSubject && selectedSubject._id === _id ? (
								<div
									style={{
										marginLeft: 20,
										marginTop: 20,
									}}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: 10,
											marginBottom: 10,
										}}
									>
										<Fab
											color="primary"
											size="small"
											disabled={!classes}
											onClick={() => setClasses((prev) => prev - 1)}
										>
											<Minus />
										</Fab>
										<div> {classes} </div>

										<Fab
											color="primary"
											size="small"
											onClick={() => setClasses((prev) => prev + 1)}
											disabled={(classes + 1) * rewards > availableRewards}
										>
											<Plus />
										</Fab>
									</div>
									<div className="points-redeemed-text">
										{" "}
										Points get redeemed: {classes * rewards}{" "}
									</div>
									<Button
										onClick={redeemPoints}
										color="primary"
										size="small"
										variant="contained"
										style={{marginTop: 10}}
									>
										{" "}
										Redeem{" "}
									</Button>
								</div>
							) : (
								<Button
									variant="contained"
									className="redeem-btn"
									onClick={() => setSelectedSubject()}
								>
									<p style={{fontSize: 12}}>{rewards} Points</p>
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		</Card>
	)
}

export default Redeemcard
