import React, {useCallback, useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import {Redirect} from "react-router-dom"
import {updateLastTimeJoined} from "../../Services/services"
import useWindowDimensions from "../useWindowDimensions"
import api from "../../helpers/api"
import BookIcon from "@material-ui/icons/Book"
import io from "socket.io-client"
import {Clock, Video} from "react-feather"
import LeavesModal from "../LeavesModal"
import {Alert} from "@material-ui/lab"
import {useHistory} from "react-router-dom"

const socket = io(process.env.REACT_APP_API_KEY)
const DemoMeetingCard = ({
	link,
	isClassTemperarilyCancelled,
	message,
	isJoinButtonDisabled,
	numberOfClassesBought,
	scheduleId,
	subject,
	desc2,
	setDialogOpen,
	setSelectedSchedule,
	_id,
	cancelledDates,
	scheduleDays,
	setOpen,
	setMessage,
	setColor,
	setRefresh,
	setAllowedDays,
	setEditingId,
	setSelectedDateToCancel,
	isWhereby,
	customerName,
	isSummerCampClass,
	isTeacherOnLeave,
}) => {
	const {width} = useWindowDimensions()
	const [leavesModal, setleavesModal] = useState(false)
	const [desktopView, setDesktopView] = useState(false)
	const [redirectUrl, setRedirectUrl] = useState("")
	const history = useHistory()

	const deskview = useCallback(() => {
		if (width > 800) {
			setDesktopView(true)
		} else {
			setDesktopView(false)
		}
	}, [width])

	useEffect(() => {
		deskview()
	}, [width, deskview])

	const useStyles = makeStyles({
		root: {
			margin: "10px",
			padding: "10px",
			background: "white",
			boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
			backdropFilter: "blur( 8.5px )",
			WebkitBackdropFilter: "blur( 8.5px )",
			borderRadius: "10px",
			border: "1px solid rgba( 255, 255, 255, 0.18 )",
			width: desktopView ? 400 : "100%",
		},
	})

	const classes = useStyles()

	const deleteCancelledClass = (id) => {
		if (window.confirm("Cancel Leave ?")) {
			api
				.delete(`/cancelclass/${id}`)
				.then((data) => {
					setColor("success")
					setMessage(data.data.message)
					setOpen(true)
					setRefresh((prev) => !prev)
				})
				.catch((err) => {
					console.log(err)
					setColor("error")
					setMessage(
						err.response && err.response.data && err.response.data.error
							? err.response.data.error
							: "Something went wrong"
					)
					setOpen(true)
				})
		}
	}

	const updateCancelledClass = (id, cancelledDate) => {
		setEditingId(id)
		setDialogOpen(true)
		setSelectedSchedule({
			subject,
			_id,
		})
		setAllowedDays(scheduleDays)
		setSelectedDateToCancel(new Date(cancelledDate))
	}

	const joinClass = (link) => {
		updateLastTimeJoined(localStorage.getItem("userId"), _id)
			.then((data) => {
				socket.emit("student-joined-class", {
					scheduleId,
					email: localStorage.getItem("userId"),
				})
				if (isWhereby) {
					console.log(link)
					setRedirectUrl(link)
				} else {
					window.location = link
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	if (redirectUrl) {
		return (
			<Redirect
				to={{
					pathname: "/whereby/meeting",
					state: {link},
				}}
			/>
		)
	}

	return (
		<>
			<Card
				style={{
					backgroundColor: isTeacherOnLeave ? "rgba(235, 123, 123, 0.1)" : undefined,
				}}
				className={classes.root}
			>
				{isTeacherOnLeave ? (
					<Alert variant="filled" severity="warning">
						{isTeacherOnLeave}
					</Alert>
				) : (
					""
				)}
				{isClassTemperarilyCancelled ? (
					<Alert variant="filled" severity="warning">
						{message}
					</Alert>
				) : (
					""
				)}

				<div
					style={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
				>
					<div>
						<div
							style={{
								height: "60px",
								width: "60px",
								marginLeft: "10px",
								backgroundColor: "#ff4757",
								borderRadius: "50%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								color: "white",
							}}
						>
							<Clock />
							<p style={{fontSize: 10}}>Demo</p>
						</div>
					</div>
					<div>
						<Typography
							style={{
								marginTop: "10px",
								fontWeight: "bold",
								fontSize: "18px",
								marginLeft: 16,
							}}
						>
							{customerName}
						</Typography>
						<div>
							<CardContent>
								<Typography
									variant="body2"
									color="textSecondary"
									component="p"
									style={{fontWeight: 600}}
								>
									{desc2}
								</Typography>
							</CardContent>
						</div>
					</div>
				</div>
				{!isSummerCampClass ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-evenly",
							alignItems: "flex-end",
							marginBottom: 15,
						}}
					>
						<>
							<Button
								onClick={() => joinClass(link)}
								disabled={isTeacherOnLeave}
								style={{
									backgroundColor: isTeacherOnLeave ? "#aaa" : "#27ae60",
									color: "#fff",
									height: "40px",
									width: 150,
								}}
							>
								<Video style={{height: 20, width: 20, marginRight: 10}} /> Join Class
							</Button>
						</>

						<Button
							onClick={() => history.push(`/particular-material/${scheduleId}`)}
							disabled={
								(!isSummerCampClass && isJoinButtonDisabled) ||
								isTeacherOnLeave ||
								isClassTemperarilyCancelled
							}
							style={{
								backgroundColor: "#f39c12",
								color: "#fff",
								height: "40px",
								width: 150,
							}}
						>
							<BookIcon style={{height: 20, width: 20, marginRight: 10}} /> Materials
						</Button>
					</div>
				) : (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<div style={{textAlign: "center", marginTop: "-15px"}}>
							<p style={{fontSize: "14px", fontWeight: "bold"}}>
								<span style={{color: "#3867d6", fontSize: 16}}>{numberOfClassesBought}</span>{" "}
								Classes Left
							</p>
						</div>
					</div>
				)}
			</Card>

			<LeavesModal
				open={leavesModal}
				setOpen={setleavesModal}
				cancelledDates={cancelledDates}
				updateCancelledClass={updateCancelledClass}
				deleteCancelledClass={deleteCancelledClass}
			/>
		</>
	)
}

export default DemoMeetingCard
