import React, {useEffect, useState} from "react"
import "./scheduler.css"
import {FormControlLabel, FormGroup, Switch} from "@material-ui/core"
import SingleBlock from "./SingleBlock"
import useWindowDimensions from "../../../helpers/useDimensions"
import {MenuAppBar} from "../../../components"
import api from "../../../helpers/api"
import TeacherBottomTabs from "../../../components/BottomTabs/TeacherBottomTabs"
import {addAvailableTimeSlot, deleteAvailableTimeSlot} from "../../../Services/services"
const times = [
	"12:00 AM-12:30 AM",
	"12:30 AM-01:00 AM",
	"01:00 AM-01:30 AM",
	"01:30 AM-02:00 AM",
	"02:00 AM-02:30 AM",
	"02:30 AM-03:00 AM",
	"03:00 AM-03:30 AM",
	"03:30 AM-04:00 AM",
	"04:00 AM-04:30 AM",
	"04:30 AM-05:00 AM",
	"05:00 AM-05:30 AM",
	"05:30 AM-06:00 AM",
	"06:00 AM-06:30 AM",
	"06:30 AM-07:00 AM",
	"07:00 AM-07:30 AM",
	"07:30 AM-08:00 AM",
	"08:00 AM-08:30 AM",
	"08:30 AM-09:00 AM",
	"09:00 AM-09:30 AM",
	"09:30 AM-10:00 AM",
	"10:00 AM-10:30 AM",
	"10:30 AM-11:00 AM",
	"11:00 AM-11:30 AM",
	"11:30 AM-12:00 PM",
	"12:00 PM-12:30 PM",
	"12:30 PM-01:00 PM",
	"01:00 PM-01:30 PM",
	"01:30 PM-02:00 PM",
	"02:00 PM-02:30 PM",
	"02:30 PM-03:00 PM",
	"03:00 PM-03:30 PM",
	"03:30 PM-04:00 PM",
	"04:00 PM-04:30 PM",
	"04:30 PM-05:00 PM",
	"05:00 PM-05:30 PM",
	"05:30 PM-06:00 PM",
	"06:00 PM-06:30 PM",
	"06:30 PM-07:00 PM",
	"07:00 PM-07:30 PM",
	"07:30 PM-08:00 PM",
	"08:00 PM-08:30 PM",
	"08:30 PM-09:00 PM",
	"09:00 PM-09:30 PM",
	"09:30 PM-10:00 PM",
	"10:00 PM-10:30 PM",
	"10:30 PM-11:00 PM",
	"11:00 PM-11:30 PM",
	"11:30 PM-12:00 AM",
]
const hours = [
	"12:00 AM",
	"12:30 AM",
	"01:00 AM",
	"01:30 AM",
	"02:00 AM",
	"02:20 AM",
	"03:00 AM",
	"03:30 AM",
	"04:00 AM",
	"04:30 AM",
	"05:00 AM",
	"05:30 AM",
	"06:00 AM",
	"06:30 AM",
	"07:00 AM",
	"07:30 AM",
	"08:00 AM",
	"08:30 AM",
	"09:00 AM",
	"09:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"01:00 PM",
	"01:30 PM",
	"02:00 PM",
	"02:30 PM",
	"03:00 PM",
	"03:30 PM",
	"04:00 PM",
	"04:30 PM",
	"05:00 PM",
	"05:30 PM",
	"06:00 PM",
	"06:30 PM",
	"07:00 PM",
	"07:30 PM",
	"08:00 PM",
	"08:30 PM",
	"09:00 PM",
	"09:30 PM",
	"10:00 PM",
	"10:30 PM",
	"11:00 PM",
	"11:30 PM",
]
const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

function Scheduler() {
	const [teacherId, setTeacherId] = useState("")
	const {width} = useWindowDimensions()
	const [availableSlotsEditingMode, setAvailableSlotsEditingMode] = useState(false)
	const [availableSlots, setavailableSlots] = useState()
	const [scheduledSlots, setScheduledSlots] = useState()
	const [timeTableData, setTimeTableData] = useState()

	useEffect(() => {
		fetchSchedules()
		setTeacherId(localStorage.getItem("teacherId"))
	}, [])

	const fetchSchedules = async () => {
		const data = await api.get(`teacher/get/teacherDetails/${localStorage.getItem("teacherId")}`)

		const res = await api.get(`teacher/timetable/${localStorage.getItem("teacherId")}`)
		if (data) {
			setavailableSlots(data.data?.result?.availableSlots)
			setScheduledSlots(data.data?.result?.scheduledSlots)
			setTimeTableData(res?.data?.result)
		}
	}

	const addOrRemoveAvailableSlot = (slot) => {
		if (!availableSlots.includes(slot)) {
			addAvailableTimeSlot(teacherId, slot)
				.then((data) => {
					setavailableSlots((prev) => [...prev, slot])
				})
				.catch((err) => console.log(err))
		} else {
			deleteAvailableTimeSlot(teacherId, slot)
				.then((data) => {
					const arr = availableSlots.filter((item) => item !== slot)
					setavailableSlots(arr)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	return (
		<div>
			<MenuAppBar />

			<div style={{marginTop: 70}}>
				<div style={{display: "flex", flexDirection: "row", padding: "20px"}}>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									checked={availableSlotsEditingMode}
									onChange={() => setAvailableSlotsEditingMode((prev) => !prev)}
								/>
							}
							label="Adjust Slots"
						/>
					</FormGroup>
				</div>

				<div style={{paddingLeft: "20px", paddingRight: 20}}>
					<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginBottom: 5,
								width: "50%",
							}}
						>
							<div
								style={{height: 30, width: 30, borderRadius: 5, backgroundColor: "#2ecc71"}}
							></div>{" "}
							<p style={{marginLeft: 5}}>Available</p>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginBottom: 5,
								width: "50%",
							}}
						>
							<div
								style={{height: 30, width: 30, borderRadius: 5, backgroundColor: "#e67e22"}}
							></div>{" "}
							<p style={{marginLeft: 5}}>Schedules</p>
						</div>
					</div>
					<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginBottom: 5,
								width: "50%",
							}}
						>
							<div
								style={{height: 30, width: 30, borderRadius: 5, backgroundColor: "#e74c3c"}}
							></div>{" "}
							<p style={{marginLeft: 5}}>Demo</p>
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginBottom: 5,
								width: "50%",
							}}
						>
							<div
								style={{height: 30, width: 30, borderRadius: 5, backgroundColor: "#95a5a6"}}
							></div>{" "}
							<p style={{marginLeft: 5}}>Temporary Cancelled</p>
						</div>
					</div>
				</div>
				<div
					style={{
						height: "50px",
						display: "flex",
						flexDirection: "row",
						position: "sticky",
						top: "0px",
						backgroundColor: "white",
					}}
				>
					<div
						style={{
							width: width < 700 ? "10%" : "5%",
							backgroundColor: "#f1f2f6",
						}}
					/>
					<div
						style={{
							width: width < 700 ? "90%" : "95%",
							backgroundColor: "#f1f2f6",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
							}}
						>
							{days.map((day, i) => (
								<div key={i} style={{}} className="schedulerHeader">
									{" "}
									{width < 700 ? day.toUpperCase().slice(0, 3) : day.toUpperCase()}
								</div>
							))}
						</div>
					</div>
				</div>
				<div style={{width: "100%", display: "flex", flexDirection: "row"}}>
					<div
						style={{
							width: width < 700 ? "10%" : "5%",
							backgroundColor: "#EAF0F1",
						}}
					>
						{hours.map((hour, i) => (
							<div key={i} className="time-header">
								{hour}
							</div>
						))}
					</div>

					<div style={{width: width < 700 ? "90%" : "95%"}}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
							}}
						>
							{times.map((time, i) => {
								return (
									<React.Fragment key={i}>
										{days.map((day, j) => {
											return (
												<SingleBlock
													key={j}
													selectedSlots={scheduledSlots?.includes(`${day.toUpperCase()}-${time}`)}
													allSchedules={availableSlots?.includes(`${day.toUpperCase()}-${time}`)}
													day={day}
													time={time}
													i={i}
													j={j}
													availableSlotsEditingMode={availableSlotsEditingMode}
													addOrRemoveAvailableSlot={addOrRemoveAvailableSlot}
													timetableData={
														timeTableData && timeTableData[`${day.toUpperCase()}-${time}`]
													}
												/>
											)
										})}
									</React.Fragment>
								)
							})}
						</div>
					</div>
				</div>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
		</div>
	)
}

export default Scheduler
