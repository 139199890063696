import React, {useState, useEffect} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../components"
import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import studyImage from "../../assets/youtube_logo.png"
import api from "../../helpers/api"
import {Link} from "react-router-dom"
import moment from "moment"
const useStyles = makeStyles({
	card: {
		maxWidth: 345,
	},
	media: {
		height: 150,
	},

	root: {
		flexGrow: 1,
	},
})

const MaterialAccess = () => {
	const [materialData, setMaterialData] = useState()
	useEffect(() => {
		getMaterials()
	}, [])
	const getMaterials = async () => {
		const data = await api.get(
			`${process.env.REACT_APP_API_KEY}/uploads/getStudentmaterial/${localStorage.getItem(
				"userId"
			)}`
		)

		const sortedArray = data && data.data.mat

		sortedArray.sort(function compare(a, b) {
			var dateA = new Date(a.createdAt)
			var dateB = new Date(b.createdAt)
			return dateB - dateA
		})
		setMaterialData(sortedArray)
	}
	const classes = useStyles()

	return (
		<div>
			<MenuAppBar />

			<div
				style={{
					margin: "auto",
					width: "80%",
					marginTop: 100,
					marginBottom: 80,
				}}
			>
				<h1 style={{textAlign: "center", marginBottom: 10, fontSize: 18}}>All Materials</h1>
				<Grid container spacing={5}>
					{materialData &&
						materialData.map((data) => {
							if (data.UploadLink && data.typeOfmaterial) {
								return (
									<Grid item xs={12} md={4} key={data._id}>
										<Link
											style={{textDecoration: "none"}}
											to={`/materials/?type=${data.typeOfmaterial}&link=${data.UploadLink}`}
										>
											<Card className={classes.card}>
												<CardActionArea style={{display: "flex", flexDirection: "row"}}>
													<div
														style={{
															width: "55%",
															margin: "0 auto",
															marginTop: 10,
														}}
													>
														<img src={studyImage} alt="" style={{height: 30, width: 30}} />
													</div>
													<CardContent>
														<Typography
															ariant="h5"
															component="h5"
															style={{fontSize: 15, fontWeight: "bold"}}
														>
															{/* {data.materialName} */}
															{moment(data.materialName).format("MMM Do YY")}
														</Typography>
													</CardContent>
												</CardActionArea>
											</Card>
										</Link>
									</Grid>
								)
							}
						})}
				</Grid>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default MaterialAccess
