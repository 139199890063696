import React, {useState, useEffect} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../components"
import TransactionCard from "./TransactionCard"
import api from "../../helpers/api"
import useWindowDimensions from "../../helpers/useDimensions"
import Footer from "../login/Footer"
import "./style.css"
const TransactionsPage = () => {
	const {width} = useWindowDimensions()
	const [allTransactions, setAllTransactions] = useState([])
	useEffect(() => {
		getTransactions()
	}, [])

	const getTransactions = async () => {
		try {
			let subjects = await api.get("/admin/get/Subject")
			subjects = subjects.data.result
			const id = localStorage.getItem("customerId")
			const data = await api.get(`/payment/get/transactions/${id}`)
			console.log(data.data.result)
			setAllTransactions(
				data.data.result.map((transaction) => {
					return {
						time: transaction.createdAt,
						status: transaction.status,
						name: transaction.customerId.firstName,
						currency:
							transaction.type === "PAYPAL" && transaction.status === "CANCELLED"
								? ""
								: transaction.type === "PAYPAL"
								? transaction.paymentData.transactions[0].amount.currency === "USD" ||
								  transaction.paymentData.transactions[0].amount.currency === "CAD"
									? "$"
									: "₹"
								: "₹",
						amount:
							transaction.type === "PAYPAL" && transaction.status === "CANCELLED"
								? "NA"
								: transaction.type === "PAYPAL"
								? transaction.paymentData.transactions[0].amount.total
								: transaction.paymentData.payload.payment.entity.amount / 100,
						subject: subjects.filter(
							(subject) => subject.id === transaction.customerId.subjectId
						)[0].subjectName,
					}
				})
			)
		} catch (error) {
			console.log(error)
		}
	}
	return (
		<>
			<MenuAppBar />
			<h3 style={{textAlign: "center", marginTop: 80, marginBottom: 20}}>Recent Transactions</h3>
			{allTransactions.map((transaction) => (
				<TransactionCard {...transaction} />
			))}
			<div className="mb-100" />
			{width > 800 ? (
				<Footer />
			) : (
				<div
					style={{
						marginTop: "30px",
						position: "fixed",
						left: 0,
						bottom: 0,
						textAlign: "center",
						width: "100%",
					}}
				>
					<StudentBottomTabs />
				</div>
			)}
		</>
	)
}

export default TransactionsPage
