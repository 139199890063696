import React, {useState} from "react"
import {colors, DialogContent, Typography} from "@material-ui/core"
import {DialogContentText, DialogActions, Button} from "@material-ui/core"
import {Dialog} from "@material-ui/core"
import {makeStyles} from "@material-ui/core"
import {updatePaidTill} from "../../../Services/services"
import {getPaymentLink} from "./../../../Services/services"
import {useHistory} from "react-router-dom"
import {useParams} from "react-router-dom"

function ReviewTransaction({
	plan,
	onClose,
	discount,
	customer,
	setSelectedPlan,
	loading,
	setLoading,
}) {
	const classes = useStyles()
	const history = useHistory()
	const params = useParams()

	const onPaynowClick = async () => {
		setLoading(true)
		if (plan) {
			const data = await getPaymentLink(plan._id, params.customerId)
			setSelectedPlan("")
			if (data.status === 200) {
				if (data.data.type === "PAYPAL" && data.data.link) {
					window.location.assign(data.data.link)
				} else if (data.data.type === "RAZORPAY") {
					const options = {
						key: process.env.REACT_APP_RAZORPAY_KEY_ID,
						currency: data.data.currency,
						amount: data.data.amount.toString(),
						order_id: data.data.id,
						name: "Payment for Livesloka Class",
						notes: [params.customerId],
						handler: (response) => {
							if (response.razorpay_order_id) {
								updatePaidTill(plan._id, params.customerId).then((data) => {
									history.push("/payment-success")
								})
							} else {
								history.push("/payment-failed")
							}
						},
						prefill: {},
					}
					const paymentObject = new window.Razorpay(options)
					paymentObject.open()
				}
			}
		} else {
			setLoading(false)
		}
	}

	const monthlyAmount = plan ? plan.amount / plan.intervalCount : 0

	return (
		<Dialog
			open={!!plan}
			onClose={() => {
				onClose()
				setLoading(false)
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent style={{padding: 50}}>
				<DialogContentText id="alert-dialog-description">
					{plan && (
						<>
							<Typography variant="h5" align="center" className={classes.planName}>
								Confirm your payment
							</Typography>
							<div className={classes.list}>
								<div className={classes.spaceBetween}>
									<div className={classes.gray}>Amount</div>
									<div>
										{plan.currency.prefix} {monthlyAmount}
									</div>
								</div>
								<div className={classes.spaceBetween}>
									<div className={classes.green}>Discount</div>
									<div className={classes.green}>
										{" "}
										- {plan.currency.prefix} {discount}
									</div>
								</div>
								<div className={classes.divider} />
								<div className={classes.spaceBetween}>
									<div className={classes.gray}>Total (Per person per month)</div>
									<div>
										{plan.currency.prefix} {monthlyAmount - discount}
									</div>
								</div>
								<div className={classes.divider} />
								<div className={classes.spaceBetween} style={{marginTop: 10}}>
									<div className={classes.gray}>Months</div>
									<div> x {plan.intervalCount}</div>
								</div>
								<div className={classes.spaceBetween}>
									<div className={classes.gray}>No. Students</div>
									<div> x {customer?.numberOfStudents || 1}</div>
								</div>
								<div className={classes.divider} />
								<div className={classes.spaceBetween}>
									<div className={classes.gray}>
										Amount Payable ({plan.currency.prefix} {monthlyAmount - discount} x{" "}
										{plan.intervalCount} x {customer?.numberOfStudents || 1}){" "}
									</div>
									<div className={classes.largeText}>
										{" "}
										{plan.currency.prefix}{" "}
										{(monthlyAmount - discount) *
											plan.intervalCount *
											(customer?.numberOfStudents || 1)}
									</div>
								</div>
							</div>
						</>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						onClose()
						setLoading(false)
					}}
					variant="outlined"
					color="primary"
				>
					Cancel
				</Button>
				<Button
					color="primary"
					disabled={loading}
					onClick={onPaynowClick}
					variant="contained"
					autoFocus
				>
					Pay Now
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ReviewTransaction

const useStyles = makeStyles(() => ({
	divider: {
		height: 2,
		width: "500px",
		backgroundColor: "rgba(0,0,0,0.2)",
		maxWidth: "100%",
		margin: "5px auto",
	},
	largeText: {
		fontSize: "1.6rem",
	},
	green: {
		color: colors.green["400"],
	},
	gray: {
		color: colors.grey["600"],
	},
	list: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		gap: 20,
		width: "100%",
		color: "black",
		marginTop: 20,
	},
	planName: {
		background: "linear-gradient(135deg,#6253E1,#04BEFE)",
		padding: "20px 0",
		borderRadius: "5px",
		color: "#fff",
	},
	spaceBetween: {
		display: "flex",
		width: "100%",
		alignItems: "center",
		justifyContent: "space-between",
	},
}))
