import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import SpeedDial from "@material-ui/lab/SpeedDial"
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon"
import SpeedDialAction from "@material-ui/lab/SpeedDialAction"
import {AttachFileOutlined, Audiotrack, Description, Image, MovieCreation} from "@material-ui/icons"
import {Backdrop} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	speedDial: {
		position: "absolute",
		"&.MuiSpeedDial-directionUp": {
			right: "45px",
			bottom: "6px",
		},
	},
}))

const actions = [
	{icon: <Image />, name: "Image", id: "image"},
	{icon: <MovieCreation />, name: "Video", id: "video"},
	{icon: <Audiotrack />, name: "Audio", id: "audio"},
	{icon: <Description />, name: "Document", id: "document"},
]

export default function AttachFile({onHandelClick}) {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = () => {
		setOpen(true)
	}

	return (
		<SpeedDial
			ariaLabel="SpeedDial example"
			className={classes.speedDial}
			icon={<AttachFileOutlined />}
			onClose={handleClose}
			onOpen={handleOpen}
			open={open}
			direction="up"
		>
			{/* <Backdrop open={open} /> */}

			{actions.map((action) => (
				<SpeedDialAction
					key={action.name}
					icon={action.icon}
					tooltipTitle={action.name}
					onClick={() => {
						onHandelClick(action.id)
					}}
				/>
			))}
		</SpeedDial>
	)
}
