import {makeStyles} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {BookOpen, ChevronRight, Film, Gift, MapPin} from "react-feather"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import {Link} from "react-router-dom"
import UpdateAddressDialog from "../../../components/UpdateAddressDialog"
import api from "../../../helpers/api"
import ProfilePicupdate from "../../../components/ProfilePicupdate"
const useStyles = makeStyles(() => ({
	container: {
		height: "100vh",
		width: "100%",
	},
	background: {
		backgroundColor: "#20bf6b",
		height: "50%",
		boxShadow: "0px 8px 10px -1px rgba(38,222,129,0.75)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	circle: {
		height: 120,
		width: 120,
		borderRadius: "50%",
		marginTop: 20,
	},
	image: {
		height: 120,
		width: 120,
		borderRadius: "50%",
		objectFit: "cover",
		border: "3px solid white",
	},
	text: {
		fontSize: 22,
		fontWeight: "bold",
		marginTop: 10,
		color: "white",
	},
	email: {
		fontSize: 18,
		marginTop: 5,
		color: "white",
	},
	linksContainer: {
		marginTop: 20,
	},
	link: {
		height: 50,
		backgroundColor: "#f1f2f6",
		borderBottom: "1px solid #57606f",
		padding: 10,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		textDecoration: "none",
		color: "black",
	},
}))
const StudentsProfile = () => {
	const [openUpdateAddress, setOpenUpdateAddress] = useState(false)
	const classes = useStyles()
	const [openProfile, setOpenProfile] = useState(false)

	const [profileData, setProfileData] = useState()

	useEffect(() => {
		getUserByEmail()
	}, [])

	const getBackData = (data) => {
		if (data === 200) {
			getUserByEmail()
		}
	}
	const getUserByEmail = async () => {
		try {
			const {data} = await api.get(`/get/customer/${localStorage.getItem("userId")}`)

			if (data) {
				setProfileData(data?.result)
			}
		} catch (error) {
			console.log(error.response)
		}
	}

	return (
		<div>
			<UpdateAddressDialog open={openUpdateAddress} setOpen={setOpenUpdateAddress} />

			<ProfilePicupdate
				open={openProfile}
				setOpen={setOpenProfile}
				data={profileData}
				getBackData={getBackData}
			/>
			<MenuAppBar />

			<div className={classes.container}>
				<div className={classes.background}>
					<div className={classes.circle} onClick={() => setOpenProfile(!openProfile)}>
						<img src={profileData?.profilePic} alt="" className={classes.image} />
					</div>
					<p className={classes.text}>{profileData?.username}</p>
					<p className={classes.email}>{profileData?.email}</p>
				</div>

				<div className={classes.linksContainer}>
					<div className={classes.link} onClick={() => setOpenUpdateAddress(!openUpdateAddress)}>
						<div style={{display: "flex", alignItems: "center"}}>
							<MapPin />
							<p style={{marginLeft: 10}}>Address</p>
						</div>
						<ChevronRight />
					</div>
					{/* <Link to="/rewards" className={classes.link}>
						<div style={{display: "flex", alignItems: "center"}}>
							<Gift />
							<p style={{marginLeft: 10}}>Rewards</p>
						</div>
						<ChevronRight />
					</Link> */}
					<Link to="/videos" className={classes.link}>
						<div style={{display: "flex", alignItems: "center"}}>
							<Film />
							<p style={{marginLeft: 10}}>Videos</p>
						</div>
						<ChevronRight />
					</Link>
					<Link to="/certificates" className={classes.link}>
						<div style={{display: "flex", alignItems: "center"}}>
							<BookOpen />
							<p style={{marginLeft: 10}}>Certificates</p>
						</div>
						<ChevronRight />
					</Link>
				</div>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default StudentsProfile
