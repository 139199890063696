import React, {useMemo} from "react"
import {MenuAppBar} from "./../../../components"
import {useState, useEffect} from "react"
import {makeStyles, capitalize, Card, Grid, Snackbar, Container} from "@material-ui/core"
import {useParams} from "react-router-dom"
import {Alert} from "@material-ui/lab"
import api from "./../../../helpers/api"
import ReviewTransaction from "./ReviewTransaction"
import loadingAnimation from "../../../assets/loading.json"
import {Lottie} from "react-lottie"

const colors = [
	"linear-gradient(135deg,#6253E1,#04BEFE)",
	"linear-gradient(135deg,#FC6076,#E75516)",
	"linear-gradient(135deg,#6253E1,#04BEFE)",
	"linear-gradient(135deg,#FC6076,#E75516)",
	"linear-gradient(135deg,#6253E1,#04BEFE)",
	"linear-gradient(135deg,#FC6076,#E75516)",
	"linear-gradient(135deg,#6253E1,#04BEFE)",
	"linear-gradient(135deg,#FC6076,#E75516)",
]

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: loadingAnimation,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
}

const PlanCard = ({plan, i, discount, setSelectedPlan}) => {
	const classes = useStyles()

	return (
		<Grid item xs={12} sm={6} md={4} lg={3}>
			<Card className={classes.card}>
				<div className={classes.topPart} style={{background: colors[i], padding: 20}}>
					<p style={{textAlign: "center", fontSize: 24, fontWeight: 600, marginBottom: 10}}>
						{plan.name}
					</p>
					<p style={{textAlign: "center"}}>{plan.description}</p>
				</div>
				<div style={{padding: 20, width: "100%"}}>
					<p className={classes.price}>
						{plan.currency.prefix || "$"} {plan.amount / plan.intervalCount - discount}
					</p>
					{discount ? (
						<p style={{textAlign: "center"}}>
							Actual Price:
							<strike style={{fontSize: 20}}>
								{plan.currency.prefix || "$"} {plan.amount / plan.intervalCount}
							</strike>
						</p>
					) : (
						""
					)}
					<p style={{textAlign: "center"}}>
						{plan.intervalCount} {capitalize(plan.interval)}
						{plan.intervalCount > 1 ? "s" : ""}
					</p>
					<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
						<div onClick={() => setSelectedPlan(plan._id)} className={classes.link}>
							Choose
						</div>
					</div>
				</div>
			</Card>
		</Grid>
	)
}

function SelectPlan() {
	const classes = useStyles()
	const [availablePlans, setAvailablePlans] = useState([])
	const params = useParams()
	const [error, setError] = useState("")
	const [selectedPlan, setSelectedPlan] = useState("")
	const [customer, setCustomer] = useState({})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		;(async () => {
			await api
				.get(`/options/customer/${params.customerId}`)
				.then((data) => {
					setCustomer(data.data.result.customer)
					setAvailablePlans(data.data.result.discounts)
				})
				.catch((err) => {
					setError(err.response.data.error)
				})
		})()
	}, [params.customerId])

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}
		setError("")
	}

	const plan = useMemo(
		() => availablePlans.filter((plan) => plan?.plan?._id === selectedPlan)[0],
		[availablePlans, selectedPlan]
	)

	return (
		<>
			<Snackbar open={!!error} autoHideDuration={6000} onClose={() => handleClose()}>
				<Alert onClose={() => handleClose()} severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
			<MenuAppBar />
			<ReviewTransaction
				plan={plan?.plan}
				loading={loading}
				setSelectedPlan={setSelectedPlan}
				setLoading={setLoading}
				discount={plan?.amount}
				customer={customer}
				onClose={() => {
					setSelectedPlan("")
				}}
			/>
			<Container className={classes.container}>
				<Grid container spacing={2}>
					{availablePlans.map(
						(plan, i) =>
							plan.plan && (
								<PlanCard
									setSelectedPlan={setSelectedPlan}
									plan={plan.plan}
									key={plan.plan._id}
									i={i}
									discount={plan.amount}
								/>
							)
					)}
				</Grid>
			</Container>
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	card: {
		display: "flex",
		alignItems: "center",
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
		borderRadius: "20px",
		flexDirection: "column",
		margin: 15,
		paddingBottom: 20,
		transition: "all .2s ease-in-out",
		"&:hover": {
			transform: "scale(1.1)",
		},
	},
	title: {
		fontSize: 30,
		fontWeight: 600,
	},

	price: {
		fontSize: 50,
		fontWeight: 600,
		textAlign: "center",
	},

	topPart: {
		width: "100%",
		minHeight: 200,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		borderBottomRightRadius: 20,
		borderBottomLeftRadius: 20,
	},
	link: {
		width: "60%",
		height: 50,
		marginTop: 20,
		backgroundColor: "#2d3436",
		color: "white",
		borderRadius: 30,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textDecoration: "none",
		cursor: "pointer",
	},
	container: {
		marginTop: 80,
	},
}))

export default SelectPlan
