import React from "react"
import {useParams} from "react-router"
import {MenuAppBar} from "../components"
import useWindowDimensions from "../helpers/useDimensions"
function TestMeeting(props) {
	const {width, height} = useWindowDimensions()

	return (
		<div>
			<MenuAppBar />
			<iframe
				title="Whereby Meeting"
				style={{
					marginTop: "60px",
					width: "100vw",
					height: height - 70,
				}}
				src={`${props.location.state.link}?embed`}
				allow="camera; microphone; fullscreen; speaker; display-capture"
			></iframe>
		</div>
	)
}

export default TestMeeting
