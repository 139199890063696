import React, {useEffect, useState} from "react"
import {withStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import {Rating} from "@material-ui/lab"
import api from "../helpers/api"
import {useSnackbar} from "notistack"
import {Card} from "@material-ui/core"
import {Edit, Trash2} from "react-feather"
const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
})

const DialogTitle = withStyles(styles)((props) => {
	const {children, classes, onClose, ...other} = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent)

export default function ReviewsModal({open, setOpen, allData}) {
	// console.log(allData)
	const {enqueueSnackbar} = useSnackbar()

	const handleClose = () => {
		setOpen(false)
	}

	const [ratingValue, setRatingValue] = useState(0)
	const [ratingDesc, setRatingDesc] = useState("")

	const [reviewData, setReviewData] = useState({})

	useEffect(() => {
		getReviews()
	}, [open])

	const getReviews = async () => {
		try {
			const data = await api.get(`/reviews/${allData.customerId}/${allData._id}`)
			setReviewData(data?.data?.result)
		} catch (error) {}
	}
	const submitReview = async () => {
		const formData = {
			ratingValue,
			ratingDesc,
			teacherId: allData.teacher,
			className: allData.className,
			studentName: allData.customerName,
			customerId: allData.customerId,
			scheduleId: allData._id,
		}

		try {
			const data = await api.post("/reviews", formData)
			// console.log(data)

			if (data.status === 201) {
				enqueueSnackbar(`Added Successfully`, {variant: "success"})

				setRatingDesc("")
				setRatingValue("")
				setOpen(false)
			}
		} catch (error) {}
	}

	const deleteReview = async () => {
		try {
			const data = await api.delete(`/reviews/${reviewData._id}`)

			if (data.status === 200) {
				getReviews()
			}
		} catch (error) {}
	}

	return (
		<div>
			<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					Rating
				</DialogTitle>
				<DialogContent style={{width: 330}} dividers>
					{reviewData === null ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							<p style={{textAlign: "center", fontWeight: 520, marginBottom: 10}}>
								{allData.className}
							</p>
							<Rating
								value={parseInt(ratingValue)}
								onChange={(e) => setRatingValue(e.target.value)}
								defaultValue={0}
								size="large"
							/>
							<textarea
								style={{
									height: 100,
									width: "90%",
									marginTop: 20,
									outline: "none",
									border: "2px solid #dcdde1",
									borderRadius: 10,
									padding: 10,
								}}
								onChange={(e) => setRatingDesc(e.target.value)}
								value={ratingDesc}
							></textarea>
							<Button
								onClick={submitReview}
								variant="contained"
								style={{width: "90%", marginTop: 10, backgroundColor: "#3867d6", color: "white"}}
							>
								Submit
							</Button>
						</div>
					) : (
						<Card
							style={{
								height: "auto",
								width: "100%",
								padding: 10,
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<div>
								<Rating value={parseInt(reviewData.ratingValue)} readOnly size="medium" />
								<p>{reviewData.ratingDesc}</p>
							</div>
							<div style={{display: "flex", flexDirection: "column"}}>
								<Edit style={{marginBottom: 10, cursor: "pointer"}} />

								<Trash2 onClick={deleteReview} style={{cursor: "pointer"}} />
							</div>
						</Card>
					)}
				</DialogContent>
			</Dialog>
		</div>
	)
}
