import React, {useState} from "react"
import {isFuture} from "../../../Services"
import ScheduleInfoModal from "./ScheduleInfoModal"

function SingleBlock({
	day,
	time,
	j,
	addOrRemoveAvailableSlot,
	availableSlotsEditingMode,
	allSchedules,
	selectedSlots,
	timetableData,
}) {
	const [openModal, setOpenModal] = useState(false)

	return (
		<>
			<ScheduleInfoModal open={openModal} setOpen={setOpenModal} data={timetableData} />

			<div
				onClick={() => {
					if (timetableData) {
						setOpenModal(true)
					} else if (availableSlotsEditingMode) {
						addOrRemoveAvailableSlot(`${day.toUpperCase()}-${time}`)
					}
				}}
				key={j}
				className={`day-time-intersection-box `}
				style={{
					fontSize: "14px",
					fontWeight: "bold",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<div
					style={{
						background: timetableData?.demo
							? "#e74c3c"
							: timetableData?.cancelledTill && isFuture(timetableData?.cancelledTill)
							? "#95a5a6"
							: allSchedules
							? "#2ecc71"
							: selectedSlots && timetableData
							? "#e67e22"
							: "white",
					}}
					className="blockName"
				>
					<p style={{fontSize: 8}}>{timetableData?.students[0]?.firstName.split(" ")[0]}</p>
				</div>
			</div>
		</>
	)
}

export default SingleBlock
