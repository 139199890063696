import Grid from "@material-ui/core/Grid"
import React, {useState, useEffect, useCallback} from "react"
import Tilt from "react-parallax-tilt"
import {Link} from "react-router-dom"
import api from "../../helpers/api"
import {makeStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import useWindowDimensions from "../../helpers/useDimensions"
import TextField from "@material-ui/core/TextField"
import {Alert, AlertTitle, Autocomplete} from "@material-ui/lab"
import {Backdrop, CircularProgress, InputAdornment, Tooltip} from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import {Mail} from "react-feather"
import momentTZ from "moment-timezone"

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
		backgroundColor: "#075E89",
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: "#fff",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}))

const RefundAlert = () => (
	<Alert
		style={{
			margin: "10px",
		}}
		severity="success"
	>
		<AlertTitle>Hurry Up! Register your slot</AlertTitle>
		<strong>100% Refund</strong> if you are not satisfied after first class on June 01, 2021.
		<br />
		<br />
		<div>Have any Queries ?</div>
		<div>Contact:</div>
		<div
			style={{
				marginLeft: "20px",
			}}
		>
			<div>
				<Tooltip title="Open WhatsApp">
					<a href={`https://api.whatsapp.com/send?phone=19542571859`}>
						<IconButton size="small">
							<WhatsAppIcon style={{color: "green"}} />
						</IconButton>
					</a>
				</Tooltip>
				<span>+1 954 257 1859</span>
			</div>
			<div>
				<Tooltip title="Open WhatsApp">
					<a href={`https://api.whatsapp.com/send?phone=19542572313`}>
						<IconButton size="small">
							<WhatsAppIcon style={{color: "green"}} />
						</IconButton>
					</a>
				</Tooltip>
				<span>+1 954 257 2313</span>
			</div>
			<div>
				<Tooltip title="Open WhatsApp">
					<a href={`https://api.whatsapp.com/send?phone=918074474524`}>
						<IconButton size="small">
							<WhatsAppIcon style={{color: "green"}} />
						</IconButton>
					</a>
				</Tooltip>
				<span>+91 8074474524</span>
			</div>
			<div>
				<Tooltip title="Open Mail">
					<a href={"mailto:info@livesloka.com"}>
						<IconButton size="small">
							<Mail />
						</IconButton>
					</a>
				</Tooltip>
				<span>info@livesloka.com</span>
			</div>
		</div>
	</Alert>
)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

function arrayMin(arr) {
	var len = arr.length,
		min = Infinity
	while (len--) {
		if (arr[len] < min) {
			min = arr[len]
		}
	}
	return min
}

const minFinder = (data) => {
	let times = []
	if (data.length > 0) {
		data.forEach((el) => {
			times.push(el.split("-")[1].substring(1, 5).replace(":", "."))
		})
	}
	return arrayMin(times)
}

const meetinStartTimeGenerator = (data) => {
	let wtg = ""
	let mintime = minFinder(data["wednesday"])
	mintime = mintime.toString().replace(".", ":")
	data["wednesday"].forEach((el) => {
		if (el.split("-")[1].includes(mintime)) {
			const strtime = el.split("-")
			wtg = `
			<div>
			<span style="font-weight:bold" >Monday-Friday</span>: 10:00 AM (CST)
			</div>
			<div>
			<span style="font-weight:bold" >Monday-Friday</span>: 11:00 AM (EST)
			</div>
			`
		}
	})
	return wtg
}

function OurTopCourses() {
	const classes = useStyles()
	const [coursesList, setCoursesList] = useState([])
	const [popupData, setPopupData] = useState({})
	const [popupTitle, setPopupTitle] = useState("")
	const [timeZones, setTimeZones] = useState([])
	const {width} = useWindowDimensions()
	const [success, setSuccess] = useState(false)
	const [message, setMessage] = useState("")
	const [paymentUrl, setPaymentUrl] = useState("")
	const [alreadyRegistered, setAlreadyRegistered] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [otpsSent, setOtpsSent] = useState(0)
	const [loading, setLoading] = useState(false)
	const [formData, setFormData] = useState({
		timeZoneId: "",
		password: "",
		email: "",
		firstName: "",
		lastName: "",
		whatsAppnumber: "",
	})
	const [otp, setOtp] = useState("")
	const [isOtpScreenActive, setIsOtpScreenActive] = useState(false)
	const [actualOtp, setActualOtp] = useState(undefined)

	const onHashChange = useCallback(() => {
		let hash = window.location.hash.slice(1)
		console.log(hash, coursesList)
		if (hash && coursesList.length) {
			console.log(hash)
			let id = hash.split("-")[0]
			let filteredCourse = coursesList.filter((course) => course._id === id)[0]
			if (hash.endsWith("register")) {
				setPopupData(filteredCourse._id)
				setPopupTitle(filteredCourse.summerCampTitle)
			} else {
				setPopupData(filteredCourse)
				setPopupTitle(filteredCourse.summerCampTitle)
			}
		} else {
			setPopupData("")
			setPopupTitle("")
		}
	}, [coursesList])

	useEffect(() => {
		if (!coursesList.length) {
			getallCourses()
		}
		var Tawk_API = Tawk_API || {},
			Tawk_LoadStart = new Date()
		let s1 = document.createElement("script")
		s1.async = true
		s1.src = "https://embed.tawk.to/6098405e185beb22b30b99ca/1f59bmsp5"
		s1.charset = "UTF-8"
		s1.setAttribute("crossorigin", "*")
		document.body.appendChild(s1)
		window.addEventListener("hashchange", () => onHashChange(coursesList))
		return () => {
			window.removeEventListener("hashchange", () => onHashChange(coursesList))
		}
	}, [coursesList, onHashChange])

	const submitForm = async (formData) => {
		setLoading(true)
		try {
			if (!(otpsSent > 4)) {
				let keyvalues = {
					timeZoneId: "Time Zone",
					password: "Password",
					email: "Email",
					firstName: "First Name",
					lastName: "Last Name",
					whatsAppnumber: "Phone No",
				}
				for (let index = 0; index < Object.keys(formData).length; index++) {
					const key = Object.keys(formData)[index]
					if (!formData[key] || alreadyRegistered) {
						setMessage(keyvalues[key] + " is Required!")
						setSuccess(false)
						setLoading(false)
						return
					}
				}
				api
					.get(`/otp/${formData.whatsAppnumber.split(" ").join("")}`)
					.then((data) => {
						setActualOtp(data.data.result)
						setIsOtpScreenActive(true)
						setMessage("OTP sent Successfully! for your registered Mobile Number")
						setSuccess(true)
						setOtpsSent((prev) => prev + 1)
						setLoading(false)
					})
					.catch((err) => {
						console.log(err)
						setLoading(false)
						setMessage("OTP not sent,give correct phone number with Country Code")
					})
			} else {
				setMessage("OTP Limit Exceeded")
				setSuccess(false)
				setLoading(false)
			}
		} catch (error) {
			console.log(error)
			setMessage("Error Sending OTP! try Again")
			setSuccess(false)
			setLoading(false)
		}
	}

	const submitOTP = async () => {
		setLoading(true)
		try {
			if (actualOtp - 3456 == otp || alreadyRegistered) {
				let details = await api.post("/summercamps/register", {
					...formData,
					timeZoneId: formData.timeZoneId.id,
					scheduleId: popupData,
				})
				if (details.data.url) {
					setPaymentUrl(details.data.url)
					setMessage("")
					setIsOtpScreenActive(false)
					setLoading(false)
				} else if (details.data.status === "ALREADY PAID") {
					setMessage("Already registered and Paid successfully...Please login and Join the class")
					setSuccess(true)
					setIsOtpScreenActive(false)
					setLoading(false)
					setTimeout(() => {
						setMessage("")
						setSuccess(false)
					}, 5000)
				}
			} else {
				setMessage("Invalid OTP")
				setSuccess(false)
				setLoading(false)
			}
		} catch (error) {
			console.log(error)
			setMessage(
				error.response && error.response.data && error.response.data.error
					? error.response.data.error
					: "Something went wrong"
			)
			setSuccess(false)
			setLoading(false)
			setTimeout(() => {
				setMessage("")
				setSuccess(false)
			}, 5000)
		}
	}

	useEffect(() => {
		onHashChange()
	}, [coursesList])

	const getallCourses = async () => {
		try {
			const data = await api.get("/summercamps")
			const timeZonesData = await api.get("/admin/get/timeZone")
			console.log(timeZonesData)
			setTimeZones(timeZonesData.data.result)
			if (data) {
				setCoursesList(data.data.result)
			}
		} catch (error) {
			console.log(error)
		}
	}
	return (
		<div
			className="auto-container"
			style={{
				marginTop: "130px",
			}}
		>
			<h1 style={{textAlign: "center"}}>Embrace an Art Form this Summer</h1>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Dialog
				fullScreen
				open={typeof popupData === "object" ? Object.keys(popupData).length : !!popupData}
				onClose={() => {
					setPopupData("")
					setPopupTitle("")
					setIsOtpScreenActive(false)
					setMessage("")
					setFormData({})
					setSuccess(false)
					setAlreadyRegistered(false)
				}}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => {
								window.location.hash = ""
								setPopupData("")
								setPopupTitle("")
								setIsOtpScreenActive(false)
								setMessage("")
								setFormData({})
								setSuccess(false)
								setAlreadyRegistered(false)
							}}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography variant="body1" className={classes.title}>
							{popupTitle}
						</Typography>
						<Button
							key={Math.random()}
							color="inherit"
							onClick={() => {
								window.location.hash = !!popupData._id ? `${popupData._id}-register` : ""
								setFormData({})
							}}
						>
							{!!popupData._id ? "Register" : "Close"}
						</Button>
					</Toolbar>
				</AppBar>
				{typeof popupData === "object" && Object.keys(popupData).length ? (
					<div
						key={Math.random()}
						className="auto-container light-blue-bg"
						style={{
							overflowY: "scroll",
						}}
					>
						<Grid container>
							<Grid item xs={12} lg={8}>
								<h5 className="heading">{popupData.summerCampTitle}</h5>
								<div className="course-info-container">
									<p className="head-desc">{popupData.summerCampDescription}</p>
									<div
										className="people-registered"
										style={{
											color: "#075E89",
										}}
									>
										{popupData.students.length}/{popupData.summerCampStudentsLimit} registered
										student
										{popupData.students.length === 1 ? "" : "s"}
									</div>
									<button
										className="btn-glaze"
										style={{
											margin: "20px 0",
											paddingLeft: "30px",
											paddingRight: "30px",
										}}
										onClick={() => {
											window.location.hash = `#${popupData._id}-register`
										}}
									>
										Register
									</button>
									{width < 600 ? <RefundAlert /> : ""}
									<div className="sub-heading">Timings </div>
									<div
										style={{
											margin: "20px",
										}}
										dangerouslySetInnerHTML={{
											__html: meetinStartTimeGenerator(popupData.slots),
										}}
									/>
									<div className="sub-heading">Schedule</div>
									<div
										style={{
											margin: "20px",
										}}
										dangerouslySetInnerHTML={{
											__html: popupData.summerCampSchedule,
										}}
									/>
									{width < 1280 ? (
										<div style={{textAlign: "center"}}>
											Fees: <span className="course-amount">${popupData.summerCampAmount}</span>
										</div>
									) : (
										""
									)}
									<div className="sub-heading">Instructors</div>
									<Grid container>
										<Grid item xs={12} sm={6}>
											<div className="instructor-img-wrapper">
												<img
													className="instructor-img"
													src={popupData.teacher.teacherImageLink}
													alt={"Instructor"}
												/>
											</div>
											<div className="instructor-info">
												<div className="name">{popupData.teacher.TeacherName}</div>
												<div className="desc">{popupData.teacher.TeacherDesc}</div>
											</div>
										</Grid>
									</Grid>
								</div>
							</Grid>
							{width > 1280 ? (
								<Grid item xs={12} lg={4}>
									<div className="course-img-container">
										<img src={popupData.summerCampImage} alt={popupTitle} className="course-img" />
									</div>
									<div style={{textAlign: "center"}}>
										Fees: <span className="course-amount">${popupData.summerCampAmount}</span>
									</div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<button
											className="btn-glaze"
											style={{
												margin: "20px 0",
												paddingLeft: "30px",
												paddingRight: "30px",
											}}
											onClick={() => {
												window.location.hash = `#${popupData._id}-register`
											}}
										>
											Register
										</button>
									</div>
									<RefundAlert />
								</Grid>
							) : (
								""
							)}
						</Grid>
					</div>
				) : (
					<div
						style={{
							margin: "auto",
							maxWidth: "300px",
							padding: "20px",
						}}
					>
						<h2
							style={{
								textAlign: "center",
								paddingBottom: "20px",
							}}
						>
							Registration for {popupTitle}
						</h2>
						{message ? (
							<Alert
								style={{
									margin: "20px 0",
								}}
								severity={success ? "success" : "error"}
							>
								{message}
							</Alert>
						) : (
							""
						)}
						{isOtpScreenActive ? (
							<>
								<TextField
									style={{width: "100%", paddingBottom: 10}}
									onChange={(e) => setOtp(e.target.value)}
									value={otp}
									label="Enter OTP"
									variant="outlined"
									type="number"
								/>
								<Button
									variant="contained"
									fullWidth
									onClick={() => submitOTP()}
									style={{
										color: "#fff",
										backgroundColor: "#075e89",
										marginBottom: 20,
									}}
								>
									Submit
								</Button>
								<Button
									style={{
										textDecoration: "underline",
									}}
									onClick={() => submitForm(formData)}
								>
									Resend Otp
								</Button>
							</>
						) : paymentUrl ? (
							<>
								<Alert severity="success">
									<AlertTitle>Registration Successful</AlertTitle>
								</Alert>
								<Alert
									severity="warning"
									style={{
										marginTop: "10px",
									}}
								>
									Please complete your Payment for reserving your slot
								</Alert>
								<Alert
									severity="info"
									style={{
										marginTop: "10px",
									}}
								>
									Incase of any Queries, please contact
									<div>
										<div>
											<Tooltip title="Open WhatsApp">
												<a href={`https://api.whatsapp.com/send?phone=19542571859`}>
													<IconButton>
														<WhatsAppIcon />
													</IconButton>
												</a>
											</Tooltip>
											<span>+1 954 257 1859</span>
										</div>
										<div>
											<Tooltip title="Open WhatsApp">
												<a href={`https://api.whatsapp.com/send?phone=19542572313`}>
													<IconButton>
														<WhatsAppIcon />
													</IconButton>
												</a>
											</Tooltip>
											<span>+1 954 257 2313</span>
										</div>
										<div>
											<Tooltip title="Open WhatsApp">
												<a href={`https://api.whatsapp.com/send?phone=919573125980`}>
													<IconButton>
														<WhatsAppIcon />
													</IconButton>
												</a>
											</Tooltip>
											<span>+1 957 312 5980</span>
										</div>
										<div>
											<Tooltip title="Open Mail">
												<a href={"mailto:info@livesloka.com"}>
													<IconButton>
														<Mail />
													</IconButton>
												</a>
											</Tooltip>
											<span>info@livesloka.com</span>
										</div>
									</div>
								</Alert>
								<a
									href={paymentUrl}
									style={{
										textDecoration: "none",
										color: "white",
									}}
								>
									<button className="btn-glaze" style={{margin: "20px auto", display: "block"}}>
										Pay Now
									</button>
								</a>
							</>
						) : (
							<>
								{alreadyRegistered ? (
									<TextField
										style={{width: "100%", paddingBottom: 10}}
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												email: e.target.value,
											}))
										}
										value={formData.email}
										label="Email"
										variant="outlined"
									/>
								) : (
									<>
										<TextField
											style={{width: "100%", paddingBottom: 10}}
											onChange={(e) =>
												setFormData((prev) => ({
													...prev,
													firstName: e.target.value,
												}))
											}
											value={formData.firstName}
											label="First Name"
											variant="outlined"
										/>
										<TextField
											style={{width: "100%", paddingBottom: 10}}
											onChange={(e) =>
												setFormData((prev) => ({
													...prev,
													lastName: e.target.value,
												}))
											}
											value={formData.lastName}
											label="Last Name"
											variant="outlined"
										/>
										<TextField
											style={{width: "100%", paddingBottom: 10}}
											onChange={(e) =>
												setFormData((prev) => ({
													...prev,
													whatsAppnumber: e.target.value,
												}))
											}
											value={formData.whatsAppnumber}
											label="Phone No with Country Code"
											variant="outlined"
										/>
										<TextField
											style={{width: "100%", paddingBottom: 10}}
											onChange={(e) =>
												setFormData((prev) => ({
													...prev,
													email: e.target.value,
												}))
											}
											value={formData.email}
											label="Email"
											variant="outlined"
										/>
										<TextField
											style={{width: "100%", paddingBottom: 10}}
											onChange={(e) =>
												setFormData((prev) => ({
													...prev,
													password: e.target.value,
												}))
											}
											value={formData.password}
											label="Password"
											type={showPassword ? "text" : "password"}
											variant="outlined"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowPassword((prev) => !prev)}
															edge="end"
														>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
										<Autocomplete
											id="autocomplete"
											style={{
												paddingBottom: 10,
											}}
											options={timeZones}
											getOptionSelected={(option, value) => option.id === value && value.id}
											getOptionLabel={(option) => option.timeZoneName}
											value={formData.timeZoneId}
											onChange={(e, v) => setFormData((prev) => ({...prev, timeZoneId: v}))}
											renderInput={(params) => (
												<TextField {...params} label="Time Zone" variant="outlined" />
											)}
										/>
									</>
								)}

								<Button
									variant="contained"
									fullWidth
									onClick={() => (alreadyRegistered ? submitOTP() : submitForm(formData))}
									style={{color: "#fff", backgroundColor: "#075e89", marginBottom: 20}}
								>
									Submit
								</Button>
								<div
									onClick={() => setAlreadyRegistered((prev) => !prev)}
									style={{
										textDecoration: "underline",
										padding: "5px 0",
										cursor: "pointer",
									}}
								>
									{alreadyRegistered ? (
										"Not Registered Yet?, click Here"
									) : (
										<>
											<div
												style={{
													textAlign: "center",
												}}
											>
												Already Registered?
											</div>
											<div>Click here to complete Payment</div>
										</>
									)}
								</div>
							</>
						)}
					</div>
				)}
			</Dialog>
			<Grid container>
				{coursesList &&
					coursesList.map((course) => {
						return (
							<Grid className="course" item xs={12} sm={6} md={6} lg={4} key={course._id}>
								<Tilt tiltMaxAngleX="5" tiltMaxAngleY="5">
									<div className="card">
										<img
											className="card-img"
											onClick={() => {
												window.location.hash = `#${course._id}`
											}}
											src={!!course.summerCampImage ? course.summerCampImage : undefined}
											alt="Course Img"
										/>

										<div className="card-text">
											<div
												style={{
													cursor: "pointer",
												}}
												onClick={() => {
													window.location.hash = `#${course._id}`
												}}
											>
												<div className="head">
													<h5>{course.summerCampTitle}</h5>
												</div>
												<div className="desc">{course.summerCampDescription}</div>
											</div>
											<div className="bottom">
												<div className="students">
													{course.students.length}/{course.summerCampStudentsLimit} Registered
												</div>
												<button
													className="btn-small"
													onClick={() => {
														window.location.hash = `#${course._id}-register`
													}}
													style={{
														display: "flex",
														alignItems: "center",
													}}
												>
													Register
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														style={{
															marginLeft: "8px",
														}}
														viewBox="0 0 24 24"
														fill="none"
														stroke="currentColor"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
														className="feather feather-chevron-right"
													>
														<polyline points="9 18 15 12 9 6"></polyline>
													</svg>
												</button>
											</div>
										</div>
									</div>
								</Tilt>
							</Grid>
						)
					})}
			</Grid>
		</div>
	)
}

export default OurTopCourses
