import React from "react"
import "./footer.css"
import {Link} from "react-router-dom"
import {Typography} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
	footerLink: {
		textDecoration: "none",
		"&:hover": {
			color: "blue",
		},
	},
	footerContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#ecf0f1",
		width: "100%",
	},
	footerLinks: {
		margin: 10,
		color: "black",
		fontSize: 14,
		textDecoration: "none",
		[theme.breakpoints.down("sm")]: {
			fontSize: 12,
			margin: 5,
			textAlign: "center",
		},
	},
	footerLink2: {
		textDecoration: "none",
		"&:hover": {
			color: "blue",
		},
	},

	newCont: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	copyrightLink: {
		marginBottom: 10,
		fontSize: 14,
	},
}))

const Footer = () => {
	const classes = useStyles()
	return (
		<div className={classes.footerContainer}>
			<div className={classes.newCont}>
				<Link className={classes.footerLink2} to="/about">
					<p className={classes.footerLinks}>About Us</p>
				</Link>
				<Link className={classes.footerLink2} to="/privacy-policy">
					<p className={classes.footerLinks}>Privacy Policy</p>
				</Link>
				<Link className={classes.footerLink2} to="/terms-of-use">
					<p className={classes.footerLinks}>Terms And Conditions</p>
				</Link>
				<Link className={classes.footerLink2} to="/return-policy">
					<p className={classes.footerLinks}>Cancellation and Refund Policy</p>
				</Link>
				<Link className={classes.footerLink2} to="/cookie-policy">
					<p className={classes.footerLinks}>Cookie Policy</p>
				</Link>
			</div>
			<div>
				<Typography className={classes.copyrightLink}>
					{"Copyright © "}
					<Link className={classes.footerLink} to="/">
						LiveSloka
					</Link>{" "}
					{new Date().getFullYear()}
					{"."}
				</Typography>
			</div>
		</div>
	)
}

export default Footer
