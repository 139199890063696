import React from "react"
import Logo from "./logo512.png"
import "./footer.css"
import getWindowDimensions from "../../helpers/useDimensions"
import Footer from "./Footer"
const Aboutus = () => {
	const {width, height} = getWindowDimensions()

	return (
		<div className="aboutImageBg">
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "row",
					height: "100%",
				}}
			>
				{width < 800 ? (
					<div
						style={{
							width: "100%",
							height: "100%",
							background: "white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<img
							src={Logo}
							style={{
								height: "150px",
								width: "150px",
								marginTop: height < 730 ? 300 : height < 800 ? 200 : "",
							}}
							alt=""
						/>

						<div style={{width: "100%"}}>
							<h1
								style={{
									textAlign: "center",
									marginBottom: 20,
									color: "#2c3e50",
								}}
							>
								About Live Sloka
							</h1>
							<p style={{margin: 20, fontWeight: 300}}>
								Live Sloka is an online learning platform to enable everyone to learn Indian
								classical art forms. There is a certain grace, rhythm, and innate beauty in Indian
								Cultural heritage. Live Sloka is an initiative to bring the experience of learning
								these beautiful art forms for kids and adults. ​
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								Our journey started with enabling kids and adults to learn sacred slokas with
								perfect pronunciation and intonation that are chanted in homes and temples across
								India. Learning these sacred chants enables children to enhance their memory and
								pronunciation. We went on to introduce Carnatic Music, which was as beautiful an
								experience as learning slokas was. To get in tune with the Indian Classical Music
								forms is an immersive experience by itself.
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								Further, we have launched Indian Classical dance forms. When head, heart, and hands
								come together in unison to express the divine, art finds its completeness. The more
								we get in touch with the rich cultural heritage of India, the more we explore the
								full potential within us.
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								We hope that you would join us on this beautiful journey to experience the joy of
								learning the sacred Sanskrit Slokas, Indian Classical Music as well as Indian
								Classical Dance.
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								We bring home this opportunity to learn slokas, music, and dance online from
								dedicated and experienced teachers from prestigious institutions of India.
							</p>
						</div>
					</div>
				) : (
					<div
						style={{
							width: "80%",
							height: "90%",
							background: "white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<img src={Logo} style={{height: "150px", width: "150px "}} alt="" />

						<div style={{width: "70%"}}>
							<h1
								style={{
									textAlign: "center",
									marginBottom: 20,
									color: "#2c3e50",
								}}
							>
								About Live Sloka
							</h1>
							<p style={{margin: 20, fontWeight: 300}}>
								Live Sloka is an online learning platform to enable everyone to learn Indian
								classical art forms. There is a certain grace, rhythm, and innate beauty in Indian
								Cultural heritage. Live Sloka is an initiative to bring the experience of learning
								these beautiful art forms for kids and adults. ​
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								Our journey started with enabling kids and adults to learn sacred slokas with
								perfect pronunciation and intonation that are chanted in homes and temples across
								India. Learning these sacred chants enables children to enhance their memory and
								pronunciation. We went on to introduce Carnatic Music, which was as beautiful an
								experience as learning slokas was. To get in tune with the Indian Classical Music
								forms is an immersive experience by itself.
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								Further, we have launched Indian Classical dance forms. When head, heart, and hands
								come together in unison to express the divine, art finds its completeness. The more
								we get in touch with the rich cultural heritage of India, the more we explore the
								full potential within us.
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								We hope that you would join us on this beautiful journey to experience the joy of
								learning the sacred Sanskrit Slokas, Indian Classical Music as well as Indian
								Classical Dance.
							</p>

							<p style={{margin: 20, fontWeight: 300}}>
								We bring home this opportunity to learn slokas, music, and dance online from
								dedicated and experienced teachers from prestigious institutions of India.
							</p>
						</div>
					</div>
				)}
			</div>

			{width > 800 ? (
				<div style={{position: "absolute", bottom: 0, width: "100%"}}>
					<Footer />
				</div>
			) : (
				<div style={{marginTop: height > 650 ? 300 : ""}}>
					<Footer />
				</div>
			)}
		</div>
	)
}

export default Aboutus
