import {List, Button, Avatar} from "@material-ui/core"
import React from "react"
import {Link} from "react-router-dom"
import {useLocation} from "react-router-dom"
import {Clock, DollarSign, User, LogOut} from "react-feather"
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles((theme) => ({
	nonActiveLink: {
		display: "flex",
		alignItems: "center",
	},
	navIcon: {
		height: 20,
		width: 20,
		marginRight: 10,
		color: "#bdc3c7",
	},
}))

const TeacherDrawer = ({drawerLinkHide, teachersData}) => {
	const classes = useStyles()
	const location = useLocation()
	return (
		<div style={{marginLeft: -30}}>
			{console.log(teachersData.teacherImageLink)}
			{teachersData && (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						marginTop: 30,
					}}
				>
					{teachersData.teacherImageLink === "" ? (
						<Avatar
							style={{
								height: 150,
								width: 150,
								objectFit: "cover",
								borderRadius: 150 / 2,
							}}
						>
							<p style={{fontSize: 40}}>{teachersData.TeacherName[0]}</p>
						</Avatar>
					) : (
						<img
							src={teachersData.teacherImageLink}
							alt=""
							style={{height: 150, width: 150, objectFit: "cover", borderRadius: 150 / 2}}
						/>
					)}

					<p style={{marginTop: 20, fontWeight: "bold", fontSize: 15}}>
						{teachersData.TeacherName}
					</p>
					<p style={{marginTop: 5, fontSize: 13, opacity: 0.7}}>{teachersData.teacherMail}</p>
					<hr style={{width: "80%", marginTop: 10, marginBottom: 10, opacity: 0.6}} />
				</div>
			)}

			<div style={{marginLeft: 30}}>
				<List className={classes.nonActiveLink}>
					<Clock className={classes.navIcon} />
					<Link to="/timetable" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>Time Table</p>
					</Link>
				</List>

				<List className={classes.nonActiveLink}>
					<DollarSign className={classes.navIcon} />
					<Link to="/payroll" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>Payroll</p>
					</Link>
				</List>
				<List className={classes.nonActiveLink}>
					<User className={classes.navIcon} />
					<Link to="/profile" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>Profile</p>
					</Link>
				</List>

				<hr style={{width: "90%", marginTop: 10, marginBottom: 10, opacity: 0.6}} />

				<List>
					<Link to="/about" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>About us</p>
					</Link>
				</List>
				<List>
					<Link to="/privacy-policy" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>Privacy Policy</p>
					</Link>
				</List>
				<List>
					<Link to="/terms-of-use" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>Terms Of Use</p>
					</Link>
				</List>
				{drawerLinkHide === "1" ? (
					<List>
						<Link to="/return-policy" style={{textDecoration: "none", color: "black"}}>
							<p style={{fontSize: 18}}>Return Policy</p>
						</Link>
					</List>
				) : (
					""
				)}

				<List>
					<Link to="/cookie-policy" style={{textDecoration: "none", color: "black"}}>
						<p style={{fontSize: 18}}>Cookie Policy</p>
					</Link>
				</List>
			</div>
		</div>
	)
}

export default TeacherDrawer
