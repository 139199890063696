import React from "react"
import animationData from "../../../assets/success.json"
import Lottie from "react-lottie"
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core"
import {Link, useHistory} from "react-router-dom"
const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
}

const Subscriptionsuccess = ({successOpen, setsuccessOpen}) => {
	const history = useHistory()
	const handleClose = () => {
		setsuccessOpen(false)
	}
	return (
		<div>
			<Dialog open={successOpen}>
				<DialogContent>
					<Lottie options={defaultOptions} height={300} width={300} />
					<p style={{fontSize: 24, textAlign: "center"}}>Payment Successful</p>
				</DialogContent>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						marginBottom: 10,
					}}
				>
					<Link to="/" style={{textDecoration: "none"}}>
						<Button
							style={{height: 40, width: 100, backgroundColor: "#3867d6"}}
							onClick={handleClose}
							color="primary"
							autoFocus
						>
							<p style={{color: "white", fontWeight: "bold"}}>Login</p>
						</Button>
					</Link>
				</div>
			</Dialog>
		</div>
	)
}

export default Subscriptionsuccess
