import React, {useCallback, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {makeStyles} from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import MeetingTable from "./MeetingTable"
import {GetTeacherMeetings} from "../../context/TeachersMeeting/TeachersMeetingState"
import useWindowDimensions from "../../helpers/useDimensions"

function TabPanel(props) {
	const {children, value, index, ...other} = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		marginTop: "70px",
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
}))

export default function TeacherMeetingDaysTabs({value, setValue, setLeaveData}) {
	const classes = useStyles()
	const [userId] = useState(localStorage.getItem("teacherId"))
	const {width} = useWindowDimensions()
	const [teacherMeetings, setTeacherMeetings] = useState([])
	const allDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
	const getTeacherMeetings = useCallback(async () => {
		const {
			data: {result},
		} = await GetTeacherMeetings(userId)
		setTeacherMeetings(result)
	}, [userId])

	useEffect(() => {
		getTeacherMeetings()
	}, [getTeacherMeetings])

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className={classes.root}>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				scrollButtons="auto"
				variant="scrollable"
				aria-label="scrollable auto tabs example"
			>
				{allDays.map((day, i) => (
					<Tab label={width < 900 ? day.slice(0, 3) : day} key={i} />
				))}
			</Tabs>
			{allDays.map((day, i) => (
				<TabPanel value={value} key={i} index={i}>
					<MeetingTable
						value={value}
						day={day}
						meetings={teacherMeetings.filter((meeting) => meeting.slots[day].length)}
						setLeaveData={setLeaveData}
					/>
				</TabPanel>
			))}
		</div>
	)
}
