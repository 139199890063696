import React, {useContext} from "react"
import {makeStyles} from "@material-ui/core/styles"
import {Container, IconButton} from "@material-ui/core"
import authContext from "../../context/auth/authContext"
import {LogIn, LogOut} from "react-feather"
const useStyles = makeStyles((theme) => ({
	root: {
		height: 90,
		width: "100%",
		position: "fixed",
		top: "0",
		zIndex: "1000",
		backgroundColor: "rgb(52,73,94,0.8)",
	},

	topFont: {
		fontSize: 14,
		[theme.breakpoints.down("xs")]: {
			marginBottom: 5,
		},
	},
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingTop: 10,
		position: "relative",
	},
	textContainer: {
		display: "flex",
		fontSize: "2.2rem",
		flexDirection: "row",
		textAlign: "center",
		color: "#fff",
		fontWeight: 500,
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			textAlign: "center",
		},
	},
	loginBtn: {
		backgroundColor: "#00ab15",
		padding: "6px 22px",
		color: "white",
		borderRadius: 50,
		width: 120,
		"&:hover": {
			backgroundColor: "#00ab15",
		},
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
}))

const TopHeader = () => {
	const classes = useStyles()
	const AuthContext = useContext(authContext)

	const {isAuthenticated} = AuthContext

	return (
		<div className={classes.root}>
			<Container maxWidth="lg" className={classes.container}>
				<div className={classes.textContainer}>
					<div style={{alignItems: "center"}}>
						<p
							style={{
								textShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
							}}
						>
							Live Sloka
						</p>
						<p style={{fontSize: 14}}>June 2021 Online Summer Camp</p>
					</div>
				</div>

				<div style={{position: "absolute", right: 0}}>
					{!isAuthenticated ? (
						<a
							href="/login"
							style={{
								textDecoration: "none",
								color: "#fff",
							}}
						>
							<IconButton>
								<LogIn style={{color: "white"}} />
							</IconButton>
						</a>
					) : (
						<a href="/user-dashboard">
							<IconButton>
								<LogOut style={{color: "white"}} />
							</IconButton>
						</a>
					)}
				</div>
			</Container>
		</div>
	)
}

export default TopHeader
