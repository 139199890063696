import DateFnsUtils from "@date-io/date-fns"
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	TextField,
} from "@material-ui/core"
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import React, {useState} from "react"
import {MenuAppBar} from "../../components"
import TeacherBottomTabs from "../../components/BottomTabs/TeacherBottomTabs"
import useWindowDimensions from "../../helpers/useDimensions"
import {applyTeacherLeave} from "../../Services/services"
import TeacherMeetingDaysTabs from "./TeacherMeetingDaysTabs"
import {useSnackbar} from "notistack"
import {useConfirm} from "material-ui-confirm"
import moment from "moment"

function AccessMeetings() {
	const [value, setValue] = useState(new Date().getDay())
	const {width} = useWindowDimensions()
	const {enqueueSnackbar} = useSnackbar()
	const confirm = useConfirm()
	const [leaveData, setLeaveData] = useState({
		scheduleId: "",
		teacherId: "",
		date: new Date(),
		entireDay: false,
		isHidden: true,
		editingMode: false,
		reason: "",
	})

	const applyALeave = async () => {
		try {
			let {scheduleId, teacherId, entireDay, date, reason} = leaveData
			if (!reason) {
				return enqueueSnackbar("Reason is required", {
					variant: "error",
				})
			}
			if (moment(date).unix() < moment().endOf("day").unix()) {
				await confirm({
					description:
						"Parents get upset if you apply leave today, do you still wanna apply leave today?",
					title: "Applying leave today",
					cancellationText: "Cancel",
					confirmationText: "Agree",
				})
			}

			applyTeacherLeave({
				scheduleId: entireDay ? "" : scheduleId,
				teacherId,
				date,
				entireDay,
				reason,
			})
				.then((data) => {
					enqueueSnackbar(data.data.message, {
						variant: "success",
					})

					setLeaveData({
						scheduleId: "",
						teacherId: "",
						date: new Date(),
						entireDay: false,
						isHidden: true,
						editingMode: false,
					})
				})
				.catch((err) => {
					let message =
						err.response && err.response.data && err.response.data.error
							? err.response.data.error
							: "Something went wrong!"
					enqueueSnackbar(message, {
						variant: "error",
					})

					setLeaveData({
						scheduleId: "",
						teacherId: "",
						date: new Date(),
						entireDay: false,
						isHidden: true,
						editingMode: false,
					})
				})
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<Dialog
				open={!leaveData.isHidden}
				onClose={() =>
					setLeaveData((prev) => ({
						scheduleId: "",
						teacherId: "",
						date: new Date(),
						entireDay: false,
						isHidden: true,
						editingMode: false,
					}))
				}
				fullScreen={width < 400}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					{!leaveData.editingMode ? "Apply Leave" : "Edit Leave"}{" "}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{leaveData.editingMode ? `Select new Date to update` : "Select a date to apply Leave"}
					</DialogContentText>
					<div>
						<TextField
							id="reason"
							label="Leave Reason"
							variant="outlined"
							multiline
							rows={3}
							fullWidth
							value={leaveData.reason}
							onChange={(e) => setLeaveData((prev) => ({...prev, reason: e.target.value}))}
						/>
					</div>
					<FormControlLabel
						control={
							<Checkbox
								checked={leaveData.entireDay}
								onChange={() => setLeaveData((prev) => ({...prev, entireDay: !prev.entireDay}))}
								name="checkedA"
							/>
						}
						label="Apply Leave for Entire Day"
					/>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<DatePicker
							margin="normal"
							fullWidth
							disablePast
							id="date-picker-dialog"
							label="Select Leave Date"
							inputVariant="outlined"
							variant="static"
							shouldDisableDate={(date) => !(new Date(date).getDay() === value)}
							value={leaveData.date}
							onChange={(date) => {
								setLeaveData((prev) => ({...prev, date: new Date(date)}))
							}}
						/>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button
						variant={"contained"}
						color="secondary"
						onClick={() =>
							setLeaveData((prev) => ({
								scheduleId: "",
								teacherId: "",
								date: new Date(),
								entireDay: false,
								isHidden: true,
								editingMode: false,
								reason: "",
							}))
						}
					>
						cancel
					</Button>
					<Button variant={"contained"} color={"primary"} onClick={applyALeave}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
			<MenuAppBar />
			<div style={{marginBottom: "50px"}}>
				<TeacherMeetingDaysTabs value={value} setValue={setValue} setLeaveData={setLeaveData} />
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
		</>
	)
}

export default AccessMeetings
