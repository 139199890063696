import React, {useEffect, useState} from "react"
import {io} from "socket.io-client"
import axios from "axios"
import BotButton from "./BotButton"
import AllChats from "./AllChats"
import {SEEN} from "./helpers"

let socket

const ChatHome = () => {
	const [bot, setbot] = useState(false)
	const role = +localStorage.getItem("role")
	const userId = localStorage.getItem("userId")

	// const [shake, ShouldShake] = useState(false)
	const [newMessage, setNewMessage] = useState(false)
	const GROUPS = []

	const showBot = (isActive) => {
		setbot(isActive)
		if (newMessage) {
			setNewMessage(false)
		}
	}

	useEffect(() => {
		socket = io.connect(`${process.env.REACT_APP_API_KEY}/`)

		socket.on("message-to-group-from-bot", ({groupID, userID, message, username}) => {
			console.log(userID)
			if (userId !== userID) {
				if (GROUPS.includes(groupID)) {
					setNewMessage(true)
					const index = SEEN.indexOf(groupID)
					if (index > -1) {
						SEEN.splice(index, 1)
					}
				}
			}
		})

		return () => {
			socket.removeAllListeners()
		}
	}, [])
	useEffect(() => {
		if (role === 1) {
			axios
				.get(`${process.env.REACT_APP_API_KEY}/customerGroups/${userId}`)
				.then(({data}) => {
					setGroups(data)
				})
				.catch((err) => {
					console.log(err)
				})
		} else if (role === 2)
			axios
				.get(`${process.env.REACT_APP_API_KEY}/teacherGroups/${userId}`)
				.then(({data}) => {
					setGroups(data)
				})
				.catch((err) => {
					console.log(err)
				})
	}, [])

	const setGroups = (groups) => {
		const allGroups = groups.map((el) => el.groupID)
		GROUPS.push(...allGroups)
		console.log(GROUPS)
	}
	return (
		<>
			{bot ? (
				<AllChats showBot={showBot}></AllChats>
			) : (
				<BotButton showBot={showBot} newMessage={newMessage} />
			)}
		</>
	)
}

export default ChatHome

// const setShake = (theshake) => {
// 	ShouldShake(theshake)
// }
// useEffect(() => {
// 	socket = io.connect(`${process.env.REACT_APP_API_KEY}/`)
// 	axios.get(`${process.env.REACT_APP_API_KEY}/user/${userID}`).then((data) => {
// 		const id = data?.data?.roomID
// 		if (id) {
// 			socket.emit("JOIN_ROOM", {roomID: id, isAdmin: false}, (error) => {
// 				if (error) alert(error)
// 			})
// 		}
// 	})
// }, [])
// useEffect(() => {
// 	socket.on("messageToRoomFromAdmin", ({role, message}) => {
// 		if (!bot) {
// 			console.log(message, bot)
// 			setShake(true)
// 		}
// 	})
// 	return removeListners
// }, [bot])

// const removeListners = () => {
// 	console.log("removing from App")
// 	socket.removeAllListeners()
// }
