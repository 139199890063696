import React from "react"
import OurAchievements from "./OurAchievements"
import OurTopCourses from "./OurTopCourses"
import TopHeader from "./TopHeader"
import "./style.css"
import Footer from "../login/Footer"

const BootcampIndex = () => {
	return (
		<div>
			<TopHeader />

			<OurTopCourses />
			<OurAchievements />
			<div
				style={{
					marginTop: 200,
				}}
			>
				<Footer />
			</div>
		</div>
	)
}

export default BootcampIndex
