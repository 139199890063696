import React, {useState, useEffect} from "react"
import {fade, makeStyles} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import {Tooltip} from "@material-ui/core"
import {Redirect} from "react-router-dom"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import MenuIcon from "@material-ui/icons/Menu"
import List from "@material-ui/core/List"
import {Link} from "react-router-dom"
import clsx from "clsx"
import {
	Aperture,
	DollarSign,
	Home,
	Pocket,
	Users,
	Clipboard,
	User,
	Info,
	Award,
} from "react-feather"
import useWindowDimensions from "../helpers/useDimensions"
import Notifications from "./Notifications"
import ProfilePicupdate from "./ProfilePicupdate"
import api from "../helpers/api"
import TeacherDrawer from "./TeacherDrawer"
import UpdateAddressDialog from "./UpdateAddressDialog"
import {BookOpen, ChevronRight, Film, Gift, MapPin} from "react-feather"
import Logo from "../assets/livesloka_logo.png"
const useStyles = makeStyles((theme) => ({
	fixed: {
		flexGrow: 1,
		position: "fixed",
		top: "0",
		left: 0,
		right: 0,
		zIndex: "1000",
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},

	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			width: "auto",
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: "20ch",
		},
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	link: {
		height: 50,
		borderBottom: "1px solid #57606f",
		padding: 10,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		textDecoration: "none",
		color: "black",
		backgroundColor: "#f1f2f6",
	},
	profileBackground: {
		height: 210,
		backgroundColor: "#20bf6b",
		marginTop: -20,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		color: "white",
	},
}))

export default function MenuAppBar({userName}) {
	const classes = useStyles()
	const {width} = useWindowDimensions()

	const [drawerLinkHide] = useState(localStorage.getItem("role"))

	const [redirect, setRedirect] = useState(false)
	const [state, setState] = useState({
		left: false,
	})

	const toggleDrawer = (anchor, open) => (event) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return
		}

		setState({...state, [anchor]: open})
	}
	const [openProfile, setOpenProfile] = useState(false)
	const logout = () => {
		localStorage.clear()
	}

	const [profileData, setProfileData] = useState()
	const [teachersData, setTeachersData] = useState()
	const [openUpdateAddress, setOpenUpdateAddress] = useState(false)

	useEffect(() => {
		getUserByEmail()

		if (localStorage.getItem("role") == 2) {
			getTeacherDetails()
		}
	}, [])

	const getBackData = (data) => {
		if (data === 200) {
			getUserByEmail()
		}
	}
	const getUserByEmail = async () => {
		try {
			const {data} = await api.get(`/get/customer/${localStorage.getItem("userId")}`)

			if (data) {
				setProfileData(data?.result)
			}
		} catch (error) {
			console.log(error.response)
		}
	}

	const getTeacherDetails = async () => {
		const {data} = await api.get(`teacher/get/teacherDetails/${localStorage.getItem("teacherId")}`)

		setTeachersData(data?.result)
	}

	if (redirect) {
		return <Redirect to="/" />
	}

	const list = (anchor) => (
		<div
			style={{width: 270, marginTop: 20, marginLeft: drawerLinkHide === "2" ? 15 : 0}}
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<div>
				{drawerLinkHide === "1" ? (
					<>
						<div>
							<div
								className={classes.profileBackground}
								onClick={() => setOpenProfile(!openProfile)}
							>
								<img
									src={profileData?.profilePic}
									style={{
										height: 100,
										width: 100,
										objectFit: "cover",
										borderRadius: 50,
										marginBottom: 15,
									}}
									alt=""
								/>

								<p style={{fontSize: 18, fontWeight: "bold"}}>{profileData?.username}</p>
								<p>{profileData?.email}</p>
							</div>

							<div>
								<Link to="/student-profile" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<User />
										<p style={{marginLeft: 10}}>Profile</p>
									</div>

									<ChevronRight />
								</Link>
								<Link to="/videos" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Film />
										<p style={{marginLeft: 10}}>Videos</p>
									</div>

									<ChevronRight />
								</Link>
								<Link to="/certificates" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<BookOpen />
										<p style={{marginLeft: 10}}>Certificates</p>
									</div>

									<ChevronRight />
								</Link>
								<Link to="/rewards" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Award />
										<p style={{marginLeft: 10}}>Rewards</p>
									</div>
									<ChevronRight />
								</Link>

								<Link to="/reviews" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Info />
										<p style={{marginLeft: 10}}>Reviews</p>
									</div>

									<ChevronRight />
								</Link>
								<Link to="/about" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Users />
										<p style={{marginLeft: 10}}>About us</p>
									</div>

									<ChevronRight />
								</Link>
								<Link to="/privacy-policy" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Pocket />
										<p style={{marginLeft: 10}}>Privacy Policy</p>
									</div>

									<ChevronRight />
								</Link>
								<Link to="/terms-of-use" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Clipboard />
										<p style={{marginLeft: 10}}>Terms Of Use</p>
									</div>

									<ChevronRight />
								</Link>

								{drawerLinkHide === "1" ? (
									<Link to="/return-policy" className={classes.link}>
										<div style={{display: "flex", alignItems: "center"}}>
											<DollarSign />
											<p style={{marginLeft: 10}}>Refund Policy</p>
										</div>

										<ChevronRight />
									</Link>
								) : (
									""
								)}

								<Link to="/cookie-policy" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<Aperture />
										<p style={{marginLeft: 10}}>Cookie Policy</p>
									</div>

									<ChevronRight />
								</Link>
							</div>
						</div>
					</>
				) : drawerLinkHide === "2" ? (
					<>
						{teachersData && (
							<TeacherDrawer drawerLinkHide={drawerLinkHide} teachersData={teachersData} />
						)}
					</>
				) : (
					<div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<div
								style={{
									height: 200,
									width: 200,
									borderRadius: "50%",
									backgroundColor: "white",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
									marginBottom: 20,
								}}
							>
								<img
									src={Logo}
									style={{
										height: "150px",
										objectFit: "cover",
										marginTop: -20,
									}}
									alt=""
								/>
							</div>
						</div>
						<div>
							<Link to="/about" className={classes.link}>
								<div style={{display: "flex", alignItems: "center"}}>
									<Users />
									<p style={{marginLeft: 10}}>About us</p>
								</div>

								<ChevronRight />
							</Link>
							<Link to="/privacy-policy" className={classes.link}>
								<div style={{display: "flex", alignItems: "center"}}>
									<Pocket />
									<p style={{marginLeft: 10}}>Privacy Policy</p>
								</div>

								<ChevronRight />
							</Link>
							<Link to="/terms-of-use" className={classes.link}>
								<div style={{display: "flex", alignItems: "center"}}>
									<Clipboard />
									<p style={{marginLeft: 10}}>Terms Of Use</p>
								</div>

								<ChevronRight />
							</Link>

							{drawerLinkHide === "1" ? (
								<Link to="/return-policy" className={classes.link}>
									<div style={{display: "flex", alignItems: "center"}}>
										<DollarSign />
										<p style={{marginLeft: 10}}>Refund Policy</p>
									</div>

									<ChevronRight />
								</Link>
							) : (
								""
							)}

							<Link to="/cookie-policy" className={classes.link}>
								<div style={{display: "flex", alignItems: "center"}}>
									<Aperture />
									<p style={{marginLeft: 10}}>Cookie Policy</p>
								</div>

								<ChevronRight />
							</Link>
						</div>
					</div>
				)}
			</div>
		</div>
	)

	return (
		<div className={classes.fixed}>
			<UpdateAddressDialog open={openUpdateAddress} setOpen={setOpenUpdateAddress} />
			<ProfilePicupdate
				open={openProfile}
				setOpen={setOpenProfile}
				data={profileData}
				getBackData={getBackData}
			/>
			<AppBar position="static" style={{backgroundColor: "#d2dae2cc"}}>
				<Toolbar>
					<IconButton onClick={toggleDrawer("left", true)}>
						<MenuIcon />
					</IconButton>
					<Typography
						style={{
							textAlign: "center",
							color: "#1e272e",
							fontWeight: "bold",
							width: "100%",
						}}
						variant="h6"
						noWrap
					>
						LiveSloka
					</Typography>

					<div style={{flexGrow: 1}} />
					<Tooltip title="Show Notifications">
						<Notifications />
					</Tooltip>
					<Tooltip title="Exit from App">
						<a href="/" style={{textDecoration: "none", color: "rgb(30, 39, 46)"}}>
							<IconButton color="inherit" onClick={() => logout()}>
								<ExitToAppIcon />
							</IconButton>
						</a>
					</Tooltip>
				</Toolbar>
			</AppBar>

			<React.Fragment key={"left"}>
				<SwipeableDrawer
					anchor={"left"}
					open={state["left"]}
					onClose={toggleDrawer("left", false)}
					onOpen={toggleDrawer("left", true)}
				>
					{list("left")}
				</SwipeableDrawer>
			</React.Fragment>
		</div>
	)
}
