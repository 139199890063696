import React, {useCallback, useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import {Link} from "react-router-dom"
import {getCustomerOption, updateLastTimeJoined} from "../Services/services"
import useWindowDimensions from "./useWindowDimensions"
import {Avatar} from "@material-ui/core"
import api from "./../helpers/api"
import BookIcon from "@material-ui/icons/Book"
import io from "socket.io-client"
import {Clock, XCircle} from "react-feather"
import LeavesModal from "./LeavesModal"
import {Alert} from "@material-ui/lab"
import moment from "moment"
import ReviewsModal from "./ReviewsModal"
import {DollarSign} from "react-feather"

const socket = io(process.env.REACT_APP_API_KEY)
const MeetingcardSubscription = ({
	link,
	isClassTemperarilyCancelled,
	message,
	customerId,
	isJoinButtonDisabled,
	paidTill,
	scheduleId,
	subject,
	desc2,
	setDialogOpen,
	setSelectedSchedule,
	_id,
	cancelledDates,
	scheduleDays,
	setOpen,
	setMessage,
	setColor,
	setRefresh,
	setAllowedDays,
	setEditingId,
	setSelectedDateToCancel,
	customerName,
	isSummerCampClass,
	isTeacherOnLeave,
	isDemo,
	demo,
	allData,
}) => {
	const {width} = useWindowDimensions()
	const [leavesModal, setleavesModal] = useState(false)
	const [reviewsModal, setReviewsModal] = useState(false)
	const [desktopView, setDesktopView] = useState(false)
	const [options, setOptions] = useState({})

	const deskview = useCallback(() => {
		if (width > 800) {
			setDesktopView(true)
		} else {
			setDesktopView(false)
		}
	}, [width])

	useEffect(() => {
		deskview()
	}, [width, deskview])

	const useStyles = makeStyles({
		root: {
			margin: "10px",
			padding: "10px",
			background: demo ? "#ecf0f1" : "rgba( 255, 255, 255, 0.45 )",
			boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
			backdropFilter: "blur( 8.5px )",
			WebkitBackdropFilter: "blur( 8.5px )",
			borderRadius: "10px",
			border: "1px solid rgba( 255, 255, 255, 0.18 )",
			width: desktopView ? 400 : "100%",
		},
	})

	const classes = useStyles()

	useEffect(() => {
		getCustomerOption(customerId)
			.then((data) => {
				setOptions(data.data.result)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [isDemo, customerId])

	const deleteCancelledClass = (id) => {
		if (window.confirm("Cancel Leave ?")) {
			api
				.delete(`/cancelclass/${id}`)
				.then((data) => {
					setColor("success")
					setMessage(data.data.message)
					setOpen(true)
					setRefresh((prev) => !prev)
				})
				.catch((err) => {
					console.log(err)
					setColor("error")
					setMessage(
						err.response && err.response.data && err.response.data.error
							? err.response.data.error
							: "Something went wrong"
					)
					setOpen(true)
				})
		}
	}

	const updateCancelledClass = (id, cancelledDate) => {
		setEditingId(id)
		setDialogOpen(true)
		setSelectedSchedule({
			subject,
			_id,
		})
		setAllowedDays(scheduleDays)
		setSelectedDateToCancel(new Date(cancelledDate))
	}

	const joinClass = (link) => {
		updateLastTimeJoined(localStorage.getItem("userId"), _id)
			.then((data) => {
				socket.emit("student-joined-class", {
					scheduleId,
					email: localStorage.getItem("userId"),
				})
				window.location = link
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<>
			<Card
				style={{
					backgroundColor: isTeacherOnLeave ? "rgba(235, 123, 123, 0.1)" : undefined,
				}}
				className={classes.root}
			>
				{isTeacherOnLeave && (
					<Alert variant="filled" severity="warning">
						{isTeacherOnLeave}
					</Alert>
				)}
				{isClassTemperarilyCancelled && (
					<Alert variant="filled" severity="warning">
						{message}
					</Alert>
				)}

				<div
					style={{
						display: "flex",
						justifyContent: "space-evenly",
						alignItems: "center",
					}}
				>
					<div>
						{demo ? (
							<div
								style={{
									height: "60px",
									width: "60px",
									marginLeft: "10px",
									backgroundColor: "#ff4757",
									borderRadius: "50%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									color: "white",
								}}
							>
								<Clock />
								<p style={{fontSize: 10}}>Demo</p>
							</div>
						) : (
							<Avatar
								style={{
									height: "50px",
									width: "50px",
									marginLeft: "10px",
									backgroundColor: "#27ae60",
								}}
							>
								{customerName[0]}
							</Avatar>
						)}
					</div>
					<div>
						<Typography
							style={{
								marginTop: "10px",
								fontWeight: "bold",
								fontSize: "18px",
								marginLeft: 16,
							}}
						>
							{customerName}
						</Typography>
						<div>
							<CardContent>
								<Typography
									variant="body2"
									color="textSecondary"
									component="p"
									style={{fontWeight: 600}}
								>
									{desc2}
								</Typography>
							</CardContent>
						</div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-evenly",
					}}
				>
					<>
						{!paidTill ? (
							""
						) : (
							<div style={{textAlign: "center", cursor: "pointer"}}>
								<h3 style={{color: "#3867d6", height: 30, marginTop: 1}}>
									{moment(paidTill).format("MMM DD")}
								</h3>
								<p style={{fontSize: "14px", fontWeight: "bold"}}>Due Date</p>
							</div>
						)}
					</>
					{options && (
						<div style={{textAlign: "center"}}>
							<Link
								to={
									!options.isScheduled
										? `/options/${options._id}`
										: `/plans/subscribe/${options.customer._id}`
								}
								style={{
									textDecoration: "none",
								}}
							>
								<DollarSign style={{color: "#27ae60"}} />
								<p
									style={{
										fontSize: "14px",
										fontWeight: "bold",
										color: "black",
									}}
								>
									Pay Now
								</p>
							</Link>
						</div>
					)}

					<div style={{textAlign: "center"}}>
						<Link
							to={`/particular-material/${scheduleId}`}
							style={{
								textDecoration: "none",
							}}
						>
							<BookIcon style={{color: "#27ae60"}} />
							<p
								style={{
									fontSize: "14px",
									fontWeight: "bold",
									color: "black",
								}}
							>
								Materials
							</p>
						</Link>
					</div>

					<div
						style={{textAlign: "center", cursor: "pointer"}}
						onClick={() => setleavesModal(true)}
					>
						<XCircle style={{color: "#e74c3c", height: 23, width: 23}} />
						<p style={{fontSize: "14px", fontWeight: "bold"}}>Leaves</p>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-around",
						marginTop: "20px",
						alignItems: "center",
					}}
				>
					<Button
						onClick={() => joinClass(link)}
						disabled={
							(!isSummerCampClass && isJoinButtonDisabled) ||
							isTeacherOnLeave ||
							isClassTemperarilyCancelled
						}
						style={{
							backgroundColor:
								(!isSummerCampClass && isJoinButtonDisabled) || isTeacherOnLeave
									? "#aaa"
									: "#27ae60",
							color: "#fff",
							height: "40px",
							width: 150,
						}}
					>
						Join Class
					</Button>
				</div>
			</Card>

			<LeavesModal
				open={leavesModal}
				setOpen={setleavesModal}
				cancelledDates={cancelledDates}
				updateCancelledClass={updateCancelledClass}
				deleteCancelledClass={deleteCancelledClass}
			/>

			<ReviewsModal open={reviewsModal} setOpen={setReviewsModal} allData={allData} />
		</>
	)
}

export default MeetingcardSubscription
