import React, {useState, useEffect} from "react"
import {MenuAppBar} from "../../../components"
import {makeStyles} from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import {red} from "@material-ui/core/colors"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import TeachersAccordianDate from "./TeachersAccordianDate"
import Axios from "axios"
import moment from "moment"
import TeacherBottomTabs from "../../../components/BottomTabs/TeacherBottomTabs"
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
	root: {
		width: 345,
		marginBottom: 20,
	},

	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
		marginTop: -60,
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	avatar: {
		backgroundColor: red[500],
	},
}))

const ExpandedIcon = ({data}) => {
	const classes = useStyles()
	const [expanded, setExpanded] = useState(false)
	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	return (
		<>
			<CardActions>
				<IconButton
					className={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					<ExpandMoreIcon />
				</IconButton>
			</CardActions>
			{data.map((data) => (
				<TeachersAccordianDate
					presentName={data.presentees}
					absentName={data.absentees}
					requestedPaidStudents={data.requestedPaidStudents}
					date={data.date}
					expandeded={expanded}
				/>
			))}
		</>
	)
}

const TeachersPayroll = () => {
	const classes = useStyles()
	const [dataRes, setDataRes] = useState()
	const [date, setDate] = useState(moment().format("YYYY-MM"))
	const [salaryMonths, setSalaryMonths] = useState([])

	useEffect(() => {
		getPayrollData(date)
	}, [date])

	useEffect(() => {
		getSalDates()
	}, [])

	const getSalDates = async () => {
		const data = await Axios.get(`${process.env.REACT_APP_API_KEY}/salary/months`)
		console.log(data)
		setSalaryMonths(data && data.data.result)
	}

	const getPayrollData = async (date) => {
		const data = await Axios.get(
			`${process.env.REACT_APP_API_KEY}/salary/teacher/${localStorage.getItem(
				"teacherId"
			)}?month=${date}`
		)
		setDataRes(data && data.data)
	}

	return (
		<div>
			<MenuAppBar />

			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					marginTop: 100,
					flexDirection: "column",
				}}
			>
				<Card
					style={{
						width: 327,
						height: 100,
						marginBottom: 20,
						backgroundColor: "#2ecc71",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<p style={{fontSize: 18, fontWeight: "bold", color: "white"}}>
						Total Salary: {dataRes && dataRes.totalSalary}
					</p>
				</Card>
				<div style={{marginTop: "20px", width: 300}}>
					<FormControl variant="outlined" style={{width: "100%"}}>
						<InputLabel id="salary-months-label">Select Month</InputLabel>
						<Select
							labelId="salary-months-label"
							id="salary-months"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							label="Select Month"
						>
							{salaryMonths &&
								salaryMonths.map((month) => (
									<MenuItem key={month} value={month}>
										{moment(month).format("MMM YYYY")}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</div>

				{dataRes &&
					dataRes.result.map((data) => {
						return (
							<Card className={classes.root}>
								<CardHeader
									avatar={
										<Avatar aria-label="recipe" className={classes.avatar}>
											{data.className[0]}
										</Avatar>
									}
									title={data.className}
									subheader={data.createdAt}
									style={{fontWeight: "bold"}}
								/>

								<CardActions disableSpacing>
									<Typography style={{fontSize: 14, fontWeight: "bold", marginLeft: 5}}>
										Total Dates: {data.dates.length}
									</Typography>
									<Typography style={{fontSize: 14, fontWeight: "bold", marginLeft: 5}}>
										Total Classes: {data.totalStudents}
									</Typography>
								</CardActions>

								<ExpandedIcon data={data.dates} />

								<CardContent>
									<Typography paragraph>Class Amount: {data.totalSalary}</Typography>
								</CardContent>
							</Card>
						)
					})}
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
		</div>
	)
}

export default TeachersPayroll
