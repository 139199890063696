import React from "react"
import {Card} from "@material-ui/core"
import moment from "moment"
import {UserCheck, UserX} from "react-feather"

const TransactionCard = ({name, time, amount, currency, subject, status}) => {
	return (
		<Card
			style={{
				width: "80%",
				height: 70,
				marginBottom: 15,
				margin: "0 auto 15px",
				background: "rgba( 255, 255, 255, 0.45 )",
				boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
				backdropFilter: "blur( 8.5px )",
				WebkitBackdropFilter: "blur( 8.5px )",
				borderRadius: "10px",
				border: "1px solid rgba( 255, 255, 255, 0.18 )",
			}}
		>
			<div style={{margin: 15, display: "flex", justifyContent: "space-between"}}>
				<div style={{display: "flex"}}>
					<div
						style={{
							height: 40,
							width: 40,
							backgroundColor: "#ecf0f1",
							borderRadius: 12,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{status === "CANCELLED" ? <UserX /> : <UserCheck style={{color: "#2980b9"}} />}
					</div>

					<div style={{marginLeft: 10}}>
						<p style={{fontWeight: "bold", fontSize: 16, color: "#2c3e50"}}>{subject}</p>
						<p style={{fontSize: 13, color: "#34495e"}}>{name}</p>
					</div>
				</div>
				<div>
					<p
						style={{
							fontWeight: "bold",
							fontSize: 16,
							textAlign: "right",
							color: status === "CANCELLED" ? "red" : "#2ecc71",
						}}
					>
						{currency} {amount}
					</p>
					<p style={{fontSize: 12, textAlign: "right"}}>{moment(time).format("MMM Do")}</p>
				</div>
			</div>
		</Card>
	)
}

export default TransactionCard
