import React from "react"
import Vimeo from "@u-wave/react-vimeo"
import {useParams, useLocation} from "react-router-dom"
import useWindowDimensions from "../../../helpers/useDimensions"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
const Videopage = () => {
	const params = useParams()
	const location = useLocation()
	const {width} = useWindowDimensions()

	return (
		<div>
			<MenuAppBar />

			<div
				style={{
					marginTop: 70,
					marginBottom: 80,
				}}
			>
				<Vimeo width={`${width}px`} video={params.id} />

				<div style={{padding: 20}}>
					<p style={{fontWeight: 600, marginBottom: 20}}>{location?.state?.data.name}</p>
					<p style={{fontWeight: 400}}>{location?.state?.data.description}</p>
				</div>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default Videopage
