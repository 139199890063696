import api from "../../helpers/api"

// Single Teacher
export const GetSingleTeacher = async (id) => {
	try {
		const res = await api.get(`/admin/getSingleTeacher/${id}`)
		return res
	} catch (err) {
		return err.response
	}
}

export const GetTeacherMeetings = async (id) => {
	try {
		const res = await api.get(`/teacher/getTeacherMeetings/${id}`)
		return res
	} catch (err) {
		return err.response
	}
}
