import React, {useEffect, useState} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../components"
import api from "../../helpers/api"
import {makeStyles} from "@material-ui/core/styles"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import CheckIcon from "@material-ui/icons/Check"
import RemoveIcon from "@material-ui/icons/Remove"
import moment from "moment"
import {InputLabel, MenuItem, FormControl, Select, Card} from "@material-ui/core"
import Footer from "../login/Footer"
import useWindowDimensions from "../../helpers/useDimensions"
import CancelIcon from "@material-ui/icons/Cancel"
const useStyles = makeStyles((theme) => ({
	root: {
		width: "90%",
		marginTop: "100px",
		margin: "0 auto",
		marginBottom: 80,
	},
	heading: {
		fontSize: "15px",
		fontWeight: "bold",
	},
	formControl: {
		width: "100%",
		marginBottom: "20px",
	},

	isPresentClass: {},
}))

const UsersHistory = () => {
	const {width} = useWindowDimensions()

	const [selectedClass, setSelectedClass] = useState()

	const handleChange = (event) => {
		const newArray = event.target.value.data

		newArray.reverse()
		setSelectedClass(event.target.value)
	}

	const classes = useStyles()
	const [historyData, setHistoryData] = useState()
	useEffect(() => {
		getUsersHistory()
	}, [])

	const getUsersHistory = async () => {
		const data = await api.get(
			`${process.env.REACT_APP_API_KEY}/user/history/${localStorage.getItem("userId")}`
		)
		setHistoryData(data && data.data)
	}

	return (
		<div className="positionCSS">
			<MenuAppBar />

			<div className={classes.root}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel>Select Class</InputLabel>
					<Select value={selectedClass} onChange={handleChange} label="Select Class">
						{historyData &&
							historyData.result.map((data) => {
								return <MenuItem value={data}>{data.subject}</MenuItem>
							})}
					</Select>
				</FormControl>
				{selectedClass &&
					selectedClass.data.map((data) => {
						if (data.isPaymentObject) {
							return (
								<Card
									style={{
										width: "100%",
										height: 70,
										marginBottom: 15,
										background: "rgba( 255, 255, 255, 0.45 )",
										boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
										backdropFilter: "blur( 8.5px )",
										WebkitBackdropFilter: "blur( 8.5px )",
										borderRadius: "10px",
										border: "1px solid rgba( 255, 255, 255, 0.18 )",
									}}
								>
									<div
										style={{
											margin: 15,
											display: "flex",
											justifyContent: "space-between",
										}}
									>
										<div style={{display: "flex"}}>
											<div
												style={{
													height: 40,
													width: 40,
													backgroundColor: "#ecf0f1",
													borderRadius: 12,
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												{data.type === "RAZORPAY" ? (
													<p style={{color: "rgb(41, 128, 185)", fontSize: 21}}>₹</p>
												) : (
													<AttachMoneyIcon style={{color: "#2980b9"}} />
												)}
											</div>
										</div>

										<div
											style={{
												marginLeft: 10,
												display: "flex",
												alignItems: "center",
											}}
										>
											<p
												style={{
													fontWeight: "bold",
													fontSize: 16,
													color: "#2980b9",
												}}
											>
												Payment
											</p>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<p
												style={{
													fontSize: 14,
													textAlign: "right",
													fontWeight: "bold",
												}}
											>
												{moment(data.createdAt).format("L")}
											</p>
										</div>
									</div>
								</Card>
							)
						}
						return (
							<Card
								style={{
									width: "100%",
									height: 70,
									marginBottom: 15,
									background: "rgba( 255, 255, 255, 0.45 )",
									boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
									backdropFilter: "blur( 8.5px )",
									WebkitBackdropFilter: "blur( 8.5px )",
									borderRadius: "10px",
									border: "1px solid rgba( 255, 255, 255, 0.18 )",
								}}
							>
								<div
									style={{
										margin: 15,
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									<div
										style={{
											height: 40,
											width: 40,
											backgroundColor: "#ecf0f1",
											borderRadius: 12,
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{data.isPresent ? (
											<CheckIcon style={{color: "#2ecc71"}} />
										) : data.isRequestedStudent ? (
											<RemoveIcon style={{color: "#f1c40f"}} />
										) : (
											<CancelIcon style={{color: "#e74c3c"}} />
										)}
									</div>

									<div
										style={{
											marginLeft: 10,
											display: "flex",
											alignItems: "center",
										}}
									>
										<p
											style={{
												fontWeight: "bold",
												fontSize: 16,
												color: data.isPresent
													? "#2ecc71"
													: data.isRequestedStudent
													? "#f1c40f"
													: "#e74c3c",
											}}
										>
											{data.isPresent ? "Present" : data.isRequestedStudent ? "Request" : "Absent"}
										</p>
									</div>

									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<p
											style={{
												fontSize: 14,
												textAlign: "right",
												fontWeight: "bold",
											}}
										>
											{moment(data.createdAt).format("L")}
										</p>
									</div>
								</div>
							</Card>
						)
					})}
			</div>

			{width > 800 ? (
				<div
					style={{
						position: "absolute",
						bottom: 0,
						width: "100%",
						left: 0,
						right: 0,
						top: "103%",
					}}
				>
					<Footer />
				</div>
			) : (
				<div
					style={{
						marginTop: "30px",
						position: "fixed",
						left: 0,
						bottom: 0,
						textAlign: "center",
						width: "100%",
					}}
				>
					<StudentBottomTabs />
				</div>
			)}
		</div>
	)
}

export default UsersHistory
