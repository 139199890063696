import {Grid, makeStyles} from "@material-ui/core"
import React, {useState, useEffect} from "react"
import {MenuAppBar} from "../../../components"
import StripeCardElement from "./StripeCardElement"
import useWindowDimensions from "../../../components/useWindowDimensions"
import {useParams} from "react-router"
import {getPlanDetails} from "../../../Services/services"
import Container from "@material-ui/core/Container"
import stripeLogo from "../../../assets/stripe-logo.png"

const PaymentInfo = ({plan}) => {
	const {width} = useWindowDimensions()
	const classes = useStyles()
	let prefix = plan.currency ? plan.currency.prefix : ""

	return (
		<Grid item xs={12} sm={12} md={6}>
			<div className={classes.divCenter}>
				<div className={classes.paymentInfo}>
					<div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
						<div>
							<p style={{fontSize: width > 430 ? 34 : 20, color: "white"}}>{plan.name}</p>
							<p style={{fontSize: width > 430 ? 34 : 20, fontWeight: 600, color: "white"}}>
								{prefix} {plan.amount}
							</p>
						</div>
					</div>

					<p
						style={{
							fontSize: width > 430 ? 12 : 11.5,
							color: "white",
							marginTop: 10,
							textAlign: "center",
						}}
					>
						You will be charged {prefix} {plan.amount} for your first {plan.intervalCount}{" "}
						{plan.interval}
						{plan.intervalCount > 1 ? "s" : ""}. After that, a recurring charge of {prefix}{" "}
						{plan.amount} will automatically apply for every {plan.intervalCount} {plan.interval}
						{plan.intervalCount > 1 ? "s" : ""}. You may cancel at any time in your payment
						settings. By clicking 'Pay Now', you agree to our Terms of Service and authorize this
						recurring charge.
					</p>
				</div>
			</div>
		</Grid>
	)
}

const Checkout = () => {
	const [plan, setPlan] = useState({})

	const {width} = useWindowDimensions()
	const classes = useStyles()
	const {customerId, planId, resubscribe} = useParams()
	useEffect(() => {
		getPlanDetails(planId)
			.then((data) => {
				setPlan(data.data.result)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [planId])

	return (
		<div>
			<MenuAppBar />
			<Container>
				<Grid container>
					{width <= 500 ? <PaymentInfo plan={plan} /> : ""}
					<Grid
						item
						xs={12}
						sm={6}
						style={{background: "rgb(244, 244, 244)", width: "100%", minHeight: "100vh"}}
					>
						<div className={classes.divCenter}>
							<div style={{display: "flex", flexDirection: "column"}}>
								<div>
									<p className={classes.title}>Live Sloka Subscription</p>
									<p className={classes.alignCenter}>
										Payment gateway is powered by
										<img src={stripeLogo} alt="stripe" className={classes.stripeLogo} />
									</p>
								</div>

								<div style={{marginTop: 50}}>
									<StripeCardElement
										plan={plan}
										customerId={customerId}
										resubscribe={resubscribe}
									/>
								</div>
							</div>
						</div>
					</Grid>

					{width > 500 ? <PaymentInfo plan={plan} /> : ""}
				</Grid>
			</Container>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	divCenter: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 80,
	},
	title: {
		fontSize: 24,
		fontWeight: 600,
		marginTop: 40,
		letterSpacing: 1.5,
	},
	paymentInfo: {
		height: 250,
		backgroundColor: "rgb(0, 35, 51)",
		padding: 30,
		borderRadius: 10,
	},
	stripeLogo: {
		height: 50,
	},
	alignCenter: {
		display: "flex",
		alignItems: "center",
	},
}))

export default Checkout
