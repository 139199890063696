import React from "react"
import AuthState from "./context/auth/AuthState"
import "./App.scss"
import "./index.css"
import Routes from "./routes/Routes"
import {ConfirmProvider} from "material-ui-confirm"
import {loadStripe} from "@stripe/stripe-js"
import {Elements} from "@stripe/react-stripe-js"
import {SnackbarProvider} from "notistack"

function App() {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

	return (
		<Elements stripe={stripePromise}>
			<SnackbarProvider maxSnack={3}>
				<ConfirmProvider>
					<AuthState>
						<Routes />
					</AuthState>
				</ConfirmProvider>
			</SnackbarProvider>
		</Elements>
	)
}

export default App
