import {
	FormControlLabel,
	Radio,
	RadioGroup,
	Button,
	CircularProgress,
	Snackbar,
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {MenuAppBar, SnackbarAlert} from "../../../components"
import api from "../../../helpers/api"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import {useHistory, useParams} from "react-router-dom"
import useWindowDimensions from "../../../components/useWindowDimensions"
import Singleoption from "./Singleoption"
import {Alert} from "@material-ui/lab"

const Options = () => {
	const params = useParams()
	let days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]

	const {width} = useWindowDimensions()
	const history = useHistory()
	const [value, setValue] = useState("female")
	const [error, setError] = useState("")

	const [optionsData, setOptionsData] = useState()
	const [selectedId, setSelectedId] = useState("")
	const [loading, setLoading] = useState(false)
	const [modalAlert, setModalAlert] = useState(false)
	const handleChange = (event) => {
		setSelectedId(event.target.value)
	}

	useEffect(() => {
		;(async () => {
			try {
				const data = await api.get(`/options/${params.id}`)
				setOptionsData(data?.data?.result)
			} catch (error) {
				console.log(error)
				if (error?.response?.data?.error) {
					setError(error?.response?.data?.error)
				} else {
					setError("Something went wrong!")
				}
			}
		})()
	}, [params.id])

	const onSubmit = async () => {
		if (selectedId) {
			setLoading(true)
			let selectedOption = optionsData && optionsData.filter((item) => item._id === selectedId)
			let formData = {}

			if (selectedOption[0].isScheduled) {
				formData = {
					selectedSlotId: selectedOption[0]._id,
					selectedSlotType: "EXISTING",
				}
			} else {
				formData = {
					selectedSlotId: selectedOption[0]._id,
					selectedSlotType: "NEW",
				}
			}

			try {
				const data = await api.put(`/options/${params.id}`, formData)
				console.log(data)
				if (data.status === 200) {
					history.push(`/plans/subscribe/${data?.data?.result?.customer}`)
				}
			} catch (error) {
				console.log(error)
			}
			setLoading(false)
		} else {
			setModalAlert(true)
		}
	}

	return (
		<div>
			<SnackbarAlert
				setOpen={setModalAlert}
				open={modalAlert}
				message={"Please Select a option"}
				color={"error"}
			/>
			<MenuAppBar />
			{error ? (
				<div style={{maxWidth: 400, margin: "90px auto"}}>
					<Alert severity="error" variant="filled">
						{error}
					</Alert>
				</div>
			) : (
				<>
					<div style={{marginTop: 100}}>
						<div
							style={{
								height: "50px",
								display: "flex",
								flexDirection: "row",
								position: "sticky",
								top: "0px",
								backgroundColor: "white",
							}}
						>
							<div
								style={{
									width: width < 700 ? "10%" : "5%",
									backgroundColor: "#f1f2f6",
								}}
							/>
							<div
								style={{
									width: width < 700 ? "90%" : "95%",
									backgroundColor: "#f1f2f6",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "wrap",
									}}
								>
									{days.map((day, i) => (
										<div key={i} style={{}} className="schedulerHeader">
											{" "}
											{width < 700 ? day.toUpperCase().slice(0, 3) : day.toUpperCase()}
										</div>
									))}
								</div>
							</div>
						</div>

						<div style={{width: "100%", display: "flex", flexDirection: "row"}}>
							<div
								style={{
									width: width < 700 ? "10%" : "5%",
									backgroundColor: "#EAF0F1",
								}}
							>
								{optionsData &&
									optionsData.map((hour, i) => (
										<div
											key={i}
											className="time-header"
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<RadioGroup
												value={selectedId}
												onChange={handleChange}
												style={{marginLeft: 20}}
											>
												<FormControlLabel
													value={hour._id}
													control={
														<Radio
															icon={<CheckCircleIcon />}
															checkedIcon={<CheckCircleIcon style={{color: "#3867d6"}} />}
														/>
													}
												/>
											</RadioGroup>
										</div>
									))}
							</div>

							<div style={{width: width < 700 ? "90%" : "95%"}}>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "wrap",
									}}
								>
									{optionsData &&
										optionsData.map((time, i) => {
											return (
												<React.Fragment key={i}>
													{days.map((day, j) => {
														return (
															<Singleoption
																day={day}
																time={time}
																value={value}
																selectedId={selectedId}
																setSelectedId={setSelectedId}
															/>
														)
													})}
												</React.Fragment>
											)
										})}
								</div>
							</div>
						</div>
					</div>

					<div
						style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20}}
					>
						<Button
							disabled={loading}
							variant="contained"
							color="primary"
							style={{width: 200}}
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress style={{height: 30, width: 30, color: "white"}} />
							) : (
								"Submit"
							)}
						</Button>
					</div>
				</>
			)}
		</div>
	)
}

export default Options
