import React, {useEffect, useState} from "react"
import {io} from "socket.io-client"
import axios from "axios"
import {
	Avatar,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
} from "@material-ui/core"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import Close from "@material-ui/icons/Close"
import Chat from "@material-ui/icons/Chat"
import ChatBot from "./ChatBot"
import Group from "./Group"
import GroupIcon from "@material-ui/icons/Group"
import PersonIcon from "@material-ui/icons/Person"
import Class from "../assets/class.png"
import {SEEN} from "./helpers"
let socket

let currentGroup = null

const AllChats = ({showBot}) => {
	// const lastElement = useRef(null)

	const role = +localStorage.getItem("role")
	const userId = localStorage.getItem("userId")

	// const [timeout, setSTimeout] = useState(undefined)

	const [groups, setGroups] = useState([])

	const [chatHead, setChatHead] = useState("Live Sloka 👋")
	const [filterValue, setFilterValue] = useState("")
	const filterdGroups = [...groups].filter((group) =>
		group.groupName.toLowerCase().includes(filterValue)
	)

	const [isRoom, setIsRoom] = useState(false)
	const [isGroup, setIsGroup] = useState(null)
	useEffect(() => {
		if (!(isRoom || !!isGroup)) {
			if (role === 1)
				axios.get(`${process.env.REACT_APP_API_KEY}/customerGroups/${userId}`).then(({data}) => {
					const modifiedGroups = data.map((el) => ({
						...el,
						seen: SEEN.includes(el.groupID) || el.messages[0]?.userID === userId ? true : false,
						groupName: el.isClass
							? el.groupName
							: (el.teachers[0].username || el.teachers[0].userId) + " Teacher",
					}))

					setGroups(modifiedGroups)
					console.log(modifiedGroups)
				})
			else if (role === 2)
				axios.get(`${process.env.REACT_APP_API_KEY}/teacherGroups/${userId}`).then(({data}) => {
					const modifiedGroups = data.map((el) => ({
						...el,
						seen: SEEN.includes(el.groupID) || el.messages[0]?.userID === userId ? true : false,
						groupName: el.isClass
							? el.groupName
							: el.customerEmails[0].username || el.customers[0].firstName,
					}))
					console.log(modifiedGroups)
					setGroups(modifiedGroups)
				})
		}
		// }, [isGroup, isRoom]) if room is set to be closed
	}, [])

	const handelGroup = (groupobj) => {
		currentGroup = groupobj
		setIsGroup(groupobj)
		setChatHead(groupobj.groupName)
		if (!groupobj.seen) {
			seeGroup(groupobj.groupID)
		}
	}
	const handelBot = (e) => {
		setIsRoom(true)
		setChatHead("Live Sloka")
	}

	const handelBack = (e) => {
		currentGroup = null

		setIsGroup(null)
		setIsRoom(false)
		setChatHead("Live Sloka 👋")
	}

	const handelChange = (e) => {
		const value = e.target.value.toLowerCase().trim()
		setFilterValue(value)
	}

	useEffect(() => {
		socket = io.connect(`${process.env.REACT_APP_API_KEY}/`)

		socket.on("message-to-group-from-bot", ({groupID, userID, message, username}) => {
			console.log(userID)
			unSeeGroup(groupID, userID, message, username)
		})

		return () => {
			removeListners()
		}
	}, []) //no need to rerender every time

	const removeListners = () => {
		// console.log(SEEN)
		sessionStorage.setItem("SEEN", JSON.stringify(SEEN))
		socket.removeAllListeners()
	}

	const seeGroup = (groupID) => {
		setGroups((prev) =>
			prev.map((el) => {
				if (el.groupID === groupID) {
					return {
						...el,
						seen: true,
					}
				}
				return el
			})
		)
		SEEN.push(groupID)
		// console.log(SEEN)
	}

	const unSeeGroup = (groupID, userID, message, username) => {
		setGroups((prev) =>
			prev.map((el) => {
				if (el.groupID === groupID) {
					return {
						...el,
						seen: currentGroup?.groupID === groupID || userId === userID,
						messages: [{...el.messages[0], message, userID, username}],
					}
				}
				return el
			})
		)
		if (userId !== userID && currentGroup?.groupID === groupID) {
			const index = SEEN.indexOf(groupID)
			if (index > -1) {
				SEEN.splice(index, 1)
			}
		}

		// console.log(SEEN)
	}
	return (
		<>
			<div
				className="chatbot"
				id="chatbot"
				style={{
					backgroundColor: "#fff",
				}}
			>
				<div className="chathome__header">
					{(isRoom || !!isGroup) && (
						<>
							<IconButton
								style={{
									marginRight: "auto",
								}}
								onClick={handelBack}
							>
								<ArrowBackIosIcon
									style={{
										color: "#fff",
									}}
								/>
							</IconButton>
						</>
					)}
					<h3
						style={{
							position: "absolute",
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						{chatHead}
					</h3>

					<IconButton
						style={{
							marginLeft: "auto",
						}}
						onClick={(e) => {
							e.stopPropagation()
							showBot(false)
						}}
					>
						<Close
							style={{
								color: "#fff",
							}}
						/>
					</IconButton>
				</div>

				{!(isRoom || !!isGroup) ? (
					<>
						<div className="chathome__message-window">
							<div className="chathome__entry">
								<TextField
									label="Search Class or Chat"
									fullWidth
									variant="outlined"
									onChange={handelChange}
									size="small"
								/>
							</div>
							<List
								style={{
									padding: "0",
								}}
							>
								{/* <ListSubheader component="div" id="nested-list-subheader">
									Groups
								</ListSubheader> */}

								<div
									style={{
										overflow: "auto",
										height: 480,
									}}
									className="chat-height"
								>
									<ListItem
										button
										onClick={handelBot}
										style={{
											margin: "0 0 6px 0",
										}}
									>
										<ListItemAvatar>
											<Avatar>
												<Chat />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Chat Support" secondary="How can we help you today?" />
									</ListItem>
									{filterdGroups.map((group) => (
										<ListItem
											button
											className="chat-list-item"
											key={group.groupID}
											onClick={() => handelGroup(group)}
											style={{
												// background: group.isClass
												// 	? group.seen
												// 		? "#3d9bffce"
												// 		: "#3d9aff"
												// 	: group.seen
												// 	? "#c83dffce"
												// 	: "#ef3dff",
												background: group.seen ? "#edededa8" : "#e3fffd",
												borderRadius: "10px",
												marginBottom: "5px",
											}}
										>
											{group.isClass ? (
												<img src={Class} width="40px" alt="class" className="chat-group-image" />
											) : (
												<ListItemAvatar>
													<Avatar
														src={`https://avatars.dicebear.com/api/adventurer/${group.groupID}.svg`}
													></Avatar>
												</ListItemAvatar>
											)}
											<ListItemText
												primary={group.groupName}
												secondary={lastMessage(group)}
												className="chat-list-item--text"
											/>

											{group.isClass ? <GroupIcon /> : <PersonIcon />}
										</ListItem>
									))}
								</div>
							</List>
						</div>
					</>
				) : isGroup ? (
					<Group group={isGroup} />
				) : (
					<ChatBot />
				)}
			</div>
		</>
	)
}

export default AllChats

const lastMessage = ({messages, isClosed, seen}) => {
	if (isClosed)
		return (
			<span
				style={{
					color: "#e74c3c",
					fontWeight: "bold",
					fontSize: "0.75rem",
				}}
			>
				Group is Closed
			</span>
		)
	if (messages.length > 0) {
		const {message, username, userID: email} = messages[0]
		return <>{createUsername(username, email, message, seen)}</>
	} else {
		return <></>
	}
}

const createUsername = (username, email, message, seen) => {
	if (email === localStorage.getItem("userId")) {
		return (
			<span
				style={{
					width: "100%",
					overflow: "hidden",
					display: "inline-block",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					fontWeight: seen ? "normal" : "bold",
					color: seen ? "inherit" : "black",
				}}
			>
				{message}
				{!seen && " ...(💬)"}
			</span>
		)
	} else {
		return (
			<span
				style={{
					width: "100%",
					overflow: "hidden",
					display: "inline-block",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap",
					fontWeight: seen ? "normal" : "bold",
					color: seen ? "inherit" : "black",
				}}
			>
				{`${username ? username.split(" ")[0] : email.split("@")[0]} : ${message}`}
				{!seen && " ...(💬)"}
			</span>
		)
	}
}

const renderGroupName = (group) => {
	console.log(group)
	return "name"
}
