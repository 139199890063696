/* eslint-disable no-unused-vars */
import React, {useState, useContext} from "react"
import Button from "@material-ui/core/Button"
import CssBaseline from "@material-ui/core/CssBaseline"
import TextField from "@material-ui/core/TextField"
import {Link} from "react-router-dom"
import Typography from "@material-ui/core/Typography"
import {makeStyles} from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import authContext from "../../context/auth/authContext"
import SnackbarAlert from "../../components/SnackbarAlert"
import {Redirect} from "react-router-dom"
import {CircularProgress} from "@material-ui/core"
import Logo from "./logo512.png"
import Footer from "./Footer"
import "./btn.scss"
import "./style.css"

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}))

export default function Login() {
	const classes = useStyles()

	const AuthContext = useContext(authContext)
	const {loginUser, loadUser} = AuthContext

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [result, setResult] = useState()
	const [open, setOpen] = useState(false)
	const [color, setColor] = useState("")
	const [loading, setLoading] = useState(false)

	const onSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		const data = await loginUser(email, password)
		setResult(data)

		if (data && data.status === 200) {
			localStorage.setItem("Name", data && data.data.result.firstName)
			localStorage.setItem("LName", data && data.data.result.lastName)
			localStorage.setItem("timezone", data && data.data.result.timeZone)
			localStorage.setItem("whatsapp", data && data.data.result.whatsAppnumber)

			localStorage.setItem("token", data && data.data.result.token)
			localStorage.setItem("role", data && data.data.result.roleId)
			localStorage.setItem("userId", data && data.data.result.userId)
			localStorage.setItem("customerId", data && data.data.result.customerId)
			localStorage.setItem("firstTimeLogin", data && data.data.result.firstTimeLogin)
			localStorage.setItem("teacherId", data && data.data.result.teacherId)
			localStorage.setItem("username", data && data.data.result.username)

			loadUser()
		}
		setOpen(true)
		setColor("error")
		setLoading(false)
	}

	if (localStorage.getItem("firstTimeLogin") === "Y" && localStorage.getItem("role") === "1") {
		return <Redirect to="/change-password" />
	} else if (
		localStorage.getItem("firstTimeLogin") === "N" &&
		localStorage.getItem("role") === "1"
	) {
		return <Redirect to="/user-dashboard" />
	} else if (
		localStorage.getItem("firstTimeLogin") === "Y" &&
		localStorage.getItem("role") === "2"
	) {
		return <Redirect to="/change-teacherPassword" />
	} else if (
		localStorage.getItem("firstTimeLogin") === "N" &&
		localStorage.getItem("role") === "2"
	) {
		return <Redirect to="/teacher-dashboard" />
	}

	return (
		<div>
			{result && result.status === 400 ? (
				<SnackbarAlert setOpen={setOpen} open={open} message={result.data.error} color={color} />
			) : (
				""
			)}
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<img src={Logo} style={{height: "150px", width: "150px "}} alt="" />
					<Typography component="h1" variant="h5">
						LiveSloka Signin
					</Typography>
					<form className={classes.form} onSubmit={onSubmit}>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="User Id"
							label="User Id"
							name="User Id"
							autoComplete="User Id"
							autoFocus
							onChange={(e) => setEmail(e.target.value)}
							value={email}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
						/>

						{loading ? (
							<div style={{textAlign: "center", marginTop: "20px"}}>
								<CircularProgress />
							</div>
						) : (
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Sign In
							</Button>
						)}
						{/* <Link to="/summercamps">
              <button
                style={{ width: "100%" }}
                class="loginButton loginButton--changeGradient"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <p style={{ fontSize: 14 }}>Online</p>
                <p style={{ fontSize: 14,fontWeight:"bold"}}>SUMMER CAMP</p>
                <p style={{ fontSize: 14 }}>Registrations Open</p>
              </button>
            </Link> */}
						<Link className="password-reset-link" to="/reset">
							Reset Password
						</Link>
					</form>
				</div>{" "}
			</Container>

			<div
				style={{
					marginTop: 200,
				}}
			>
				<Footer />
			</div>
		</div>
	)
}
