import React, {useEffect, useState} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import "./Rewards.css"
import {Link} from "react-router-dom"
import Redeemcard from "./Redeemcard"
import {getLookupData, getRewardsOfUser} from "../../../Services/services"
import {Typography} from "@material-ui/core"
const ShowRewards = () => {
	const [subjects, setSubjects] = useState([])
	const [rewards, setRewards] = useState(0)
	const [selectedSubject, setSelectedSubject] = useState(null)

	useEffect(() => {
		getLookupData("Subject")
			.then((data) => {
				setSubjects(data.data.result)
			})
			.catch((error) => {
				console.log(error)
			})
		getRewardsOfUser(localStorage.getItem("userId"))
			.then((data) => {
				setRewards(data?.data?.result?.rewards || 0)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	return (
		<div>
			<MenuAppBar />
			<div className="rewards-container">
				<Link
					to="/rewards-history"
					style={{textDecoration: "none"}}
					className="reward-card-container"
				>
					<div>
						<div className="reward-card-group">
							<div className="reward-display-card">
								<p className="reward-display-card-title">Available Points:</p>
								<p className="reward-display-card-points">{rewards}</p>
							</div>
						</div>
					</div>
				</Link>
				<Typography className="note-text">Note: all points shown below are for 1 class</Typography>
				{subjects.map((subject) => (
					<Redeemcard
						title={subject.subjectName}
						description={subject.description}
						rewards={subject.rewards}
						_id={subject._id}
						availableRewards={rewards}
						selectedSubject={selectedSubject}
						setSelectedSubject={() => setSelectedSubject(subject)}
						_setSelectedSubject={setSelectedSubject}
						setRewards={setRewards}
					/>
				))}
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default ShowRewards
