import {makeStyles, Avatar, Card, CircularProgress} from "@material-ui/core"
import React, {useState, useEffect, useContext, useMemo} from "react"
import {MenuAppBar} from "../../../components"
import TeacherBottomTabs from "../../../components/BottomTabs/TeacherBottomTabs"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import {Video, Calendar} from "react-feather"
import api from "../../../helpers/api"
import {getSlotFromTime} from "../../../helpers/getSlotFromTime"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {Link} from "react-router-dom"
import moment from "moment"
import io from "socket.io-client"
import {getJoinLink} from "../../../Services/services"
import {NotificationContext} from "./../../../context/NotificationContext"
import ChatHome from "../../../ChatBot/ChatHome"
import {isFuture} from "../../../Services"
const socket = io(process.env.REACT_APP_API_KEY)

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#f1f2f6",
		minHeight: "100vh",
	},
	teacherImage: {
		height: 50,
		width: 50,
		borderRadius: 15,
		objectFit: "cover",
	},
	WelcomeContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 30,
		paddingRight: 30,
		marginTop: 88,
	},
	TeacherTitle: {
		color: "#4b7bec",
		fontWeight: "bold",
		fontSize: 18,
	},
	semiText: {
		color: "#576574",
		fontSize: 14,
		opacity: 0.8,
	},

	Classes: {
		height: "auto",
		width: "90%",
		backgroundColor: "white",
		borderRadius: 30,
		marginTop: 20,
	},
	containerCenter: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	classImg: {
		height: 55,
		width: 55,
		backgroundColor: "#70a1ff",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 10,
		marginRight: 20,
	},
	className: {
		fontSize: 20,
		color: "white",
		fontWeight: "bold",
	},
	classesContainer: {
		display: "flex",
		alignItems: "center",
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
	},
	ClassTitle: {
		fontSize: 14,
		fontWeight: "bold",
	},
	btmContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
		marginBottom: 20,
	},
	JoinBtn: {
		height: 50,
		width: 50,
		backgroundColor: "#4b7bec49",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 10,
	},

	JoinBtnDisabled: {
		height: 50,
		width: 50,
		backgroundColor: "#dfe6e9",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 10,
	},
	JoinText: {
		color: "#3867d6",
		fontWeight: "bold",
		// marginLeft: 5,
		fontSize: 12,
		textAlign: "center",
	},
	moreClasses: {
		height: 150,
		width: "auto",
		borderRadius: 15,
		backgroundColor: "#70a1ff",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 15,
		margin: 5,
	},

	absenteesCard: {
		minHeight: 175,
		height: "auto",
		minWidth: 160,
		width: "auto",
		borderRadius: 15,
		backgroundColor: "#70a1ff",
		display: "flex",
		flexDirection: "column",
		// padding: 15,
		paddingTop: 10,
		paddingBottom: 10,
		margin: 5,
	},
	moreClassesText: {
		color: "white",
		fontSize: 14,
	},
	moreClassesContainer: {
		display: "flex",
		flexDirection: "column",
		marginTop: 30,
		marginLeft: 20,
		marginRight: 20,
	},
	assignmentColor: {
		marginLeft: "auto",
		fontSize: 12,
		fontWeight: "bold",
		color: "#e67e22",
	},

	customChip: {
		height: 30,
		width: 100,
		backgroundColor: "#e74c3c",
		borderTopRightRadius: 15,
		borderBottomRightRadius: 15,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 15,
	},
	leavesTextStyle: {
		display: "flex",
		width: "100%",
	},
	timeSlot: {
		height: 30,
		padding: 2,
		backgroundColor: "#3867d6",
		color: "white",
		marginTop: 5,
		borderTopLeftRadius: 5,
		borderBottomLeftRadius: 5,
		width: "21%",
	},
	studentName: {
		border: "2px solid #3867d6",
		height: 30,
		width: "77%",
		marginTop: 5,
		borderTopRightRadius: 5,
		borderBottomRightRadius: 5,
	},
}))

const ClassesCard = ({schedule}) => {
	const classes = useStyles()
	const [zoomLoading, setZoomLoading] = useState(false)
	const joinClass = (scheduleId) => {
		setZoomLoading(true)
		let date = moment().format()
		getJoinLink(scheduleId, localStorage.getItem("teacherId"), date)
			.then((data) => {
				socket.emit("teacher-joined-class", {scheduleId})
				window.location.href = data.data.link
			})
			.catch((error) => {})

		setZoomLoading(false)
	}

	function arrayMin(arr) {
		var len = arr.length,
			min = Infinity
		while (len--) {
			if (arr[len] < min) {
				min = arr[len]
			}
		}
		return min
	}

	const minFinder = (data) => {
		let times = []
		if (data.length > 0) {
			data.forEach((el) => {
				times.push(el.split("-")[1].substring(1, 5).replace(":", "."))
			})
		}
		return arrayMin(times)
	}

	const meetinStartTimeGenerator = (data, day) => {
		let wtg = ""
		let mintime = minFinder(data[day])
		mintime = mintime.toString().replace(".", ":")
		data[day].forEach((el) => {
			if (el.split("-")[1].includes(mintime)) {
				const strtime = el.split("-")
				wtg = wtg + strtime[1]
			}
		})
		return wtg
	}

	const colorsArr = ["#2c3e50", "#e67e22", "#ff7f50", "#fd79a8", "#1e90ff"]

	const isClassCancelled = useMemo(
		() => schedule.cancelledTill && isFuture(schedule.cancelledTill),
		[schedule.cancelledTill]
	)

	console.log(schedule.cancelledTill)

	return (
		<div className={classes.containerCenter}>
			<div className={classes.Classes} style={{overflow: "hidden", paddingTop: 10}}>
				<div className={classes.customChip}>
					<p style={{color: "white", paddingLeft: 10}}>
						{meetinStartTimeGenerator(schedule.slots, moment().format("dddd").toLowerCase())}
					</p>
				</div>

				<div className={classes.classesContainer}>
					<div className={classes.classImg}>
						{schedule && <p className={classes.className}>{schedule?.className[0]}</p>}
					</div>
					<div>
						<p className={classes.ClassTitle}>{schedule?.className}</p>
						<p style={{opacity: 0.7, fontSize: 10}}>
							{isClassCancelled ? schedule.message : schedule.scheduleDescription}
						</p>
					</div>
				</div>
				<div className={classes.containerCenter}>
					<hr style={{marginTop: 20, width: "90%", opacity: 0.4}} />
				</div>

				<div className={classes.btmContainer}>
					<div>
						<AvatarGroup max={4}>
							{schedule.students.map((item, i) => (
								<Avatar style={{background: colorsArr[i % colorsArr.length]}} key={item._id}>
									{item.firstName[0]}
								</Avatar>
							))}
						</AvatarGroup>
					</div>
					<div style={{display: "flex"}}>
						<div style={{marginRight: 20}}>
							<div
								className={isClassCancelled ? classes.JoinBtnDisabled : classes.JoinBtn}
								onClick={() => {
									if (!isClassCancelled) {
										joinClass(schedule._id)
									}
								}}
							>
								{zoomLoading ? (
									<CircularProgress style={{height: 20, width: 20}} />
								) : (
									<>
										<Video
											style={{
												color: isClassCancelled ? "#b2bec3" : "#3867d6",
											}}
										/>
									</>
								)}
							</div>
							<p
								className={classes.JoinText}
								style={{
									color: isClassCancelled ? "#b2bec3" : "#3867d6",
								}}
							>
								Join
							</p>
						</div>
						<div>
							<Link
								style={{textDecoration: "none"}}
								to={{
									pathname: `student-attendance/${schedule._id}`,
									state: {type: "new"},
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "column",
									}}
								>
									<div
										className={classes.JoinBtn}
										style={{backgroundColor: "rgb(250, 177, 160, 0.4)"}}
									>
										<Calendar style={{color: "#e17055"}} />
									</div>
									<p className={classes.JoinText} style={{color: "#e17055", textAlign: "center"}}>
										Attendance
									</p>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const AbsentCart = ({type, data}) => {
	const [groupedData, setGroupedData] = useState({})
	const classes = useStyles()

	useEffect(() => {
		let newData = {}
		data &&
			data.forEach((id) => {
				let date = moment(id.cancelledDate).format("h:mm A")

				if (newData[date]) {
					newData[date].push(id.studentId)
				} else {
					newData[date] = [id.studentId]
				}
			})

		setGroupedData(newData)
	}, [data])

	const colorsArr = ["#2c3e50", "#e67e22", "#ff7f50", "#fd79a8", "#1e90ff"]

	return (
		<Card
			className={classes.absenteesCard}
			style={{background: "white", paddingLeft: 20, justifyContent: ""}}
		>
			<p
				style={{
					marginRight: "auto",
					fontSize: 12,
					fontWeight: "bold",
					color: "#e67e22",
					marginBottom: 10,
				}}
			>
				{type}
			</p>

			<div>
				{data.length === 0 ? (
					<p>No Students</p>
				) : (
					<>
						<div>
							{Object.keys(groupedData).map((data, i) => {
								return (
									<div
										style={{
											minHeight: 60,
											height: "auto",
											width: "95%",
											border: "1px solid #ecf0f1",
											borderRadius: 10,
											padding: 5,
											display: "flex",
											alignItems: "center",
											marginTop: 5,
										}}
									>
										<div
											style={{
												flex: 0.25,
												height: "100%",
												backgroundColor: `${colorsArr[i % colorsArr.length]}33`,
												borderRadius: 10,
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "column",
											}}
										>
											<p style={{fontWeight: "bold", color: colorsArr[i % colorsArr.length]}}>
												{data.split(" ")[0]}
											</p>
											<p style={{color: colorsArr[i % colorsArr.length], marginTop: -5}}>
												{data.split(" ")[1]}
											</p>
										</div>

										<div
											style={{
												flex: 0.73,
												height: "100%",
												marginLeft: 10,
												display: "flex",
												justifyContent: "center",
												flexDirection: "column",
											}}
										>
											{groupedData[data].map((item) => (
												<p>{item.firstName}</p>
											))}
										</div>
									</div>
								)
							})}
						</div>
					</>
				)}
			</div>
		</Card>
	)
}

const TeacherDashboard = () => {
	function arrayMin(arr) {
		var len = arr.length,
			min = Infinity
		while (len--) {
			if (arr[len] < min) {
				min = arr[len]
			}
		}
		return min
	}

	const minFinder = (data) => {
		let times = []
		if (data.length > 0) {
			data.forEach((el) => {
				times.push(el.split("-")[1].substring(1, 5).replace(":", "."))
			})
		}
		return arrayMin(times)
	}

	const meetinStartTimeGenerator = (data, day) => {
		let wtg = ""
		let mintime = minFinder(data[day])
		mintime = mintime.toString().replace(".", ":")
		data[day].forEach((el) => {
			if (el.split("-")[1].includes(mintime)) {
				const strtime = el.split("-")
				wtg = wtg + strtime[1]
			}
		})
		return wtg
	}

	const settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 2,
	}

	const {setRefresh: globalNotificationsRefresh} = useContext(NotificationContext)

	useEffect(() => {
		globalNotificationsRefresh((prev) => !prev)
	}, [globalNotificationsRefresh])

	const colorsArr = ["#2c3e50", "#e67e22", "#ff7f50", "#fd79a8", "#1e90ff"]

	const [classesInfo, setClassesInfo] = useState()
	const [teacherInfo, setTeacherInfo] = useState()
	useEffect(() => {
		getClassDetails()
		getTeacherDetails()
	}, [])

	const getTeacherDetails = async () => {
		try {
			const {data} = await api.get(
				`teacher/get/teacherDetails/${localStorage.getItem("teacherId")}`
			)

			setTeacherInfo(data.result)
		} catch (error) {}
	}
	const getClassDetails = async () => {
		const timeSlot = getSlotFromTime(new Date())

		try {
			const {data} = await api.get(
				`/schedule/teacher/present/${localStorage.getItem("teacherId")}/${timeSlot.slot}`
			)

			setClassesInfo(data?.result)
		} catch (error) {}
	}
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<MenuAppBar />

			{!classesInfo ? (
				<div
					style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}
				>
					<CircularProgress />
				</div>
			) : (
				<>
					<div style={{paddingTop: 1}}>
						<div className={classes.WelcomeContainer}>
							<div>
								<p className={classes.TeacherTitle}>
									Welcome, {teacherInfo?.TeacherName?.split(" ")[0]}
								</p>
								<p className={classes.semiText}>Are you ready to start the class?</p>
							</div>

							<div>
								<img src={teacherInfo?.teacherImageLink} alt="" className={classes.teacherImage} />
							</div>
						</div>

						{classesInfo?.scheduleRightNow == null ? (
							<>
								<p
									style={{
										fontWeight: "bold",
										fontSize: 15,
										marginLeft: 30,
										marginTop: 20,
										marginBottom: -10,
									}}
								>
									Current Class
								</p>
								<div className={classes.containerCenter}>
									<div
										className={classes.Classes}
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: 100,
										}}
									>
										<p style={{fontWeight: "bold"}}>No classes at this time</p>
									</div>
								</div>
							</>
						) : (
							<>
								<p
									style={{
										fontWeight: "bold",
										fontSize: 15,
										marginLeft: 30,
										marginTop: 20,
										marginBottom: -10,
									}}
								>
									Present Class
								</p>
								<ClassesCard schedule={classesInfo?.scheduleRightNow} />
							</>
						)}
						{classesInfo?.nextSchedule == null ? (
							<>
								<p
									style={{
										fontWeight: "bold",
										fontSize: 15,
										marginLeft: 30,
										marginTop: 20,
										marginBottom: -10,
									}}
								>
									Next Class
								</p>
								<div className={classes.containerCenter}>
									<div
										className={classes.Classes}
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: 100,
										}}
									>
										<p style={{fontWeight: "bold"}}>No classes at this time</p>
									</div>
								</div>
							</>
						) : (
							<>
								<p
									style={{
										fontWeight: "bold",
										fontSize: 15,
										marginLeft: 30,
										marginTop: 20,
										marginBottom: -10,
									}}
								>
									Next Class
								</p>

								<ClassesCard schedule={classesInfo?.nextSchedule} />
							</>
						)}
					</div>
					<div style={{paddingBottom: 100}}>
						<div className={classes.moreClassesContainer}>
							<div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
								<p style={{fontWeight: "bold"}}>Your Classes</p>
								<Link style={{textDecoration: "none"}} to="/meetings">
									<p style={{fontWeight: "bold", color: "#3867d6"}}>More Classes</p>
								</Link>
							</div>

							{classesInfo.otherSchedules.length === 0 ? (
								<p style={{fontWeight: "bold", color: "#3867d6"}}>No classes</p>
							) : (
								<>
									<Slider {...settings}>
										{classesInfo.otherSchedules
											.map((meeting) => ({
												startTime: meetinStartTimeGenerator(
													meeting.slots,
													moment().format("dddd").toLowerCase()
												),
												...meeting,
											}))
											.sort(
												(a, b) =>
													Date.parse(
														"1970/01/01 " + a.startTime.slice(0, -2) + " " + a.startTime.slice(-2)
													) -
													Date.parse(
														"1970/01/01 " + b.startTime.slice(0, -2) + " " + b.startTime.slice(-2)
													)
											)
											.map((item, i) => {
												return (
													<div key={i} style={{padding: 20}}>
														<Card
															className={classes.moreClasses}
															style={{background: colorsArr[i % colorsArr.length]}}
														>
															<div
																className={classes.customChip}
																style={{
																	marginRight: 76,
																	backgroundColor: "white",
																	marginTop: 5,
																	width: "70%",
																}}
															>
																<p style={{color: "black", paddingLeft: 10}}>
																	{meetinStartTimeGenerator(
																		item.slots,
																		moment().format("dddd").toLowerCase()
																	)}
																</p>
															</div>
															<p></p>
															<p className={classes.moreClassesText}>{item.className}</p>
														</Card>
													</div>
												)
											})}
									</Slider>
								</>
							)}
						</div>

						<div className={classes.moreClassesContainer}>
							<div style={{display: "flex", justifyContent: "space-between", marginBottom: 10}}>
								<p style={{fontWeight: "bold"}}>Absentees</p>
							</div>
							<div style={{display: "flex", flexDirection: "column"}}>
								<div>
									<AbsentCart type="Today" data={classesInfo?.todayLeaves} />
								</div>
								<div>
									<AbsentCart type="Tomorrow" data={classesInfo?.tomorrowLeaves} />
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
			<ChatHome />
		</div>
	)
}

export default TeacherDashboard
