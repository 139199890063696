import React from "react"
import ChatIcon from "@material-ui/icons/Chat"
import {Badge} from "@material-ui/core"
const BotButton = ({showBot, newMessage}) => {
	return (
		<div
			id="chat-circle"
			onClick={(e) => {
				e.stopPropagation()

				showBot(true)
			}}
		>
			<Badge badgeContent={newMessage ? 1 : 0} color="error">
				<ChatIcon style={{fontSize: 30}} />
			</Badge>
		</div>
	)
}

export default BotButton

// const BotButton = ({showBot, shake, setShake}) => {
// 	return (
// 		<div
// 			id="chat-circle"
// 			onClick={(e) => {
// 				e.stopPropagation()
// 				if (shake) {
// 					setShake(false)
// 				}
// 				showBot(true)
// 			}}
// 			className={shake ? "shake-horizontal shake-constant shake-constant--hover" : ""}
// 		>
// 			<Badge badgeContent={4} color="error">
// 				<ChatIcon style={{fontSize: 30}} />
// 			</Badge>
// 		</div>
// 	)
// }
