import {
	Avatar,
	Button,
	TextField,
	makeStyles,
	IconButton,
	CircularProgress,
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {firebase, storage} from "../../../Firebase/"
import {MenuAppBar} from "../../../components"
import TeacherBottomTabs from "../../../components/BottomTabs/TeacherBottomTabs"
import api from "../../../helpers/api"
import "./profile.css"

const useStyles = makeStyles((theme) => ({
	inputRoot: {
		fontSize: 12,
	},
	labelRoot: {
		fontSize: 12,
		marginLeft: 5,
	},
	labelFocused: {},

	textInput: {
		marginTop: 5,
	},
}))

const Profile = () => {
	const classes = useStyles()
	const [teacherDetails, setTeacherDetails] = useState()
	const [enableEdit, setEnableEdit] = useState(false)
	const [enableEdit2, setEnableEdit2] = useState(false)
	const [enableEdit3, setEnableEdit3] = useState(false)
	const [profilePicture, setProfilePicture] = useState()
	const [refresh, setRefresh] = useState(false)
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		getTeacherDetails()
	}, [])

	const [profileData, setProfileData] = useState({})
	const getTeacherDetails = async () => {
		const data = await api.get(`teacher/get/teacherDetails/${localStorage.getItem("teacherId")}`)

		console.log(data)
		setProfileData({
			teacherImageLink: data?.data?.result?.teacherImageLink,
			TeacherName: data?.data?.result?.TeacherName,
			TeacherDesc: data?.data?.result?.TeacherDesc,
			Phone_number: data?.data?.result?.Phone_number,
			teacherMail: data?.data?.result?.teacherMail,
			Bank_full_name: data?.data?.result?.Bank_full_name,
			Bank_account: data?.data?.result?.Bank_account,
			ifsc: data.data.result.ifsc,
			id: data?.data?.result?.id,
			experience: data?.data?.result?.experience,
			rewards: data?.data?.result?.rewards,
			teacherVideoLink: data?.data?.result?.teacherVideoLink,
		})

		setTeacherDetails(data?.data?.result)
	}

	const submitProfileData = async () => {
		try {
			const data = await api.post("/admin/update/Teacher", profileData)

			if (data.status === 200) {
				setEnableEdit(false)
				setEnableEdit2(false)
				setEnableEdit3(false)
			}
		} catch (error) {}
	}

	const profilePictureUpdate = async () => {
		if (profilePicture !== undefined) {
			let unix = new Date().getTime()

			const uploadTask = storage
				.ref(`teachers/${unix}-${profilePicture[0].name}`)
				.put(profilePicture[0])

			var desertRef = storage.ref("teachers").child(profileData.firebaseLocation)
			desertRef
				.delete()
				.then(function () {
					console.log("yes deleted old")
				})
				.catch(function (error) {
					console.log(error)
				})

			uploadTask.on(
				"state_changed",
				(snapshot) => {},
				(err) => {
					console.log(err)
				},
				() => {
					storage
						.ref("teachers")
						.child(`${unix}-${profilePicture[0].name}`)
						.getDownloadURL()
						.then(async (URL) => {
							try {
								const data = await api.post("/admin/update/Teacher", {
									...profileData,
									teacherImageLink: URL,
									firebaseLocation: `${unix}-${profilePicture[0].name}`,
								})
								getTeacherDetails()
								if (data.status === 200) {
									profilePicture()
									setEnableEdit(false)
									setEnableEdit2(false)
								}
							} catch (error) {}
						})
				}
			)

			setLoading(false)
		}
	}
	return (
		<div>
			<MenuAppBar />

			<div style={{marginBottom: 100}}>
				{teacherDetails && (
					<div className="profileContainer">
						<div className="profileGrad">
							<div className="ProfilePic">
								<p className="editProfileText">Edit Profile</p>

								<IconButton
									variant="contained"
									component="label"
									style={{
										marginTop: -20,
									}}
								>
									<Avatar src={profileData.teacherImageLink} className="profilePicture" />
									<input
										multiple
										accept="image/*"
										onChange={(e) => {
											setProfilePicture(e.target.files)
										}}
										type="file"
										hidden
									/>
								</IconButton>

								<Button
									disabled={loading}
									onClick={profilePictureUpdate}
									variant="contained"
									style={{
										backgroundColor: "transparent",
										color: "white",
										border: "1px solid white",
										width: 150,
									}}
								>
									{loading ? (
										<CircularProgress
											style={{
												height: 30,
												width: 30,
												color: "white",
											}}
										/>
									) : (
										"Upload Photo"
									)}
								</Button>
							</div>
						</div>

						<div>
							<div className="editProfile">
								<div className="editProfileCont">
									<div style={{padding: 30}}>
										<div className="profCont">
											<p className="editBtn editBtn0">PERSONAL DETAILS</p>
											<p
												className="editBtn editBtn1"
												onClick={() => {
													if (enableEdit) {
														submitProfileData()
													} else {
														setEnableEdit(!enableEdit)
													}
												}}
											>
												{enableEdit ? "SUBMIT" : "EDIT"}
											</p>
										</div>

										<div style={{marginTop: 10}}>
											<div className="editCon">
												<p className="profileLabel">Teacher name</p>
												{enableEdit ? (
													<TextField
														value={profileData.TeacherName}
														onChange={(e) =>
															setProfileData((prev) => {
																return {
																	...prev,
																	TeacherName: e.target.value,
																}
															})
														}
														fullWidth
														className={classes.textInput}
														InputProps={{
															classes: {root: classes.inputRoot},
														}}
														InputLabelProps={{
															classes: {
																root: classes.labelRoot,
																focused: classes.labelFocused,
															},
														}}
														size="small"
														variant="outlined"
													/>
												) : (
													<p className="profileText">{profileData.TeacherName}</p>
												)}
											</div>

											<div className="editCon">
												<p className="profileLabel">Phone</p>

												{enableEdit ? (
													<TextField
														fullWidth
														className={classes.textInput}
														InputProps={{
															classes: {root: classes.inputRoot},
														}}
														InputLabelProps={{
															classes: {
																root: classes.labelRoot,
																focused: classes.labelFocused,
															},
														}}
														size="small"
														variant="outlined"
														onChange={(e) =>
															setProfileData((prev) => {
																return {
																	...prev,
																	Phone_number: e.target.value,
																}
															})
														}
														value={profileData.Phone_number}
													/>
												) : (
													<p className="profileText">{profileData.Phone_number}</p>
												)}
											</div>

											<div className="editCon">
												<p className="profileLabel">Email:</p>

												{enableEdit ? (
													<TextField
														fullWidth
														className={classes.textInput}
														InputProps={{
															classes: {root: classes.inputRoot},
														}}
														InputLabelProps={{
															classes: {
																root: classes.labelRoot,
																focused: classes.labelFocused,
															},
														}}
														size="small"
														variant="outlined"
														onChange={(e) =>
															setProfileData((prev) => {
																return {
																	...prev,
																	teacherMail: e.target.value,
																}
															})
														}
														value={profileData.teacherMail}
													/>
												) : (
													<p className="profileText">{profileData.teacherMail}</p>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="editProfile">
								<div className={enableEdit ? "editProfileCont3" : "editProfileCont2"}>
									<div style={{padding: 30}}>
										<div className="profCont">
											<p className="editBtn editBtn0">BANK ACCOUNT</p>
											<p
												className="editBtn editBtn1"
												onClick={() => {
													if (enableEdit2) {
														submitProfileData()
													} else {
														setEnableEdit2(!enableEdit2)
													}
												}}
											>
												{" "}
												{enableEdit2 ? "SUBMIT" : "EDIT"}
											</p>
										</div>

										<div style={{marginTop: 10}}>
											<div className="editCon">
												<p className="profileLabel">Bank Full Name</p>

												{enableEdit2 ? (
													<TextField
														fullWidth
														className={classes.textInput}
														InputProps={{
															classes: {root: classes.inputRoot},
														}}
														InputLabelProps={{
															classes: {
																root: classes.labelRoot,
																focused: classes.labelFocused,
															},
														}}
														size="small"
														variant="outlined"
														onChange={(e) =>
															setProfileData((prev) => {
																return {
																	...prev,
																	Bank_full_name: e.target.value,
																}
															})
														}
														value={profileData.Bank_full_name}
													/>
												) : (
													<p className="profileText">
														{profileData.Bank_full_name === "" ? "NA" : profileData.Bank_full_name}
													</p>
												)}
											</div>

											<div className="editCon">
												<p className="profileLabel">Bank Acc No</p>

												{enableEdit2 ? (
													<TextField
														fullWidth
														className={classes.textInput}
														InputProps={{
															classes: {root: classes.inputRoot},
														}}
														InputLabelProps={{
															classes: {
																root: classes.labelRoot,
																focused: classes.labelFocused,
															},
														}}
														size="small"
														variant="outlined"
														onChange={(e) =>
															setProfileData((prev) => {
																return {
																	...prev,
																	Bank_account: e.target.value,
																}
															})
														}
														value={profileData.Bank_account}
													/>
												) : (
													<p className="profileText">
														{profileData.Bank_account === "" ? "NA" : profileData.Bank_account}
													</p>
												)}
											</div>

											<div className="editCon">
												<p className="profileLabel">Bank Ifsc No</p>

												{enableEdit2 ? (
													<TextField
														fullWidth
														className={classes.textInput}
														InputProps={{
															classes: {root: classes.inputRoot},
														}}
														InputLabelProps={{
															classes: {
																root: classes.labelRoot,
																focused: classes.labelFocused,
															},
														}}
														size="small"
														variant="outlined"
														onChange={(e) =>
															setProfileData((prev) => {
																return {
																	...prev,
																	ifsc: e.target.value,
																}
															})
														}
														value={profileData.ifsc}
													/>
												) : (
													<p className="profileText">
														{profileData.ifsc === "" ? "NA" : profileData.ifsc}
													</p>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="editProfile">
								<div className={"editProfileCont3"} style={{top: enableEdit2 ? 857 : 817}}>
									<div style={{padding: 30, marginBottom: 50}}>
										<div className="profCont">
											<p className="editBtn editBtn0">More Info</p>
											<p
												className="editBtn editBtn1"
												onClick={() => {
													if (enableEdit3) {
														submitProfileData()
													} else {
														setEnableEdit3(!enableEdit3)
													}
												}}
											>
												{" "}
												{enableEdit3 ? "SUBMIT" : "EDIT"}
											</p>
										</div>

										<div style={{marginTop: 20}}>
											<div className="editCon">
												<p className="profileLabel">Description</p>

												{enableEdit3 ? (
													<div
														style={{
															marginLeft: 1,
														}}
													>
														<textarea
															style={{
																height: "90px",
																width: "100%",
																border: "1px solid #636e72",
																borderRadius: 8,
																padding: 10,
																outline: "none",
															}}
															size="small"
															variant="outlined"
															onChange={(e) =>
																setProfileData((prev) => {
																	return {
																		...prev,
																		TeacherDesc: e.target.value,
																	}
																})
															}
															value={profileData.TeacherDesc}
														/>
													</div>
												) : (
													<p className="profileText">
														{profileData.TeacherDesc === "" ? "NA" : profileData.TeacherDesc}
													</p>
												)}
											</div>
										</div>

										<div style={{marginTop: 20}}>
											<div className="editCon">
												<p className="profileLabel">Experience</p>

												{enableEdit3 ? (
													<div
														style={{
															marginLeft: 1,
														}}
													>
														<textarea
															style={{
																height: "90px",
																width: "100%",
																border: "1px solid #636e72",
																borderRadius: 8,
																padding: 10,
																outline: "none",
															}}
															size="small"
															variant="outlined"
															onChange={(e) =>
																setProfileData((prev) => {
																	return {
																		...prev,
																		experience: e.target.value,
																	}
																})
															}
															value={profileData.experience}
														/>
													</div>
												) : (
													<p className="profileText">
														{profileData.experience === "" ? "NA" : profileData.experience}
													</p>
												)}
											</div>
										</div>

										<div style={{marginTop: 20}}>
											<div className="editCon">
												<p className="profileLabel">Rewards</p>

												{enableEdit3 ? (
													<div
														style={{
															marginLeft: 1,
														}}
													>
														<textarea
															style={{
																height: "90px",
																width: "100%",
																border: "1px solid #636e72",
																borderRadius: 8,
																padding: 10,
																outline: "none",
															}}
															size="small"
															variant="outlined"
															onChange={(e) =>
																setProfileData((prev) => {
																	return {
																		...prev,
																		rewards: e.target.value,
																	}
																})
															}
															value={profileData.rewards}
														/>
													</div>
												) : (
													<p className="profileText">
														{profileData.rewards === "" ? "NA" : profileData.rewards}
													</p>
												)}
											</div>
										</div>

										<div style={{marginTop: 20}}>
											<div className="editCon">
												<p className="profileLabel">Video Link</p>

												{enableEdit3 ? (
													<div
														style={{
															marginLeft: 1,
														}}
													>
														<textarea
															style={{
																height: "90px",
																width: "100%",
																border: "1px solid #636e72",
																borderRadius: 8,
																padding: 10,
																outline: "none",
															}}
															size="small"
															variant="outlined"
															onChange={(e) =>
																setProfileData((prev) => {
																	return {
																		...prev,
																		teacherVideoLink: e.target.value,
																	}
																})
															}
															value={profileData.teacherVideoLink}
														/>
													</div>
												) : (
													<p className="profileText">
														{profileData.teacherVideoLink === ""
															? "NA"
															: profileData.teacherVideoLink}
													</p>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<TeacherBottomTabs />
			</div>
		</div>
	)
}

export default Profile
