import React, {useContext, useState} from "react"
import {ReactSVG} from "react-svg"
import {Collapse, IconButton} from "@material-ui/core"
import "./SingleNotification.css"
import {closeNotification} from "../../Services/services"
import {NotificationContext} from "../../context/NotificationContext"
import moment from "moment"

function SingleNotification({icon, color, title, text, id, hideClose, time, isImage, image}) {
	const [close, setClose] = useState(true)
	const NotificationContextData = useContext(NotificationContext)
	const {updateUnRead} = NotificationContextData

	const handleClose = async () => {
		setClose(false)
		let data = await closeNotification({
			notificationId: id,
			userId: localStorage.getItem("userId"),
		})
		console.log(data)
		setTimeout(() => {
			updateUnRead(id)
		}, 2000)
	}

	return (
		<Collapse in={close}>
			{isImage ? (
				<div
					style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15}}
				>
					<img
						src={image}
						style={{
							width: "90%",
							objectFit: "cover",
							borderRadius: 10,
							boxShadow: "0 8px 32px 0 rgb(31 38 135 / 37%)",
						}}
						alt=""
					/>
				</div>
			) : null}

			{title && text && icon ? (
				<div className="notification-wrapper">
					<div
						className="icon-wrapper"
						style={{
							borderLeftColor: color,
						}}
					>
						<ReactSVG
							style={{
								backgroundColor: color,
							}}
							className="icon-circle"
							src={require(`./icons/${icon}.svg`).default}
						/>
					</div>
					<div className="text-area">
						<div className="title">{title}</div>
						<div className="text">
							{text}
							{time ? moment(time).format("Do of MMMM") : ""}{" "}
						</div>
					</div>
					{!hideClose ? (
						<IconButton onClick={handleClose} size="small">
							<ReactSVG className="close-icon" src={require(`./icons/x.svg`).default} />
						</IconButton>
					) : (
						""
					)}
				</div>
			) : null}
		</Collapse>
	)
}

export default SingleNotification
