import {makeStyles, IconButton} from "@material-ui/core"
import React from "react"
import {FileText, Image, Download} from "react-feather"
const useStyles = makeStyles((theme) => ({
	card: {
		height: 300,
		width: "70%",
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
		margin: 10,
		background: "linear-gradient(135deg,#FC6076,#E75516)",
		padding: 20,
		borderRadius: 10,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	icon: {
		height: 70,
		width: 70,
		color: "white",
	},
	title: {
		fontSize: 22,
		color: "white",
		fontWeight: 600,
	},
	desc: {
		fontSize: 15,
		color: "white",
		fontWeight: 500,
	},
}))
const CertificateCard = ({item}) => {
	const classes = useStyles()

	let pdf = item?.image
	let pdfregExp = /%2..*%2F(.*?)\?alt/
	let pdfmatch = pdf.match(pdfregExp)

	console.log(pdfmatch)

	const onCardClick = (link) => {
		window.open(link, "_blank")
	}

	if (pdfmatch[1].split(".")[1] === "pdf") {
		return (
			<div className={classes.card} onClick={() => onCardClick(item.image)}>
				<div>
					<FileText className={classes.icon} />
					<IconButton style={{backgroundColor: "white"}}>
						<Download />
					</IconButton>
				</div>

				<div>
					<p className={classes.title}>{item.name}</p>
					<p className={classes.desc}>{item.description}</p>
				</div>
			</div>
		)
	} else {
		return (
			<div
				onClick={() => onCardClick(item.image)}
				className={classes.card}
				style={{background: "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)"}}
			>
				<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<Image className={classes.icon} />

					<IconButton style={{backgroundColor: "white"}}>
						<Download />
					</IconButton>
				</div>

				<div>
					<p className={classes.title}>{item.name}</p>
					<p className={classes.desc}>{item.description}</p>
				</div>
			</div>
		)
	}
}

export default CertificateCard
