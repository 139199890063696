import React, {useState, useEffect} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../components"
import {makeStyles} from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import studyImage from "../../assets/youtube_logo.png"
import videoLogo from "../../assets/video-player.png"
import pdfLogo from "../../assets/pdf.png"
import musicLogo from "../../assets/music.png"
import image_logo from "../../assets/image_logo.png"
import api from "../../helpers/api"
import moment from "moment"
import {useParams} from "react-router-dom"

const useStyles = makeStyles({
	card: {
		maxWidth: 345,
	},
	media: {
		height: 150,
	},

	root: {
		flexGrow: 1,
	},
})

const ParticularMaterial = () => {
	const [materialData, setMaterialData] = useState()
	const {id} = useParams()

	useEffect(() => {
		getMaterials()
	}, [])
	const getMaterials = async () => {
		const data = await api.get(`${process.env.REACT_APP_API_KEY}/uploads/student/${id}`)
		setMaterialData(data && data.data.result)
	}
	const classes = useStyles()

	return (
		<div>
			<MenuAppBar />

			<div
				style={{
					margin: "auto",
					width: "80%",
					marginTop: 100,
					marginBottom: 80,
				}}
			>
				<h1 style={{textAlign: "center", marginBottom: 10, fontSize: 18}}>Class Materials</h1>
				<Grid container spacing={5}>
					{materialData &&
						materialData.map((data) => {
							if (data.uploadLink) {
								return (
									<Grid item xs={12} md={4} key={data._id}>
										{data.typeOfMaterial === "Youtube" ? (
											<a
												href={data.uploadLink}
												target="_blank"
												rel="noreferrer"
												style={{textDecoration: "none"}}
											>
												<Card className={classes.card}>
													<CardActionArea style={{display: "flex", flexDirection: "row"}}>
														<CardContent
															style={{
																display: "flex",
																flexDirection: "row",
																justifyContent: "space-evenly",
																alignItems: "center",
															}}
														>
															<div
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																	alignItems: "center",
																}}
															>
																<div>
																	<img src={studyImage} alt="" style={{height: 30, width: 30}} />
																</div>
																<div style={{textAlign: "center"}}>
																	<Typography
																		ariant="h5"
																		component="h5"
																		style={{
																			fontSize: 15,
																			fontWeight: "bold",
																			marginLeft: 10,
																		}}
																	>
																		{data.materialName}
																	</Typography>
																	<Typography
																		ariant="h5"
																		component="h5"
																		style={{
																			fontSize: 12,
																			fontWeight: "bold",
																			marginLeft: 10,
																		}}
																	>
																		{moment(data.createdAt).format("MMM Do YY")}
																	</Typography>
																</div>
															</div>
														</CardContent>
													</CardActionArea>
												</Card>
											</a>
										) : (
											<a href={data.uploadLink} target="_blank" style={{textDecoration: "none"}}>
												<Card className={classes.card}>
													<CardActionArea>
														<CardContent
															style={{
																display: "flex",
																flexDirection: "row",
																alignItems: "flex-start",
															}}
														>
															<div>
																<img
																	src={
																		data.typeOfMaterial === "video/mp4" ? (
																			videoLogo
																		) : data.typeOfMaterial === "application/pdf" ? (
																			pdfLogo
																		) : data.typeOfMaterial === "audio/mpeg" ? (
																			musicLogo
																		) : data.typeOfMaterial === "image/png" ? (
																			image_logo
																		) : data.typeOfMaterial === "image/jpeg" ? (
																			image_logo
																		) : (
																			<img
																				src="https://www.flaticon.com/svg/vstatic/svg/535/535761.svg?token=exp=1618930536~hmac=f791068eac5964ad00d28b32dedc1497"
																				alt=""
																				className={classes.avatar}
																			/>
																		)
																	}
																	alt=""
																	style={{height: 30, width: 30}}
																/>
															</div>
															<div
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																	alignItems: "flex-start",
																	flexDirection: "column",
																}}
															>
																<Typography
																	ariant="h5"
																	component="h5"
																	style={{
																		fontSize: 15,
																		fontWeight: "bold",
																		marginLeft: 10,
																	}}
																>
																	{data.materialName}
																</Typography>
																<Typography
																	ariant="h5"
																	component="h5"
																	style={{
																		fontSize: 12,
																		fontWeight: "bold",
																		marginLeft: 10,
																	}}
																>
																	{moment(data.createdAt).format("MMM' Do YYYY")}
																</Typography>
															</div>
														</CardContent>
													</CardActionArea>
												</Card>
											</a>
										)}
									</Grid>
								)
							}
						})}
				</Grid>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default ParticularMaterial
