import {makeStyles} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../../components"
import api from "../../../helpers/api"
import CertificateCard from "./CertificateCard"

const useStyles = makeStyles((theme) => ({
	card: {
		height: 300,
		width: "90%",
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
		margin: 10,
	},
}))
const CertificatePage = () => {
	const [videoData, setVideoData] = useState()
	useEffect(() => {
		getVideos()
	}, [])
	const getVideos = async () => {
		const data = await api.get(`/videos/customer/${localStorage.getItem("userId")}`)

		let array = []
		console.log(data)
		data &&
			data.data.result.map((item) => {
				if (item.image) {
					array.push(item)
				}
			})
		setVideoData(array)
	}

	const classes = useStyles()
	return (
		<div>
			<MenuAppBar />
			<div
				style={{
					marginTop: 70,
					marginBottom: 80,
					padding: 10,
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					{videoData && videoData.map((item) => <CertificateCard item={item} />)}
				</div>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default CertificatePage
