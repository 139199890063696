import React from "react"
import {Alert} from "@material-ui/lab"
import VideocamIcon from "@material-ui/icons/Videocam"
import Fab from "@material-ui/core/Fab"
import {UserX} from "react-feather"
import {Card, CardContent, Chip, Tooltip} from "@material-ui/core"
import {Link} from "react-router-dom"
import moment from "moment"
import {isFuture} from "../../Services"

function SingleTeacherCard({meeting, value, joinClass, day, setLeaveData}) {
	const isClassCancelled = meeting.cancelledTill && isFuture(meeting.cancelledTill)

	return (
		<Card className="card-teacher-meeting">
			{isClassCancelled ? (
				<Alert severity="warning" variant="filled">
					{meeting.message || "Class is Cancelled"}
				</Alert>
			) : (
				""
			)}

			<CardContent>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<Tooltip title="Join Class">
						<Fab
							color="primary"
							size="small"
							variant="extended"
							disabled={isClassCancelled}
							onClick={() => joinClass(meeting._id)}
						>
							<VideocamIcon /> Join
						</Fab>
					</Tooltip>
					<Link
						style={{
							textDecoration: "none",
							color: "black",
							marginLeft: 10,
							marginBottom: 20,
							cursor: isClassCancelled ? "default" : "pointer",
						}}
						to={
							isClassCancelled
								? undefined
								: `/student-attendance/${meeting._id}?time=${meeting.startTime}`
						}
					>
						<Tooltip title="Take Attendance">
							<Fab
								variant="extended"
								color="primary"
								disabled={isClassCancelled || value !== new Date().getDay()}
								size="small"
							>
								Attendance
							</Fab>
						</Tooltip>
					</Link>
				</div>
				{meeting.cancelledClasses.map((date) =>
					moment().format("MM DD") === moment(date.cancelledDate).format("MM DD") &&
					moment(date.cancelledDate).format("dddd").toLowerCase() === day.toLowerCase() ? (
						<Alert severity="warning">
							{" "}
							{date.studentId ? date.studentId.firstName : "someone"} doesn't attend class today
						</Alert>
					) : (
						""
					)
				)}
				<h3>{meeting.className}</h3>

				<p style={{fontSize: "15px"}}>
					<b>students:</b> {meeting.students.map((student) => student.firstName).join(", ")}
				</p>
				<Chip
					color="secondary"
					className="time-chip"
					size="small"
					label={". " + meeting.startTime}
				/>
			</CardContent>
			<div className="teacher-meeting-card-buttons">
				<Tooltip title="Apply for a Leave">
					<Fab
						color="secondary"
						size="small"
						onClick={() =>
							setLeaveData((prev) => {
								let {startTime} = meeting
								let isAm = startTime.trim().endsWith("AM")
								let splittedDate = startTime.split(":")
								let hours = isAm ? parseInt(splittedDate[0]) : parseInt(splittedDate[0]) + 12
								let mins = splittedDate[1].startsWith("30") ? 30 : 0
								return {
									...prev,
									scheduleId: meeting._id,
									teacherId: meeting.teacher,
									isHidden: false,
									date: moment().set("hours", hours).set("minutes", mins).format(),
								}
							})
						}
					>
						<UserX style={{marginLeft: 2}} />
					</Fab>
				</Tooltip>
			</div>
		</Card>
	)
}

export default SingleTeacherCard
