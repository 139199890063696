import React, {useEffect, useState} from "react"
import {MenuAppBar, StudentBottomTabs} from "../../components"
import api from "../../helpers/api"
import {makeStyles} from "@material-ui/core/styles"
import moment from "moment"
import {InputLabel, MenuItem, FormControl, Select} from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
const useStyles = makeStyles((theme) => ({
	root: {
		width: "90%",
		marginTop: "100px",
		margin: "0 auto",
	},
	heading: {
		fontSize: "15px",
		fontWeight: "bold",
	},
	formControl: {
		width: "100%",
		marginBottom: "20px",
	},

	isPresentClass: {},
}))

const StudentHistory = () => {
	const [selectedClass, setSelectedClass] = useState()

	const handleChange = (event) => {
		setSelectedClass(event.target.value)
	}

	const classes = useStyles()
	const [historyData, setHistoryData] = useState()
	useEffect(() => {
		getUsersHistory()
	}, [])

	const getUsersHistory = async () => {
		const data = await api.get(
			`${process.env.REACT_APP_API_KEY}/class-history/${localStorage.getItem("userId")}`
		)
		setHistoryData(data && data.data)
	}

	return (
		<div>
			<MenuAppBar />

			<div className={classes.root}>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel>Select Class</InputLabel>
					<Select value={selectedClass} onChange={handleChange} label="Select Class">
						{historyData &&
							historyData.result.map((data) => {
								return <MenuItem value={data}>{data.subject}</MenuItem>
							})}
					</Select>
				</FormControl>

				<TableContainer component={Paper} style={{margin: "40px 0"}}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Prev Class Count</TableCell>
								<TableCell>Next Class Count</TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Comment</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{selectedClass &&
								selectedClass.history.map((data) => {
									return (
										<TableRow key={data.customerId}>
											<TableCell component="th" style={{color: "black"}} scope="row">
												{data.previousValue}
											</TableCell>
											<TableCell component="th" style={{color: "black"}} scope="row">
												{data.nextValue}
											</TableCell>
											<TableCell style={{color: "black"}}>
												{moment(data.createdAt).format("l")}
											</TableCell>
											<TableCell style={{color: "black"}}>{data.comment}</TableCell>
										</TableRow>
									)
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			<div
				style={{
					marginTop: "30px",
					position: "fixed",
					left: 0,
					bottom: 0,
					textAlign: "center",
					width: "100%",
				}}
			>
				<StudentBottomTabs />
			</div>
		</div>
	)
}

export default StudentHistory
