import React from "react"
import useWindowDimensions from "../../../helpers/useDimensions"

function Singleoption({day, time, value, selectedId, setSelectedId}) {
	const {width} = useWindowDimensions()

	return (
		<>
			<div
				onClick={() => setSelectedId(time._id)}
				className={`day-time-intersection-box `}
				style={{
					fontSize: "14px",
					fontWeight: "bold",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<div
					style={{
						background:
							time._id === selectedId && time[day.toLowerCase()]
								? "#3867d6"
								: time[day.toLowerCase()]
								? "white"
								: "#dfe4ea",
					}}
					className="blockName"
				>
					<p
						style={{
							fontSize: width > 900 ? 16 : 10,
							color: time._id === selectedId ? "white" : "black",
						}}
					>
						{time[day.toLowerCase()]}
					</p>
				</div>
			</div>
		</>
	)
}

export default Singleoption
