import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import {Chip} from "@material-ui/core"
import {isFuture} from "../../../Services"

export default function ScheduleInfoModal({open, setOpen, data}) {
	const handleClose = () => {
		setOpen(false)
	}

	if (data?.cancelledTill && isFuture(data?.cancelledTill)) {
		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Message</DialogTitle>
					<DialogContent dividers>
						<p style={{margin: 15}}>{data?.message || "Class cancelled without reason"} </p>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary" autoFocus>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Schedule Details</DialogTitle>
				<DialogContent>
					<div>
						<p style={{fontWeight: "bold", marginBottom: 10}}>Desc: </p>
						<p style={{marginBottom: 10}}>{data?.className}</p>
						<p style={{fontWeight: "bold", marginBottom: 10}}>Students:</p>
						{data?.students?.map((student) => (
							<Chip key={student._id} label={student.firstName} style={{margin: 3}} size="small" />
						))}
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
